/**
 * Created by john on 24/9/16.
 */
'use strict';

/* exported
getIcons
 */

function getIcons() {
  return [{
    id: '1',
    provider: 'fa',
    cssClass: 'fa-blind'
  }, {
    id: '2',
    provider: 'fa',
    cssClass: 'fa-wheelchair'
  }, {
    id: '3',
    provider: 'fa',
    cssClass: 'fa-wifi'
  }, {
    id: '4',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-1'
  }, {
    id: '5',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-2'
  }, {
    id: '6',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-3'
  }, {
    id: '7',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-4'
  }, {
    id: '8',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-5'
  }, {
    id: '9',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-6'
  }, {
    id: '10',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-7'
  }, {
    id: '11',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-8'
  }, {
    id: '12',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-9'
  }, {
    id: '13',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-10'
  }, {
    id: '14',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-11'
  }, {
    id: '15',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-12'
  }, {
    id: '16',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-13'
  }, {
    id: '17',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-14'
  }, {
    id: '18',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-15'
  }, {
    id: '19',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-16'
  }, {
    id: '20',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-17'
  }, {
    id: '21',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-18'
  }, {
    id: '22',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-19'
  }, {
    id: '23',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-20'
  }, {
    id: '24',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-21'
  }, {
    id: '25',
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-22'
  }, {
    id: 26,
    provider: 'icon_set_1',
    cssClass: 'icon_set_1_icon-23'
  }, {
      id: 27,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-24'
  }, {
      id: 28,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-25'
  }, {
      id: 29,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-26'
  }, {
      id: 30,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-27'
  }, {
      id: 31,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-28'
  }, {
      id: 32,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-29'
  }, {
      id: 33,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-30'
  }, {
      id: 34,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-31'
  }, {
      id: 35,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-32'
  }, {
      id: 36,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-33'
  }, {
      id: 37,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-34'
  }, {
      id: 38,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-35'
    },
    {
      id: 39,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-36'
    },
    {
      id: 40,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-37'
    },
    {
      id: 41,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-38'
    },
    {
      id: 42,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-39'
    },
    {
      id: 43,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-40'
    },
    {
      id: 44,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-41'
    },
    {
      id: 45,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-42'
    },
    {
      id: 46,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-43'
    },
    {
      id: 47,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-44'
    },
    {
      id: 48,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-45'
    },
    {
      id: 49,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-46'
    },
    {
      id: 50,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-47'
    },
    {
      id: 51,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-48'
    },
    {
      id: 52,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-49'
    },
    {
      id: 53,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-50'
    },
    {
      id: 54,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-51'
    },
    {
      id: 55,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-52'
    },
    {
      id: 56,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-53'
    },
    {
      id: 57,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-54'
    },
    {
      id: 58,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-55'
    },
    {
      id: 59,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-56'
    },
    {
      id: 60,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-57'
    },
    {
      id: 61,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-58'
    },
    {
      id: 62,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-59'
    },
    {
      id: 63,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-60'
    },
    {
      id: 64,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-61'
    },
    {
      id: 65,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-62'
    },
    {
      id: 66,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-63'
    },
    {
      id: 67,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-64'
    },
    {
      id: 68,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-65'
    },
    {
      id: 69,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-66'
    },
    {
      id: 70,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-67'
    },
    {
      id: 71,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-68'
    },
    {
      id: 72,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-69'
    },
    {
      id: 73,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-70'
    },
    {
      id: 74,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-71'
    },
    {
      id: 75,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-72'
    },
    {
      id: 76,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-73'
    },
    {
      id: 77,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-74'
    },
    {
      id: 78,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-75'
    },
    {
      id: 79,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-76'
    },
    {
      id: 80,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-77'
    },
    {
      id: 81,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-78'
    },
    {
      id: 82,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-79'
    },
    {
      id: 83,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-80'
    },
    {
      id: 84,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-81'
    },
    {
      id: 85,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-82'
    },
    {
      id: 86,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-83'
    },
    {
      id: 87,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-84'
    },
    {
      id: 88,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-85'
    },
    {
      id: 89,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-86'
    },
    {
      id: 90,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-87'
    },
    {
      id: 91,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-88'
    },
    {
      id: 92,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-89'
    },
    {
      id: 93,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-90'
    },
    {
      id: 94,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-91'
    },
    {
      id: 95,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-92'
    },
    {
      id: 96,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-93'
    },
    {
      id: 97,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-94'
    },
    {
      id: 98,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-95'
    },
    {
      id: 99,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-96'
    },
    {
      id: 100,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-97'
    },
    {
      id: 101,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-98'
    },
    {
      id: 102,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-99'
    },
    {
      id: 103,
      provider: 'icon_set_1',
      cssClass: 'icon_set_1_icon-100'
    }, {
    id: '200',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-102'
  }, {
    id: '201',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-103'
  }, {
    id: '202',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-104'
  }, {
    id: '203',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-105'
  }, {
    id: '204',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-106'
  }, {
    id: '205',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-107'
  }, {
    id: '206',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-108'
  }, {
    id: '207',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-109'
  }, {
    id: '208',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-110'
  }, {
    id: '209',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-111'
  }, {
    id: '210',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-112'
  }, {
    id: '211',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-113'
  }, {
    id: '212',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-114'
  }, {
    id: '213',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-115'
  }, {
    id: '214',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-116'
  }, {
    id: '215',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-117'
  }, {
    id: '216',
    provider: 'icon_set_2',
    cssClass: 'icon_set_2_icon-118'
  }, {
      id: 300,
      provider: 'fontello-icons',
      cssClass: 'icon-spin3'
    },
    {
      id: 301,
      provider: 'fontello-icons',
      cssClass: 'icon-youtube-4'
    },
    {
      id: 302,
      provider: 'fontello-icons',
      cssClass: 'icon-spin5'
    },
    {
      id: 303,
      provider: 'fontello-icons',
      cssClass: 'icon-spin6'
    },
    {
      id: 304,
      provider: 'fontello-icons',
      cssClass: 'icon-glass'
    },
    {
      id: 305,
      provider: 'fontello-icons',
      cssClass: 'icon-music'
    },
    {
      id: 306,
      provider: 'fontello-icons',
      cssClass: 'icon-search'
    },
    {
      id: 307,
      provider: 'fontello-icons',
      cssClass: 'icon-mail'
    },
    {
      id: 308,
      provider: 'fontello-icons',
      cssClass: 'icon-mail-alt'
    },
    {
      id: 309,
      provider: 'fontello-icons',
      cssClass: 'icon-heart'
    },
    {
      id: 310,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-empty'
    },
    {
      id: 311,
      provider: 'fontello-icons',
      cssClass: 'icon-star'
    },
    {
      id: 312,
      provider: 'fontello-icons',
      cssClass: 'icon-star-empty'
    },
    {
      id: 313,
      provider: 'fontello-icons',
      cssClass: 'icon-star-half'
    },
    {
      id: 314,
      provider: 'fontello-icons',
      cssClass: 'icon-star-half-alt'
    },
    {
      id: 315,
      provider: 'fontello-icons',
      cssClass: 'icon-user'
    },
    {
      id: 316,
      provider: 'fontello-icons',
      cssClass: 'icon-users'
    },
    {
      id: 317,
      provider: 'fontello-icons',
      cssClass: 'icon-male'
    },
    {
      id: 318,
      provider: 'fontello-icons',
      cssClass: 'icon-female'
    },
    {
      id: 319,
      provider: 'fontello-icons',
      cssClass: 'icon-video'
    },
    {
      id: 320,
      provider: 'fontello-icons',
      cssClass: 'icon-videocam'
    },
    {
      id: 321,
      provider: 'fontello-icons',
      cssClass: 'icon-picture'
    },
    {
      id: 322,
      provider: 'fontello-icons',
      cssClass: 'icon-camera'
    },
    {
      id: 323,
      provider: 'fontello-icons',
      cssClass: 'icon-camera-alt'
    },
    {
      id: 324,
      provider: 'fontello-icons',
      cssClass: 'icon-th-large'
    },
    {
      id: 325,
      provider: 'fontello-icons',
      cssClass: 'icon-th'
    },
    {
      id: 326,
      provider: 'fontello-icons',
      cssClass: 'icon-th-list'
    },
    {
      id: 327,
      provider: 'fontello-icons',
      cssClass: 'icon-ok'
    },
    {
      id: 328,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-circled'
    },
    {
      id: 329,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-circled2'
    },
    {
      id: 330,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-squared'
    },
    {
      id: 331,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel'
    },
    {
      id: 332,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circled'
    },
    {
      id: 333,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circled2'
    },
    {
      id: 334,
      provider: 'fontello-icons',
      cssClass: 'icon-plus'
    },
    {
      id: 335,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-circled'
    },
    {
      id: 336,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-squared'
    },
    {
      id: 337,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-squared-small'
    },
    {
      id: 338,
      provider: 'fontello-icons',
      cssClass: 'icon-minus'
    },
    {
      id: 339,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-circled'
    },
    {
      id: 340,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-squared'
    },
    {
      id: 341,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-squared-alt'
    },
    {
      id: 342,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-squared-small'
    },
    {
      id: 343,
      provider: 'fontello-icons',
      cssClass: 'icon-help'
    },
    {
      id: 344,
      provider: 'fontello-icons',
      cssClass: 'icon-help-circled'
    },
    {
      id: 345,
      provider: 'fontello-icons',
      cssClass: 'icon-info-circled'
    },
    {
      id: 346,
      provider: 'fontello-icons',
      cssClass: 'icon-info'
    },
    {
      id: 347,
      provider: 'fontello-icons',
      cssClass: 'icon-home'
    },
    {
      id: 348,
      provider: 'fontello-icons',
      cssClass: 'icon-link'
    },
    {
      id: 349,
      provider: 'fontello-icons',
      cssClass: 'icon-unlink'
    },
    {
      id: 350,
      provider: 'fontello-icons',
      cssClass: 'icon-link-ext'
    },
    {
      id: 351,
      provider: 'fontello-icons',
      cssClass: 'icon-link-ext-alt'
    },
    {
      id: 352,
      provider: 'fontello-icons',
      cssClass: 'icon-attach'
    },
    {
      id: 353,
      provider: 'fontello-icons',
      cssClass: 'icon-lock'
    },
    {
      id: 354,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open'
    },
    {
      id: 355,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-alt'
    },
    {
      id: 356,
      provider: 'fontello-icons',
      cssClass: 'icon-pin'
    },
    {
      id: 357,
      provider: 'fontello-icons',
      cssClass: 'icon-eye'
    },
    {
      id: 358,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-off'
    },
    {
      id: 359,
      provider: 'fontello-icons',
      cssClass: 'icon-tag'
    },
    {
      id: 360,
      provider: 'fontello-icons',
      cssClass: 'icon-tags'
    },
    {
      id: 361,
      provider: 'fontello-icons',
      cssClass: 'icon-bookmark'
    },
    {
      id: 362,
      provider: 'fontello-icons',
      cssClass: 'icon-bookmark-empty'
    },
    {
      id: 363,
      provider: 'fontello-icons',
      cssClass: 'icon-flag'
    },
    {
      id: 364,
      provider: 'fontello-icons',
      cssClass: 'icon-flag-empty'
    },
    {
      id: 365,
      provider: 'fontello-icons',
      cssClass: 'icon-flag-checkered'
    },
    {
      id: 366,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-up'
    },
    {
      id: 367,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-down'
    },
    {
      id: 368,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-up-alt'
    },
    {
      id: 369,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-down-alt'
    },
    {
      id: 370,
      provider: 'fontello-icons',
      cssClass: 'icon-download'
    },
    {
      id: 371,
      provider: 'fontello-icons',
      cssClass: 'icon-upload'
    },
    {
      id: 372,
      provider: 'fontello-icons',
      cssClass: 'icon-download-cloud'
    },
    {
      id: 373,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-cloud'
    },
    {
      id: 374,
      provider: 'fontello-icons',
      cssClass: 'icon-reply'
    },
    {
      id: 375,
      provider: 'fontello-icons',
      cssClass: 'icon-reply-all'
    },
    {
      id: 376,
      provider: 'fontello-icons',
      cssClass: 'icon-forward'
    },
    {
      id: 377,
      provider: 'fontello-icons',
      cssClass: 'icon-quote-left'
    },
    {
      id: 378,
      provider: 'fontello-icons',
      cssClass: 'icon-quote-right'
    },
    {
      id: 379,
      provider: 'fontello-icons',
      cssClass: 'icon-code'
    },
    {
      id: 380,
      provider: 'fontello-icons',
      cssClass: 'icon-export'
    },
    {
      id: 381,
      provider: 'fontello-icons',
      cssClass: 'icon-export-alt'
    },
    {
      id: 382,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil'
    },
    {
      id: 383,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-squared'
    },
    {
      id: 384,
      provider: 'fontello-icons',
      cssClass: 'icon-edit'
    },
    {
      id: 385,
      provider: 'fontello-icons',
      cssClass: 'icon-print'
    },
    {
      id: 386,
      provider: 'fontello-icons',
      cssClass: 'icon-retweet'
    },
    {
      id: 387,
      provider: 'fontello-icons',
      cssClass: 'icon-keyboard'
    },
    {
      id: 388,
      provider: 'fontello-icons',
      cssClass: 'icon-gamepad'
    },
    {
      id: 389,
      provider: 'fontello-icons',
      cssClass: 'icon-comment'
    },
    {
      id: 390,
      provider: 'fontello-icons',
      cssClass: 'icon-chat'
    },
    {
      id: 391,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-empty'
    },
    {
      id: 392,
      provider: 'fontello-icons',
      cssClass: 'icon-chat-empty'
    },
    {
      id: 393,
      provider: 'fontello-icons',
      cssClass: 'icon-bell'
    },
    {
      id: 394,
      provider: 'fontello-icons',
      cssClass: 'icon-bell-alt'
    },
    {
      id: 395,
      provider: 'fontello-icons',
      cssClass: 'icon-attention-alt'
    },
    {
      id: 396,
      provider: 'fontello-icons',
      cssClass: 'icon-attention'
    },
    {
      id: 397,
      provider: 'fontello-icons',
      cssClass: 'icon-attention-circled'
    },
    {
      id: 398,
      provider: 'fontello-icons',
      cssClass: 'icon-location'
    },
    {
      id: 399,
      provider: 'fontello-icons',
      cssClass: 'icon-direction'
    },
    {
      id: 400,
      provider: 'fontello-icons',
      cssClass: 'icon-compass'
    },
    {
      id: 401,
      provider: 'fontello-icons',
      cssClass: 'icon-trash'
    },
    {
      id: 402,
      provider: 'fontello-icons',
      cssClass: 'icon-doc'
    },
    {
      id: 403,
      provider: 'fontello-icons',
      cssClass: 'icon-docs'
    },
    {
      id: 404,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-text'
    },
    {
      id: 405,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-inv'
    },
    {
      id: 406,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-text-inv'
    },
    {
      id: 407,
      provider: 'fontello-icons',
      cssClass: 'icon-folder'
    },
    {
      id: 408,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-open'
    },
    {
      id: 409,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-empty'
    },
    {
      id: 410,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-open-empty'
    },
    {
      id: 411,
      provider: 'fontello-icons',
      cssClass: 'icon-box'
    },
    {
      id: 412,
      provider: 'fontello-icons',
      cssClass: 'icon-rss'
    },
    {
      id: 413,
      provider: 'fontello-icons',
      cssClass: 'icon-rss-squared'
    },
    {
      id: 414,
      provider: 'fontello-icons',
      cssClass: 'icon-phone'
    },
    {
      id: 415,
      provider: 'fontello-icons',
      cssClass: 'icon-phone-squared'
    },
    {
      id: 416,
      provider: 'fontello-icons',
      cssClass: 'icon-menu'
    },
    {
      id: 417,
      provider: 'fontello-icons',
      cssClass: 'icon-cog'
    },
    {
      id: 418,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-alt'
    },
    {
      id: 419,
      provider: 'fontello-icons',
      cssClass: 'icon-wrench'
    },
    {
      id: 420,
      provider: 'fontello-icons',
      cssClass: 'icon-basket'
    },
    {
      id: 421,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar'
    },
    {
      id: 422,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-empty'
    },
    {
      id: 423,
      provider: 'fontello-icons',
      cssClass: 'icon-login'
    },
    {
      id: 424,
      provider: 'fontello-icons',
      cssClass: 'icon-logout'
    },
    {
      id: 425,
      provider: 'fontello-icons',
      cssClass: 'icon-mic'
    },
    {
      id: 426,
      provider: 'fontello-icons',
      cssClass: 'icon-mute'
    },
    {
      id: 427,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-off'
    },
    {
      id: 428,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-down'
    },
    {
      id: 429,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-up'
    },
    {
      id: 430,
      provider: 'fontello-icons',
      cssClass: 'icon-headphones'
    },
    {
      id: 431,
      provider: 'fontello-icons',
      cssClass: 'icon-clock'
    },
    {
      id: 432,
      provider: 'fontello-icons',
      cssClass: 'icon-lightbulb'
    },
    {
      id: 433,
      provider: 'fontello-icons',
      cssClass: 'icon-block'
    },
    {
      id: 434,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full'
    },
    {
      id: 435,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-alt'
    },
    {
      id: 436,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-small'
    },
    {
      id: 437,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-vertical'
    },
    {
      id: 438,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-horizontal'
    },
    {
      id: 439,
      provider: 'fontello-icons',
      cssClass: 'icon-move'
    },
    {
      id: 440,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-in'
    },
    {
      id: 441,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-out'
    },
    {
      id: 442,
      provider: 'fontello-icons',
      cssClass: 'icon-down-circled2'
    },
    {
      id: 443,
      provider: 'fontello-icons',
      cssClass: 'icon-up-circled2'
    },
    {
      id: 444,
      provider: 'fontello-icons',
      cssClass: 'icon-left-circled2'
    },
    {
      id: 445,
      provider: 'fontello-icons',
      cssClass: 'icon-right-circled2'
    },
    {
      id: 446,
      provider: 'fontello-icons',
      cssClass: 'icon-down-dir'
    },
    {
      id: 447,
      provider: 'fontello-icons',
      cssClass: 'icon-up-dir'
    },
    {
      id: 448,
      provider: 'fontello-icons',
      cssClass: 'icon-left-dir'
    },
    {
      id: 449,
      provider: 'fontello-icons',
      cssClass: 'icon-right-dir'
    },
    {
      id: 450,
      provider: 'fontello-icons',
      cssClass: 'icon-down-open'
    },
    {
      id: 451,
      provider: 'fontello-icons',
      cssClass: 'icon-left-open'
    },
    {
      id: 452,
      provider: 'fontello-icons',
      cssClass: 'icon-right-open'
    },
    {
      id: 453,
      provider: 'fontello-icons',
      cssClass: 'icon-up-open'
    },
    {
      id: 454,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-left'
    },
    {
      id: 455,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-right'
    },
    {
      id: 456,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-up'
    },
    {
      id: 457,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-down'
    },
    {
      id: 458,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-circled-left'
    },
    {
      id: 459,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-circled-right'
    },
    {
      id: 460,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-circled-up'
    },
    {
      id: 461,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-circled-down'
    },
    {
      id: 462,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-double-left'
    },
    {
      id: 463,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-double-right'
    },
    {
      id: 464,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-double-up'
    },
    {
      id: 465,
      provider: 'fontello-icons',
      cssClass: 'icon-angle-double-down'
    },
    {
      id: 466,
      provider: 'fontello-icons',
      cssClass: 'icon-down'
    },
    {
      id: 467,
      provider: 'fontello-icons',
      cssClass: 'icon-left'
    },
    {
      id: 468,
      provider: 'fontello-icons',
      cssClass: 'icon-right'
    },
    {
      id: 469,
      provider: 'fontello-icons',
      cssClass: 'icon-up'
    },
    {
      id: 470,
      provider: 'fontello-icons',
      cssClass: 'icon-down-big'
    },
    {
      id: 471,
      provider: 'fontello-icons',
      cssClass: 'icon-left-big'
    },
    {
      id: 472,
      provider: 'fontello-icons',
      cssClass: 'icon-right-big'
    },
    {
      id: 473,
      provider: 'fontello-icons',
      cssClass: 'icon-up-big'
    },
    {
      id: 474,
      provider: 'fontello-icons',
      cssClass: 'icon-right-hand'
    },
    {
      id: 475,
      provider: 'fontello-icons',
      cssClass: 'icon-left-hand'
    },
    {
      id: 476,
      provider: 'fontello-icons',
      cssClass: 'icon-up-hand'
    },
    {
      id: 477,
      provider: 'fontello-icons',
      cssClass: 'icon-down-hand'
    },
    {
      id: 478,
      provider: 'fontello-icons',
      cssClass: 'icon-left-circled'
    },
    {
      id: 479,
      provider: 'fontello-icons',
      cssClass: 'icon-right-circled'
    },
    {
      id: 480,
      provider: 'fontello-icons',
      cssClass: 'icon-up-circled'
    },
    {
      id: 481,
      provider: 'fontello-icons',
      cssClass: 'icon-down-circled'
    },
    {
      id: 482,
      provider: 'fontello-icons',
      cssClass: 'icon-cw'
    },
    {
      id: 483,
      provider: 'fontello-icons',
      cssClass: 'icon-ccw'
    },
    {
      id: 484,
      provider: 'fontello-icons',
      cssClass: 'icon-arrows-cw'
    },
    {
      id: 485,
      provider: 'fontello-icons',
      cssClass: 'icon-level-up'
    },
    {
      id: 486,
      provider: 'fontello-icons',
      cssClass: 'icon-level-down'
    },
    {
      id: 487,
      provider: 'fontello-icons',
      cssClass: 'icon-shuffle'
    },
    {
      id: 488,
      provider: 'fontello-icons',
      cssClass: 'icon-exchange'
    },
    {
      id: 489,
      provider: 'fontello-icons',
      cssClass: 'icon-expand'
    },
    {
      id: 490,
      provider: 'fontello-icons',
      cssClass: 'icon-collapse'
    },
    {
      id: 491,
      provider: 'fontello-icons',
      cssClass: 'icon-expand-right'
    },
    {
      id: 492,
      provider: 'fontello-icons',
      cssClass: 'icon-collapse-left'
    },
    {
      id: 493,
      provider: 'fontello-icons',
      cssClass: 'icon-play'
    },
    {
      id: 494,
      provider: 'fontello-icons',
      cssClass: 'icon-play-circled'
    },
    {
      id: 495,
      provider: 'fontello-icons',
      cssClass: 'icon-play-circled2'
    },
    {
      id: 496,
      provider: 'fontello-icons',
      cssClass: 'icon-stop'
    },
    {
      id: 497,
      provider: 'fontello-icons',
      cssClass: 'icon-pause'
    },
    {
      id: 498,
      provider: 'fontello-icons',
      cssClass: 'icon-to-end'
    },
    {
      id: 499,
      provider: 'fontello-icons',
      cssClass: 'icon-to-end-alt'
    },
    {
      id: 500,
      provider: 'fontello-icons',
      cssClass: 'icon-to-start'
    },
    {
      id: 501,
      provider: 'fontello-icons',
      cssClass: 'icon-to-start-alt'
    },
    {
      id: 502,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-fw'
    },
    {
      id: 503,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-bw'
    },
    {
      id: 504,
      provider: 'fontello-icons',
      cssClass: 'icon-eject'
    },
    {
      id: 505,
      provider: 'fontello-icons',
      cssClass: 'icon-target'
    },
    {
      id: 506,
      provider: 'fontello-icons',
      cssClass: 'icon-signal'
    },
    {
      id: 507,
      provider: 'fontello-icons',
      cssClass: 'icon-award'
    },
    {
      id: 508,
      provider: 'fontello-icons',
      cssClass: 'icon-desktop'
    },
    {
      id: 509,
      provider: 'fontello-icons',
      cssClass: 'icon-laptop'
    },
    {
      id: 510,
      provider: 'fontello-icons',
      cssClass: 'icon-tablet'
    },
    {
      id: 511,
      provider: 'fontello-icons',
      cssClass: 'icon-mobile'
    },
    {
      id: 512,
      provider: 'fontello-icons',
      cssClass: 'icon-inbox'
    },
    {
      id: 513,
      provider: 'fontello-icons',
      cssClass: 'icon-globe'
    },
    {
      id: 514,
      provider: 'fontello-icons',
      cssClass: 'icon-sun'
    },
    {
      id: 515,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud'
    },
    {
      id: 516,
      provider: 'fontello-icons',
      cssClass: 'icon-flash'
    },
    {
      id: 517,
      provider: 'fontello-icons',
      cssClass: 'icon-moon'
    },
    {
      id: 518,
      provider: 'fontello-icons',
      cssClass: 'icon-umbrella'
    },
    {
      id: 519,
      provider: 'fontello-icons',
      cssClass: 'icon-flight'
    },
    {
      id: 520,
      provider: 'fontello-icons',
      cssClass: 'icon-fighter-jet'
    },
    {
      id: 521,
      provider: 'fontello-icons',
      cssClass: 'icon-leaf'
    },
    {
      id: 522,
      provider: 'fontello-icons',
      cssClass: 'icon-font'
    },
    {
      id: 523,
      provider: 'fontello-icons',
      cssClass: 'icon-bold'
    },
    {
      id: 524,
      provider: 'fontello-icons',
      cssClass: 'icon-italic'
    },
    {
      id: 525,
      provider: 'fontello-icons',
      cssClass: 'icon-text-height'
    },
    {
      id: 526,
      provider: 'fontello-icons',
      cssClass: 'icon-text-width'
    },
    {
      id: 527,
      provider: 'fontello-icons',
      cssClass: 'icon-align-left'
    },
    {
      id: 528,
      provider: 'fontello-icons',
      cssClass: 'icon-align-center'
    },
    {
      id: 529,
      provider: 'fontello-icons',
      cssClass: 'icon-align-right'
    },
    {
      id: 530,
      provider: 'fontello-icons',
      cssClass: 'icon-align-justify'
    },
    {
      id: 531,
      provider: 'fontello-icons',
      cssClass: 'icon-list'
    },
    {
      id: 532,
      provider: 'fontello-icons',
      cssClass: 'icon-indent-left'
    },
    {
      id: 533,
      provider: 'fontello-icons',
      cssClass: 'icon-indent-right'
    },
    {
      id: 534,
      provider: 'fontello-icons',
      cssClass: 'icon-list-bullet'
    },
    {
      id: 535,
      provider: 'fontello-icons',
      cssClass: 'icon-list-numbered'
    },
    {
      id: 536,
      provider: 'fontello-icons',
      cssClass: 'icon-strike'
    },
    {
      id: 537,
      provider: 'fontello-icons',
      cssClass: 'icon-underline'
    },
    {
      id: 538,
      provider: 'fontello-icons',
      cssClass: 'icon-superscript'
    },
    {
      id: 539,
      provider: 'fontello-icons',
      cssClass: 'icon-subscript'
    },
    {
      id: 540,
      provider: 'fontello-icons',
      cssClass: 'icon-table'
    },
    {
      id: 541,
      provider: 'fontello-icons',
      cssClass: 'icon-columns'
    },
    {
      id: 542,
      provider: 'fontello-icons',
      cssClass: 'icon-crop'
    },
    {
      id: 543,
      provider: 'fontello-icons',
      cssClass: 'icon-scissors'
    },
    {
      id: 544,
      provider: 'fontello-icons',
      cssClass: 'icon-paste'
    },
    {
      id: 545,
      provider: 'fontello-icons',
      cssClass: 'icon-briefcase'
    },
    {
      id: 546,
      provider: 'fontello-icons',
      cssClass: 'icon-suitcase'
    },
    {
      id: 547,
      provider: 'fontello-icons',
      cssClass: 'icon-ellipsis'
    },
    {
      id: 548,
      provider: 'fontello-icons',
      cssClass: 'icon-ellipsis-vert'
    },
    {
      id: 549,
      provider: 'fontello-icons',
      cssClass: 'icon-off'
    },
    {
      id: 550,
      provider: 'fontello-icons',
      cssClass: 'icon-road'
    },
    {
      id: 551,
      provider: 'fontello-icons',
      cssClass: 'icon-list-alt'
    },
    {
      id: 552,
      provider: 'fontello-icons',
      cssClass: 'icon-qrcode'
    },
    {
      id: 553,
      provider: 'fontello-icons',
      cssClass: 'icon-barcode'
    },
    {
      id: 554,
      provider: 'fontello-icons',
      cssClass: 'icon-book'
    },
    {
      id: 555,
      provider: 'fontello-icons',
      cssClass: 'icon-ajust'
    },
    {
      id: 556,
      provider: 'fontello-icons',
      cssClass: 'icon-tint'
    },
    {
      id: 557,
      provider: 'fontello-icons',
      cssClass: 'icon-check'
    },
    {
      id: 558,
      provider: 'fontello-icons',
      cssClass: 'icon-check-empty'
    },
    {
      id: 559,
      provider: 'fontello-icons',
      cssClass: 'icon-circle'
    },
    {
      id: 560,
      provider: 'fontello-icons',
      cssClass: 'icon-circle-empty'
    },
    {
      id: 561,
      provider: 'fontello-icons',
      cssClass: 'icon-dot-circled'
    },
    {
      id: 562,
      provider: 'fontello-icons',
      cssClass: 'icon-asterisk'
    },
    {
      id: 563,
      provider: 'fontello-icons',
      cssClass: 'icon-gift'
    },
    {
      id: 564,
      provider: 'fontello-icons',
      cssClass: 'icon-fire'
    },
    {
      id: 565,
      provider: 'fontello-icons',
      cssClass: 'icon-magnet'
    },
    {
      id: 566,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-bar'
    },
    {
      id: 567,
      provider: 'fontello-icons',
      cssClass: 'icon-ticket'
    },
    {
      id: 568,
      provider: 'fontello-icons',
      cssClass: 'icon-credit-card'
    },
    {
      id: 569,
      provider: 'fontello-icons',
      cssClass: 'icon-floppy'
    },
    {
      id: 570,
      provider: 'fontello-icons',
      cssClass: 'icon-megaphone'
    },
    {
      id: 571,
      provider: 'fontello-icons',
      cssClass: 'icon-hdd'
    },
    {
      id: 572,
      provider: 'fontello-icons',
      cssClass: 'icon-key'
    },
    {
      id: 573,
      provider: 'fontello-icons',
      cssClass: 'icon-fork'
    },
    {
      id: 574,
      provider: 'fontello-icons',
      cssClass: 'icon-rocket'
    },
    {
      id: 575,
      provider: 'fontello-icons',
      cssClass: 'icon-bug'
    },
    {
      id: 576,
      provider: 'fontello-icons',
      cssClass: 'icon-certificate'
    },
    {
      id: 577,
      provider: 'fontello-icons',
      cssClass: 'icon-tasks'
    },
    {
      id: 578,
      provider: 'fontello-icons',
      cssClass: 'icon-filter'
    },
    {
      id: 579,
      provider: 'fontello-icons',
      cssClass: 'icon-beaker'
    },
    {
      id: 580,
      provider: 'fontello-icons',
      cssClass: 'icon-magic'
    },
    {
      id: 581,
      provider: 'fontello-icons',
      cssClass: 'icon-truck'
    },
    {
      id: 582,
      provider: 'fontello-icons',
      cssClass: 'icon-money'
    },
    {
      id: 583,
      provider: 'fontello-icons',
      cssClass: 'icon-euro'
    },
    {
      id: 584,
      provider: 'fontello-icons',
      cssClass: 'icon-pound'
    },
    {
      id: 585,
      provider: 'fontello-icons',
      cssClass: 'icon-dollar'
    },
    {
      id: 586,
      provider: 'fontello-icons',
      cssClass: 'icon-rupee'
    },
    {
      id: 587,
      provider: 'fontello-icons',
      cssClass: 'icon-yen'
    },
    {
      id: 588,
      provider: 'fontello-icons',
      cssClass: 'icon-rouble'
    },
    {
      id: 589,
      provider: 'fontello-icons',
      cssClass: 'icon-try'
    },
    {
      id: 590,
      provider: 'fontello-icons',
      cssClass: 'icon-won'
    },
    {
      id: 591,
      provider: 'fontello-icons',
      cssClass: 'icon-bitcoin'
    },
    {
      id: 592,
      provider: 'fontello-icons',
      cssClass: 'icon-sort'
    },
    {
      id: 593,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-down'
    },
    {
      id: 594,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-up'
    },
    {
      id: 595,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-alt-up'
    },
    {
      id: 596,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-alt-down'
    },
    {
      id: 597,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-name-up'
    },
    {
      id: 598,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-name-down'
    },
    {
      id: 599,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-number-up'
    },
    {
      id: 600,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-number-down'
    },
    {
      id: 601,
      provider: 'fontello-icons',
      cssClass: 'icon-hammer'
    },
    {
      id: 602,
      provider: 'fontello-icons',
      cssClass: 'icon-gauge'
    },
    {
      id: 603,
      provider: 'fontello-icons',
      cssClass: 'icon-sitemap'
    },
    {
      id: 604,
      provider: 'fontello-icons',
      cssClass: 'icon-spinner'
    },
    {
      id: 605,
      provider: 'fontello-icons',
      cssClass: 'icon-coffee'
    },
    {
      id: 606,
      provider: 'fontello-icons',
      cssClass: 'icon-food'
    },
    {
      id: 607,
      provider: 'fontello-icons',
      cssClass: 'icon-beer'
    },
    {
      id: 608,
      provider: 'fontello-icons',
      cssClass: 'icon-user-md'
    },
    {
      id: 609,
      provider: 'fontello-icons',
      cssClass: 'icon-stethoscope'
    },
    {
      id: 610,
      provider: 'fontello-icons',
      cssClass: 'icon-ambulance'
    },
    {
      id: 611,
      provider: 'fontello-icons',
      cssClass: 'icon-medkit'
    },
    {
      id: 612,
      provider: 'fontello-icons',
      cssClass: 'icon-h-sigh'
    },
    {
      id: 613,
      provider: 'fontello-icons',
      cssClass: 'icon-hospital'
    },
    {
      id: 614,
      provider: 'fontello-icons',
      cssClass: 'icon-building'
    },
    {
      id: 615,
      provider: 'fontello-icons',
      cssClass: 'icon-smile'
    },
    {
      id: 616,
      provider: 'fontello-icons',
      cssClass: 'icon-frown'
    },
    {
      id: 617,
      provider: 'fontello-icons',
      cssClass: 'icon-meh'
    },
    {
      id: 618,
      provider: 'fontello-icons',
      cssClass: 'icon-anchor'
    },
    {
      id: 619,
      provider: 'fontello-icons',
      cssClass: 'icon-terminal'
    },
    {
      id: 620,
      provider: 'fontello-icons',
      cssClass: 'icon-eraser'
    },
    {
      id: 621,
      provider: 'fontello-icons',
      cssClass: 'icon-puzzle'
    },
    {
      id: 622,
      provider: 'fontello-icons',
      cssClass: 'icon-shield'
    },
    {
      id: 623,
      provider: 'fontello-icons',
      cssClass: 'icon-extinguisher'
    },
    {
      id: 624,
      provider: 'fontello-icons',
      cssClass: 'icon-bullseye'
    },
    {
      id: 625,
      provider: 'fontello-icons',
      cssClass: 'icon-wheelchair'
    },
    {
      id: 626,
      provider: 'fontello-icons',
      cssClass: 'icon-adn'
    },
    {
      id: 627,
      provider: 'fontello-icons',
      cssClass: 'icon-android'
    },
    {
      id: 628,
      provider: 'fontello-icons',
      cssClass: 'icon-apple'
    },
    {
      id: 629,
      provider: 'fontello-icons',
      cssClass: 'icon-bitbucket'
    },
    {
      id: 630,
      provider: 'fontello-icons',
      cssClass: 'icon-bitbucket-squared'
    },
    {
      id: 631,
      provider: 'fontello-icons',
      cssClass: 'icon-css3'
    },
    {
      id: 632,
      provider: 'fontello-icons',
      cssClass: 'icon-dribbble'
    },
    {
      id: 633,
      provider: 'fontello-icons',
      cssClass: 'icon-dropbox'
    },
    {
      id: 634,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook'
    },
    {
      id: 635,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-squared'
    },
    {
      id: 636,
      provider: 'fontello-icons',
      cssClass: 'icon-flickr'
    },
    {
      id: 637,
      provider: 'fontello-icons',
      cssClass: 'icon-foursquare'
    },
    {
      id: 638,
      provider: 'fontello-icons',
      cssClass: 'icon-github'
    },
    {
      id: 639,
      provider: 'fontello-icons',
      cssClass: 'icon-github-squared'
    },
    {
      id: 640,
      provider: 'fontello-icons',
      cssClass: 'icon-github-circled'
    },
    {
      id: 641,
      provider: 'fontello-icons',
      cssClass: 'icon-gittip'
    },
    {
      id: 642,
      provider: 'fontello-icons',
      cssClass: 'icon-gplus-squared'
    },
    {
      id: 643,
      provider: 'fontello-icons',
      cssClass: 'icon-gplus'
    },
    {
      id: 644,
      provider: 'fontello-icons',
      cssClass: 'icon-html5'
    },
    {
      id: 645,
      provider: 'fontello-icons',
      cssClass: 'icon-instagramm'
    },
    {
      id: 646,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-squared'
    },
    {
      id: 647,
      provider: 'fontello-icons',
      cssClass: 'icon-linux'
    },
    {
      id: 648,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin'
    },
    {
      id: 649,
      provider: 'fontello-icons',
      cssClass: 'icon-maxcdn'
    },
    {
      id: 650,
      provider: 'fontello-icons',
      cssClass: 'icon-pagelines'
    },
    {
      id: 651,
      provider: 'fontello-icons',
      cssClass: 'icon-pinterest-circled'
    },
    {
      id: 652,
      provider: 'fontello-icons',
      cssClass: 'icon-pinterest-squared'
    },
    {
      id: 653,
      provider: 'fontello-icons',
      cssClass: 'icon-renren'
    },
    {
      id: 654,
      provider: 'fontello-icons',
      cssClass: 'icon-skype'
    },
    {
      id: 655,
      provider: 'fontello-icons',
      cssClass: 'icon-stackexchange'
    },
    {
      id: 656,
      provider: 'fontello-icons',
      cssClass: 'icon-stackoverflow'
    },
    {
      id: 657,
      provider: 'fontello-icons',
      cssClass: 'icon-trello'
    },
    {
      id: 658,
      provider: 'fontello-icons',
      cssClass: 'icon-tumblr'
    },
    {
      id: 659,
      provider: 'fontello-icons',
      cssClass: 'icon-tumblr-squared'
    },
    {
      id: 660,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-squared'
    },
    {
      id: 661,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter'
    },
    {
      id: 662,
      provider: 'fontello-icons',
      cssClass: 'icon-vimeo-squared'
    },
    {
      id: 663,
      provider: 'fontello-icons',
      cssClass: 'icon-vkontakte'
    },
    {
      id: 664,
      provider: 'fontello-icons',
      cssClass: 'icon-weibo'
    },
    {
      id: 665,
      provider: 'fontello-icons',
      cssClass: 'icon-windows'
    },
    {
      id: 666,
      provider: 'fontello-icons',
      cssClass: 'icon-xing'
    },
    {
      id: 667,
      provider: 'fontello-icons',
      cssClass: 'icon-xing-squared'
    },
    {
      id: 668,
      provider: 'fontello-icons',
      cssClass: 'icon-youtube'
    },
    {
      id: 669,
      provider: 'fontello-icons',
      cssClass: 'icon-youtube-squared'
    },
    {
      id: 670,
      provider: 'fontello-icons',
      cssClass: 'icon-youtube-play'
    },
    {
      id: 671,
      provider: 'fontello-icons',
      cssClass: 'icon-blank'
    },
    {
      id: 672,
      provider: 'fontello-icons',
      cssClass: 'icon-lemon'
    },
    {
      id: 673,
      provider: 'fontello-icons',
      cssClass: 'icon-note'
    },
    {
      id: 674,
      provider: 'fontello-icons',
      cssClass: 'icon-note-beamed'
    },
    {
      id: 675,
      provider: 'fontello-icons',
      cssClass: 'icon-music-1'
    },
    {
      id: 676,
      provider: 'fontello-icons',
      cssClass: 'icon-search-1'
    },
    {
      id: 677,
      provider: 'fontello-icons',
      cssClass: 'icon-flashlight'
    },
    {
      id: 678,
      provider: 'fontello-icons',
      cssClass: 'icon-mail-1'
    },
    {
      id: 679,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-1'
    },
    {
      id: 680,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-empty-1'
    },
    {
      id: 681,
      provider: 'fontello-icons',
      cssClass: 'icon-star-1'
    },
    {
      id: 682,
      provider: 'fontello-icons',
      cssClass: 'icon-star-empty-1'
    },
    {
      id: 683,
      provider: 'fontello-icons',
      cssClass: 'icon-user-1'
    },
    {
      id: 684,
      provider: 'fontello-icons',
      cssClass: 'icon-users-1'
    },
    {
      id: 685,
      provider: 'fontello-icons',
      cssClass: 'icon-user-add'
    },
    {
      id: 686,
      provider: 'fontello-icons',
      cssClass: 'icon-video-1'
    },
    {
      id: 687,
      provider: 'fontello-icons',
      cssClass: 'icon-picture-1'
    },
    {
      id: 688,
      provider: 'fontello-icons',
      cssClass: 'icon-camera-1'
    },
    {
      id: 689,
      provider: 'fontello-icons',
      cssClass: 'icon-layout'
    },
    {
      id: 690,
      provider: 'fontello-icons',
      cssClass: 'icon-menu-1'
    },
    {
      id: 691,
      provider: 'fontello-icons',
      cssClass: 'icon-check-1'
    },
    {
      id: 692,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-1'
    },
    {
      id: 693,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circled-1'
    },
    {
      id: 694,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-squared'
    },
    {
      id: 695,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-1'
    },
    {
      id: 696,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-circled-1'
    },
    {
      id: 697,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-squared-1'
    },
    {
      id: 698,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-1'
    },
    {
      id: 699,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-circled-1'
    },
    {
      id: 700,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-squared-1'
    },
    {
      id: 701,
      provider: 'fontello-icons',
      cssClass: 'icon-help-1'
    },
    {
      id: 702,
      provider: 'fontello-icons',
      cssClass: 'icon-help-circled-1'
    },
    {
      id: 703,
      provider: 'fontello-icons',
      cssClass: 'icon-info-1'
    },
    {
      id: 704,
      provider: 'fontello-icons',
      cssClass: 'icon-info-circled-1'
    },
    {
      id: 705,
      provider: 'fontello-icons',
      cssClass: 'icon-back'
    },
    {
      id: 706,
      provider: 'fontello-icons',
      cssClass: 'icon-home-1'
    },
    {
      id: 707,
      provider: 'fontello-icons',
      cssClass: 'icon-link-1'
    },
    {
      id: 708,
      provider: 'fontello-icons',
      cssClass: 'icon-attach-1'
    },
    {
      id: 709,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-1'
    },
    {
      id: 710,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-1'
    },
    {
      id: 711,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-1'
    },
    {
      id: 712,
      provider: 'fontello-icons',
      cssClass: 'icon-tag-1'
    },
    {
      id: 713,
      provider: 'fontello-icons',
      cssClass: 'icon-bookmark-1'
    },
    {
      id: 714,
      provider: 'fontello-icons',
      cssClass: 'icon-bookmarks'
    },
    {
      id: 715,
      provider: 'fontello-icons',
      cssClass: 'icon-flag-1'
    },
    {
      id: 716,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-up-1'
    },
    {
      id: 717,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-down-1'
    },
    {
      id: 718,
      provider: 'fontello-icons',
      cssClass: 'icon-download-1'
    },
    {
      id: 719,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-1'
    },
    {
      id: 720,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-cloud-1'
    },
    {
      id: 721,
      provider: 'fontello-icons',
      cssClass: 'icon-reply-1'
    },
    {
      id: 722,
      provider: 'fontello-icons',
      cssClass: 'icon-reply-all-1'
    },
    {
      id: 723,
      provider: 'fontello-icons',
      cssClass: 'icon-forward-1'
    },
    {
      id: 724,
      provider: 'fontello-icons',
      cssClass: 'icon-quote'
    },
    {
      id: 725,
      provider: 'fontello-icons',
      cssClass: 'icon-code-1'
    },
    {
      id: 726,
      provider: 'fontello-icons',
      cssClass: 'icon-export-1'
    },
    {
      id: 727,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-1'
    },
    {
      id: 728,
      provider: 'fontello-icons',
      cssClass: 'icon-feather'
    },
    {
      id: 729,
      provider: 'fontello-icons',
      cssClass: 'icon-print-1'
    },
    {
      id: 730,
      provider: 'fontello-icons',
      cssClass: 'icon-retweet-1'
    },
    {
      id: 731,
      provider: 'fontello-icons',
      cssClass: 'icon-keyboard-1'
    },
    {
      id: 732,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-1'
    },
    {
      id: 733,
      provider: 'fontello-icons',
      cssClass: 'icon-chat-1'
    },
    {
      id: 734,
      provider: 'fontello-icons',
      cssClass: 'icon-bell-1'
    },
    {
      id: 735,
      provider: 'fontello-icons',
      cssClass: 'icon-attention-1'
    },
    {
      id: 736,
      provider: 'fontello-icons',
      cssClass: 'icon-alert'
    },
    {
      id: 737,
      provider: 'fontello-icons',
      cssClass: 'icon-vcard'
    },
    {
      id: 738,
      provider: 'fontello-icons',
      cssClass: 'icon-address'
    },
    {
      id: 739,
      provider: 'fontello-icons',
      cssClass: 'icon-location-1'
    },
    {
      id: 740,
      provider: 'fontello-icons',
      cssClass: 'icon-map'
    },
    {
      id: 741,
      provider: 'fontello-icons',
      cssClass: 'icon-direction-1'
    },
    {
      id: 742,
      provider: 'fontello-icons',
      cssClass: 'icon-compass-1'
    },
    {
      id: 743,
      provider: 'fontello-icons',
      cssClass: 'icon-cup'
    },
    {
      id: 744,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-1'
    },
    {
      id: 745,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-1'
    },
    {
      id: 746,
      provider: 'fontello-icons',
      cssClass: 'icon-docs-1'
    },
    {
      id: 747,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-landscape'
    },
    {
      id: 748,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-text-1'
    },
    {
      id: 749,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-text-inv-1'
    },
    {
      id: 750,
      provider: 'fontello-icons',
      cssClass: 'icon-newspaper'
    },
    {
      id: 751,
      provider: 'fontello-icons',
      cssClass: 'icon-book-open'
    },
    {
      id: 752,
      provider: 'fontello-icons',
      cssClass: 'icon-book-1'
    },
    {
      id: 753,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-1'
    },
    {
      id: 754,
      provider: 'fontello-icons',
      cssClass: 'icon-archive'
    },
    {
      id: 755,
      provider: 'fontello-icons',
      cssClass: 'icon-box-1'
    },
    {
      id: 756,
      provider: 'fontello-icons',
      cssClass: 'icon-rss-1'
    },
    {
      id: 757,
      provider: 'fontello-icons',
      cssClass: 'icon-phone-1'
    },
    {
      id: 758,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-1'
    },
    {
      id: 759,
      provider: 'fontello-icons',
      cssClass: 'icon-tools'
    },
    {
      id: 760,
      provider: 'fontello-icons',
      cssClass: 'icon-share'
    },
    {
      id: 761,
      provider: 'fontello-icons',
      cssClass: 'icon-shareable'
    },
    {
      id: 762,
      provider: 'fontello-icons',
      cssClass: 'icon-basket-1'
    },
    {
      id: 763,
      provider: 'fontello-icons',
      cssClass: 'icon-bag'
    },
    {
      id: 764,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-1'
    },
    {
      id: 765,
      provider: 'fontello-icons',
      cssClass: 'icon-login-1'
    },
    {
      id: 766,
      provider: 'fontello-icons',
      cssClass: 'icon-logout-1'
    },
    {
      id: 767,
      provider: 'fontello-icons',
      cssClass: 'icon-mic-1'
    },
    {
      id: 768,
      provider: 'fontello-icons',
      cssClass: 'icon-mute-1'
    },
    {
      id: 769,
      provider: 'fontello-icons',
      cssClass: 'icon-sound'
    },
    {
      id: 770,
      provider: 'fontello-icons',
      cssClass: 'icon-volume'
    },
    {
      id: 771,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-1'
    },
    {
      id: 772,
      provider: 'fontello-icons',
      cssClass: 'icon-hourglass'
    },
    {
      id: 773,
      provider: 'fontello-icons',
      cssClass: 'icon-lamp'
    },
    {
      id: 774,
      provider: 'fontello-icons',
      cssClass: 'icon-light-down'
    },
    {
      id: 775,
      provider: 'fontello-icons',
      cssClass: 'icon-light-up'
    },
    {
      id: 776,
      provider: 'fontello-icons',
      cssClass: 'icon-adjust'
    },
    {
      id: 777,
      provider: 'fontello-icons',
      cssClass: 'icon-block-1'
    },
    {
      id: 778,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-1'
    },
    {
      id: 779,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-small-1'
    },
    {
      id: 780,
      provider: 'fontello-icons',
      cssClass: 'icon-popup'
    },
    {
      id: 781,
      provider: 'fontello-icons',
      cssClass: 'icon-publish'
    },
    {
      id: 782,
      provider: 'fontello-icons',
      cssClass: 'icon-window'
    },
    {
      id: 783,
      provider: 'fontello-icons',
      cssClass: 'icon-arrow-combo'
    },
    {
      id: 784,
      provider: 'fontello-icons',
      cssClass: 'icon-down-circled-1'
    },
    {
      id: 785,
      provider: 'fontello-icons',
      cssClass: 'icon-left-circled-1'
    },
    {
      id: 786,
      provider: 'fontello-icons',
      cssClass: 'icon-right-circled-1'
    },
    {
      id: 787,
      provider: 'fontello-icons',
      cssClass: 'icon-up-circled-1'
    },
    {
      id: 788,
      provider: 'fontello-icons',
      cssClass: 'icon-down-open-1'
    },
    {
      id: 789,
      provider: 'fontello-icons',
      cssClass: 'icon-left-open-1'
    },
    {
      id: 790,
      provider: 'fontello-icons',
      cssClass: 'icon-right-open-1'
    },
    {
      id: 791,
      provider: 'fontello-icons',
      cssClass: 'icon-up-open-1'
    },
    {
      id: 792,
      provider: 'fontello-icons',
      cssClass: 'icon-down-open-mini'
    },
    {
      id: 793,
      provider: 'fontello-icons',
      cssClass: 'icon-left-open-mini'
    },
    {
      id: 794,
      provider: 'fontello-icons',
      cssClass: 'icon-right-open-mini'
    },
    {
      id: 795,
      provider: 'fontello-icons',
      cssClass: 'icon-up-open-mini'
    },
    {
      id: 796,
      provider: 'fontello-icons',
      cssClass: 'icon-down-open-big'
    },
    {
      id: 797,
      provider: 'fontello-icons',
      cssClass: 'icon-left-open-big'
    },
    {
      id: 798,
      provider: 'fontello-icons',
      cssClass: 'icon-right-open-big'
    },
    {
      id: 799,
      provider: 'fontello-icons',
      cssClass: 'icon-up-open-big'
    },
    {
      id: 800,
      provider: 'fontello-icons',
      cssClass: 'icon-down-1'
    },
    {
      id: 801,
      provider: 'fontello-icons',
      cssClass: 'icon-left-1'
    },
    {
      id: 802,
      provider: 'fontello-icons',
      cssClass: 'icon-right-1'
    },
    {
      id: 803,
      provider: 'fontello-icons',
      cssClass: 'icon-up-1'
    },
    {
      id: 804,
      provider: 'fontello-icons',
      cssClass: 'icon-down-dir-1'
    },
    {
      id: 805,
      provider: 'fontello-icons',
      cssClass: 'icon-left-dir-1'
    },
    {
      id: 806,
      provider: 'fontello-icons',
      cssClass: 'icon-right-dir-1'
    },
    {
      id: 807,
      provider: 'fontello-icons',
      cssClass: 'icon-up-dir-1'
    },
    {
      id: 808,
      provider: 'fontello-icons',
      cssClass: 'icon-down-bold'
    },
    {
      id: 809,
      provider: 'fontello-icons',
      cssClass: 'icon-left-bold'
    },
    {
      id: 810,
      provider: 'fontello-icons',
      cssClass: 'icon-right-bold'
    },
    {
      id: 811,
      provider: 'fontello-icons',
      cssClass: 'icon-up-bold'
    },
    {
      id: 812,
      provider: 'fontello-icons',
      cssClass: 'icon-down-thin'
    },
    {
      id: 813,
      provider: 'fontello-icons',
      cssClass: 'icon-left-thin'
    },
    {
      id: 814,
      provider: 'fontello-icons',
      cssClass: 'icon-right-thin'
    },
    {
      id: 815,
      provider: 'fontello-icons',
      cssClass: 'icon-up-thin'
    },
    {
      id: 816,
      provider: 'fontello-icons',
      cssClass: 'icon-ccw-1'
    },
    {
      id: 817,
      provider: 'fontello-icons',
      cssClass: 'icon-cw-1'
    },
    {
      id: 818,
      provider: 'fontello-icons',
      cssClass: 'icon-arrows-ccw'
    },
    {
      id: 819,
      provider: 'fontello-icons',
      cssClass: 'icon-level-down-1'
    },
    {
      id: 820,
      provider: 'fontello-icons',
      cssClass: 'icon-level-up-1'
    },
    {
      id: 821,
      provider: 'fontello-icons',
      cssClass: 'icon-shuffle-1'
    },
    {
      id: 822,
      provider: 'fontello-icons',
      cssClass: 'icon-loop'
    },
    {
      id: 823,
      provider: 'fontello-icons',
      cssClass: 'icon-switch'
    },
    {
      id: 824,
      provider: 'fontello-icons',
      cssClass: 'icon-play-1'
    },
    {
      id: 825,
      provider: 'fontello-icons',
      cssClass: 'icon-stop-1'
    },
    {
      id: 826,
      provider: 'fontello-icons',
      cssClass: 'icon-pause-1'
    },
    {
      id: 827,
      provider: 'fontello-icons',
      cssClass: 'icon-record'
    },
    {
      id: 828,
      provider: 'fontello-icons',
      cssClass: 'icon-to-end-1'
    },
    {
      id: 829,
      provider: 'fontello-icons',
      cssClass: 'icon-to-start-1'
    },
    {
      id: 830,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-forward'
    },
    {
      id: 831,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-backward'
    },
    {
      id: 832,
      provider: 'fontello-icons',
      cssClass: 'icon-progress-0'
    },
    {
      id: 833,
      provider: 'fontello-icons',
      cssClass: 'icon-progress-1'
    },
    {
      id: 834,
      provider: 'fontello-icons',
      cssClass: 'icon-progress-2'
    },
    {
      id: 835,
      provider: 'fontello-icons',
      cssClass: 'icon-progress-3'
    },
    {
      id: 836,
      provider: 'fontello-icons',
      cssClass: 'icon-target-1'
    },
    {
      id: 837,
      provider: 'fontello-icons',
      cssClass: 'icon-palette'
    },
    {
      id: 838,
      provider: 'fontello-icons',
      cssClass: 'icon-list-1'
    },
    {
      id: 839,
      provider: 'fontello-icons',
      cssClass: 'icon-list-add'
    },
    {
      id: 840,
      provider: 'fontello-icons',
      cssClass: 'icon-signal-1'
    },
    {
      id: 841,
      provider: 'fontello-icons',
      cssClass: 'icon-trophy'
    },
    {
      id: 842,
      provider: 'fontello-icons',
      cssClass: 'icon-battery'
    },
    {
      id: 843,
      provider: 'fontello-icons',
      cssClass: 'icon-back-in-time'
    },
    {
      id: 844,
      provider: 'fontello-icons',
      cssClass: 'icon-monitor'
    },
    {
      id: 845,
      provider: 'fontello-icons',
      cssClass: 'icon-mobile-1'
    },
    {
      id: 846,
      provider: 'fontello-icons',
      cssClass: 'icon-network'
    },
    {
      id: 847,
      provider: 'fontello-icons',
      cssClass: 'icon-cd'
    },
    {
      id: 848,
      provider: 'fontello-icons',
      cssClass: 'icon-inbox-1'
    },
    {
      id: 849,
      provider: 'fontello-icons',
      cssClass: 'icon-install'
    },
    {
      id: 850,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-1'
    },
    {
      id: 851,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-1'
    },
    {
      id: 852,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-thunder'
    },
    {
      id: 853,
      provider: 'fontello-icons',
      cssClass: 'icon-flash-1'
    },
    {
      id: 854,
      provider: 'fontello-icons',
      cssClass: 'icon-moon-1'
    },
    {
      id: 855,
      provider: 'fontello-icons',
      cssClass: 'icon-flight-1'
    },
    {
      id: 856,
      provider: 'fontello-icons',
      cssClass: 'icon-paper-plane'
    },
    {
      id: 857,
      provider: 'fontello-icons',
      cssClass: 'icon-leaf-1'
    },
    {
      id: 858,
      provider: 'fontello-icons',
      cssClass: 'icon-lifebuoy'
    },
    {
      id: 859,
      provider: 'fontello-icons',
      cssClass: 'icon-mouse'
    },
    {
      id: 860,
      provider: 'fontello-icons',
      cssClass: 'icon-briefcase-1'
    },
    {
      id: 861,
      provider: 'fontello-icons',
      cssClass: 'icon-suitcase-1'
    },
    {
      id: 862,
      provider: 'fontello-icons',
      cssClass: 'icon-dot'
    },
    {
      id: 863,
      provider: 'fontello-icons',
      cssClass: 'icon-dot-2'
    },
    {
      id: 864,
      provider: 'fontello-icons',
      cssClass: 'icon-dot-3'
    },
    {
      id: 865,
      provider: 'fontello-icons',
      cssClass: 'icon-brush'
    },
    {
      id: 866,
      provider: 'fontello-icons',
      cssClass: 'icon-magnet-1'
    },
    {
      id: 867,
      provider: 'fontello-icons',
      cssClass: 'icon-infinity'
    },
    {
      id: 868,
      provider: 'fontello-icons',
      cssClass: 'icon-erase'
    },
    {
      id: 869,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-pie'
    },
    {
      id: 870,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-line'
    },
    {
      id: 871,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-bar-1'
    },
    {
      id: 872,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-area'
    },
    {
      id: 873,
      provider: 'fontello-icons',
      cssClass: 'icon-tape'
    },
    {
      id: 874,
      provider: 'fontello-icons',
      cssClass: 'icon-graduation-cap'
    },
    {
      id: 875,
      provider: 'fontello-icons',
      cssClass: 'icon-language'
    },
    {
      id: 876,
      provider: 'fontello-icons',
      cssClass: 'icon-ticket-1'
    },
    {
      id: 877,
      provider: 'fontello-icons',
      cssClass: 'icon-water'
    },
    {
      id: 878,
      provider: 'fontello-icons',
      cssClass: 'icon-droplet'
    },
    {
      id: 879,
      provider: 'fontello-icons',
      cssClass: 'icon-air'
    },
    {
      id: 880,
      provider: 'fontello-icons',
      cssClass: 'icon-credit-card-1'
    },
    {
      id: 881,
      provider: 'fontello-icons',
      cssClass: 'icon-floppy-1'
    },
    {
      id: 882,
      provider: 'fontello-icons',
      cssClass: 'icon-clipboard'
    },
    {
      id: 883,
      provider: 'fontello-icons',
      cssClass: 'icon-megaphone-1'
    },
    {
      id: 884,
      provider: 'fontello-icons',
      cssClass: 'icon-database'
    },
    {
      id: 885,
      provider: 'fontello-icons',
      cssClass: 'icon-drive'
    },
    {
      id: 886,
      provider: 'fontello-icons',
      cssClass: 'icon-bucket'
    },
    {
      id: 887,
      provider: 'fontello-icons',
      cssClass: 'icon-thermometer'
    },
    {
      id: 888,
      provider: 'fontello-icons',
      cssClass: 'icon-key-1'
    },
    {
      id: 889,
      provider: 'fontello-icons',
      cssClass: 'icon-flow-cascade'
    },
    {
      id: 890,
      provider: 'fontello-icons',
      cssClass: 'icon-flow-branch'
    },
    {
      id: 891,
      provider: 'fontello-icons',
      cssClass: 'icon-flow-tree'
    },
    {
      id: 892,
      provider: 'fontello-icons',
      cssClass: 'icon-flow-line'
    },
    {
      id: 893,
      provider: 'fontello-icons',
      cssClass: 'icon-flow-parallel'
    },
    {
      id: 894,
      provider: 'fontello-icons',
      cssClass: 'icon-rocket-1'
    },
    {
      id: 895,
      provider: 'fontello-icons',
      cssClass: 'icon-gauge-1'
    },
    {
      id: 896,
      provider: 'fontello-icons',
      cssClass: 'icon-traffic-cone'
    },
    {
      id: 897,
      provider: 'fontello-icons',
      cssClass: 'icon-cc'
    },
    {
      id: 898,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-by'
    },
    {
      id: 899,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-nc'
    },
    {
      id: 900,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-nc-eu'
    },
    {
      id: 901,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-nc-jp'
    },
    {
      id: 902,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-sa'
    },
    {
      id: 903,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-nd'
    },
    {
      id: 904,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-pd'
    },
    {
      id: 905,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-zero'
    },
    {
      id: 906,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-share'
    },
    {
      id: 907,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-remix'
    },
    {
      id: 908,
      provider: 'fontello-icons',
      cssClass: 'icon-github-1'
    },
    {
      id: 909,
      provider: 'fontello-icons',
      cssClass: 'icon-github-circled-1'
    },
    {
      id: 910,
      provider: 'fontello-icons',
      cssClass: 'icon-flickr-1'
    },
    {
      id: 911,
      provider: 'fontello-icons',
      cssClass: 'icon-flickr-circled'
    },
    {
      id: 912,
      provider: 'fontello-icons',
      cssClass: 'icon-vimeo'
    },
    {
      id: 913,
      provider: 'fontello-icons',
      cssClass: 'icon-vimeo-circled'
    },
    {
      id: 914,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-1'
    },
    {
      id: 915,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-circled'
    },
    {
      id: 916,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-1'
    },
    {
      id: 917,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-circled'
    },
    {
      id: 918,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-squared-1'
    },
    {
      id: 919,
      provider: 'fontello-icons',
      cssClass: 'icon-gplus-1'
    },
    {
      id: 920,
      provider: 'fontello-icons',
      cssClass: 'icon-gplus-circled'
    },
    {
      id: 921,
      provider: 'fontello-icons',
      cssClass: 'icon-pinterest'
    },
    {
      id: 922,
      provider: 'fontello-icons',
      cssClass: 'icon-pinterest-circled-1'
    },
    {
      id: 923,
      provider: 'fontello-icons',
      cssClass: 'icon-tumblr-1'
    },
    {
      id: 924,
      provider: 'fontello-icons',
      cssClass: 'icon-tumblr-circled'
    },
    {
      id: 925,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-1'
    },
    {
      id: 926,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-circled'
    },
    {
      id: 927,
      provider: 'fontello-icons',
      cssClass: 'icon-dribbble-1'
    },
    {
      id: 928,
      provider: 'fontello-icons',
      cssClass: 'icon-dribbble-circled'
    },
    {
      id: 929,
      provider: 'fontello-icons',
      cssClass: 'icon-stumbleupon'
    },
    {
      id: 930,
      provider: 'fontello-icons',
      cssClass: 'icon-stumbleupon-circled'
    },
    {
      id: 931,
      provider: 'fontello-icons',
      cssClass: 'icon-lastfm'
    },
    {
      id: 932,
      provider: 'fontello-icons',
      cssClass: 'icon-lastfm-circled'
    },
    {
      id: 933,
      provider: 'fontello-icons',
      cssClass: 'icon-rdio'
    },
    {
      id: 934,
      provider: 'fontello-icons',
      cssClass: 'icon-rdio-circled'
    },
    {
      id: 935,
      provider: 'fontello-icons',
      cssClass: 'icon-spotify'
    },
    {
      id: 936,
      provider: 'fontello-icons',
      cssClass: 'icon-spotify-circled'
    },
    {
      id: 937,
      provider: 'fontello-icons',
      cssClass: 'icon-qq'
    },
    {
      id: 938,
      provider: 'fontello-icons',
      cssClass: 'icon-instagram'
    },
    {
      id: 939,
      provider: 'fontello-icons',
      cssClass: 'icon-dropbox-1'
    },
    {
      id: 940,
      provider: 'fontello-icons',
      cssClass: 'icon-evernote'
    },
    {
      id: 941,
      provider: 'fontello-icons',
      cssClass: 'icon-flattr'
    },
    {
      id: 942,
      provider: 'fontello-icons',
      cssClass: 'icon-skype-1'
    },
    {
      id: 943,
      provider: 'fontello-icons',
      cssClass: 'icon-skype-circled'
    },
    {
      id: 944,
      provider: 'fontello-icons',
      cssClass: 'icon-renren-1'
    },
    {
      id: 945,
      provider: 'fontello-icons',
      cssClass: 'icon-sina-weibo'
    },
    {
      id: 946,
      provider: 'fontello-icons',
      cssClass: 'icon-paypal'
    },
    {
      id: 947,
      provider: 'fontello-icons',
      cssClass: 'icon-picasa'
    },
    {
      id: 948,
      provider: 'fontello-icons',
      cssClass: 'icon-soundcloud'
    },
    {
      id: 949,
      provider: 'fontello-icons',
      cssClass: 'icon-mixi'
    },
    {
      id: 950,
      provider: 'fontello-icons',
      cssClass: 'icon-behance'
    },
    {
      id: 951,
      provider: 'fontello-icons',
      cssClass: 'icon-google-circles'
    },
    {
      id: 952,
      provider: 'fontello-icons',
      cssClass: 'icon-vkontakte-1'
    },
    {
      id: 953,
      provider: 'fontello-icons',
      cssClass: 'icon-smashing'
    },
    {
      id: 954,
      provider: 'fontello-icons',
      cssClass: 'icon-sweden'
    },
    {
      id: 955,
      provider: 'fontello-icons',
      cssClass: 'icon-db-shape'
    },
    {
      id: 956,
      provider: 'fontello-icons',
      cssClass: 'icon-logo-db'
    },
    {
      id: 957,
      provider: 'fontello-icons',
      cssClass: 'icon-music-outline'
    },
    {
      id: 958,
      provider: 'fontello-icons',
      cssClass: 'icon-music-2'
    },
    {
      id: 959,
      provider: 'fontello-icons',
      cssClass: 'icon-search-outline'
    },
    {
      id: 960,
      provider: 'fontello-icons',
      cssClass: 'icon-search-2'
    },
    {
      id: 961,
      provider: 'fontello-icons',
      cssClass: 'icon-mail-2'
    },
    {
      id: 962,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-2'
    },
    {
      id: 963,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-filled'
    },
    {
      id: 964,
      provider: 'fontello-icons',
      cssClass: 'icon-star-2'
    },
    {
      id: 965,
      provider: 'fontello-icons',
      cssClass: 'icon-star-filled'
    },
    {
      id: 966,
      provider: 'fontello-icons',
      cssClass: 'icon-user-outline'
    },
    {
      id: 967,
      provider: 'fontello-icons',
      cssClass: 'icon-user-2'
    },
    {
      id: 968,
      provider: 'fontello-icons',
      cssClass: 'icon-users-outline'
    },
    {
      id: 969,
      provider: 'fontello-icons',
      cssClass: 'icon-users-2'
    },
    {
      id: 970,
      provider: 'fontello-icons',
      cssClass: 'icon-user-add-outline'
    },
    {
      id: 971,
      provider: 'fontello-icons',
      cssClass: 'icon-user-add-1'
    },
    {
      id: 972,
      provider: 'fontello-icons',
      cssClass: 'icon-user-delete-outline'
    },
    {
      id: 973,
      provider: 'fontello-icons',
      cssClass: 'icon-user-delete'
    },
    {
      id: 974,
      provider: 'fontello-icons',
      cssClass: 'icon-video-2'
    },
    {
      id: 975,
      provider: 'fontello-icons',
      cssClass: 'icon-videocam-outline'
    },
    {
      id: 976,
      provider: 'fontello-icons',
      cssClass: 'icon-videocam-1'
    },
    {
      id: 977,
      provider: 'fontello-icons',
      cssClass: 'icon-picture-outline'
    },
    {
      id: 978,
      provider: 'fontello-icons',
      cssClass: 'icon-picture-2'
    },
    {
      id: 979,
      provider: 'fontello-icons',
      cssClass: 'icon-camera-outline'
    },
    {
      id: 980,
      provider: 'fontello-icons',
      cssClass: 'icon-camera-2'
    },
    {
      id: 981,
      provider: 'fontello-icons',
      cssClass: 'icon-th-outline'
    },
    {
      id: 982,
      provider: 'fontello-icons',
      cssClass: 'icon-th-1'
    },
    {
      id: 983,
      provider: 'fontello-icons',
      cssClass: 'icon-th-large-outline'
    },
    {
      id: 984,
      provider: 'fontello-icons',
      cssClass: 'icon-th-large-1'
    },
    {
      id: 985,
      provider: 'fontello-icons',
      cssClass: 'icon-th-list-outline'
    },
    {
      id: 986,
      provider: 'fontello-icons',
      cssClass: 'icon-th-list-1'
    },
    {
      id: 987,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-outline'
    },
    {
      id: 988,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-1'
    },
    {
      id: 989,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-outline'
    },
    {
      id: 990,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-2'
    },
    {
      id: 991,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-alt'
    },
    {
      id: 992,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-alt-filled'
    },
    {
      id: 993,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circled-outline'
    },
    {
      id: 994,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circled-2'
    },
    {
      id: 995,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-outline'
    },
    {
      id: 996,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-2'
    },
    {
      id: 997,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-outline'
    },
    {
      id: 998,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-2'
    },
    {
      id: 999,
      provider: 'fontello-icons',
      cssClass: 'icon-divide-outline'
    },
    {
      id: 1000,
      provider: 'fontello-icons',
      cssClass: 'icon-divide'
    },
    {
      id: 1001,
      provider: 'fontello-icons',
      cssClass: 'icon-eq-outline'
    },
    {
      id: 1002,
      provider: 'fontello-icons',
      cssClass: 'icon-eq'
    },
    {
      id: 1003,
      provider: 'fontello-icons',
      cssClass: 'icon-info-outline'
    },
    {
      id: 1004,
      provider: 'fontello-icons',
      cssClass: 'icon-info-2'
    },
    {
      id: 1005,
      provider: 'fontello-icons',
      cssClass: 'icon-home-outline'
    },
    {
      id: 1006,
      provider: 'fontello-icons',
      cssClass: 'icon-home-2'
    },
    {
      id: 1007,
      provider: 'fontello-icons',
      cssClass: 'icon-link-outline'
    },
    {
      id: 1008,
      provider: 'fontello-icons',
      cssClass: 'icon-link-2'
    },
    {
      id: 1009,
      provider: 'fontello-icons',
      cssClass: 'icon-attach-outline'
    },
    {
      id: 1010,
      provider: 'fontello-icons',
      cssClass: 'icon-attach-2'
    },
    {
      id: 1011,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-2'
    },
    {
      id: 1012,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-filled'
    },
    {
      id: 1013,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-2'
    },
    {
      id: 1014,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-filled'
    },
    {
      id: 1015,
      provider: 'fontello-icons',
      cssClass: 'icon-pin-outline'
    },
    {
      id: 1016,
      provider: 'fontello-icons',
      cssClass: 'icon-pin-1'
    },
    {
      id: 1017,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-outline'
    },
    {
      id: 1018,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-2'
    },
    {
      id: 1019,
      provider: 'fontello-icons',
      cssClass: 'icon-tag-2'
    },
    {
      id: 1020,
      provider: 'fontello-icons',
      cssClass: 'icon-tags-1'
    },
    {
      id: 1021,
      provider: 'fontello-icons',
      cssClass: 'icon-bookmark-2'
    },
    {
      id: 1022,
      provider: 'fontello-icons',
      cssClass: 'icon-flag-2'
    },
    {
      id: 1023,
      provider: 'fontello-icons',
      cssClass: 'icon-flag-filled'
    },
    {
      id: 1024,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-up-2'
    },
    {
      id: 1025,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-down-2'
    },
    {
      id: 1026,
      provider: 'fontello-icons',
      cssClass: 'icon-download-outline'
    },
    {
      id: 1027,
      provider: 'fontello-icons',
      cssClass: 'icon-download-2'
    },
    {
      id: 1028,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-outline'
    },
    {
      id: 1029,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-2'
    },
    {
      id: 1030,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-cloud-outline'
    },
    {
      id: 1031,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-cloud-2'
    },
    {
      id: 1032,
      provider: 'fontello-icons',
      cssClass: 'icon-reply-outline'
    },
    {
      id: 1033,
      provider: 'fontello-icons',
      cssClass: 'icon-reply-2'
    },
    {
      id: 1034,
      provider: 'fontello-icons',
      cssClass: 'icon-forward-outline'
    },
    {
      id: 1035,
      provider: 'fontello-icons',
      cssClass: 'icon-forward-2'
    },
    {
      id: 1036,
      provider: 'fontello-icons',
      cssClass: 'icon-code-outline'
    },
    {
      id: 1037,
      provider: 'fontello-icons',
      cssClass: 'icon-code-2'
    },
    {
      id: 1038,
      provider: 'fontello-icons',
      cssClass: 'icon-export-outline'
    },
    {
      id: 1039,
      provider: 'fontello-icons',
      cssClass: 'icon-export-2'
    },
    {
      id: 1040,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-2'
    },
    {
      id: 1041,
      provider: 'fontello-icons',
      cssClass: 'icon-pen'
    },
    {
      id: 1042,
      provider: 'fontello-icons',
      cssClass: 'icon-feather-1'
    },
    {
      id: 1043,
      provider: 'fontello-icons',
      cssClass: 'icon-edit-1'
    },
    {
      id: 1044,
      provider: 'fontello-icons',
      cssClass: 'icon-print-2'
    },
    {
      id: 1045,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-2'
    },
    {
      id: 1046,
      provider: 'fontello-icons',
      cssClass: 'icon-chat-2'
    },
    {
      id: 1047,
      provider: 'fontello-icons',
      cssClass: 'icon-chat-alt'
    },
    {
      id: 1048,
      provider: 'fontello-icons',
      cssClass: 'icon-bell-2'
    },
    {
      id: 1049,
      provider: 'fontello-icons',
      cssClass: 'icon-attention-2'
    },
    {
      id: 1050,
      provider: 'fontello-icons',
      cssClass: 'icon-attention-filled'
    },
    {
      id: 1051,
      provider: 'fontello-icons',
      cssClass: 'icon-warning-empty'
    },
    {
      id: 1052,
      provider: 'fontello-icons',
      cssClass: 'icon-warning'
    },
    {
      id: 1053,
      provider: 'fontello-icons',
      cssClass: 'icon-contacts'
    },
    {
      id: 1054,
      provider: 'fontello-icons',
      cssClass: 'icon-vcard-1'
    },
    {
      id: 1055,
      provider: 'fontello-icons',
      cssClass: 'icon-address-1'
    },
    {
      id: 1056,
      provider: 'fontello-icons',
      cssClass: 'icon-location-outline'
    },
    {
      id: 1057,
      provider: 'fontello-icons',
      cssClass: 'icon-location-2'
    },
    {
      id: 1058,
      provider: 'fontello-icons',
      cssClass: 'icon-map-1'
    },
    {
      id: 1059,
      provider: 'fontello-icons',
      cssClass: 'icon-direction-outline'
    },
    {
      id: 1060,
      provider: 'fontello-icons',
      cssClass: 'icon-direction-2'
    },
    {
      id: 1061,
      provider: 'fontello-icons',
      cssClass: 'icon-compass-2'
    },
    {
      id: 1062,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-2'
    },
    {
      id: 1063,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-2'
    },
    {
      id: 1064,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-text-2'
    },
    {
      id: 1065,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-add'
    },
    {
      id: 1066,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-remove'
    },
    {
      id: 1067,
      provider: 'fontello-icons',
      cssClass: 'icon-news'
    },
    {
      id: 1068,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-2'
    },
    {
      id: 1069,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-add'
    },
    {
      id: 1070,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-delete'
    },
    {
      id: 1071,
      provider: 'fontello-icons',
      cssClass: 'icon-archive-1'
    },
    {
      id: 1072,
      provider: 'fontello-icons',
      cssClass: 'icon-box-2'
    },
    {
      id: 1073,
      provider: 'fontello-icons',
      cssClass: 'icon-rss-outline'
    },
    {
      id: 1074,
      provider: 'fontello-icons',
      cssClass: 'icon-rss-2'
    },
    {
      id: 1075,
      provider: 'fontello-icons',
      cssClass: 'icon-phone-outline'
    },
    {
      id: 1076,
      provider: 'fontello-icons',
      cssClass: 'icon-phone-2'
    },
    {
      id: 1077,
      provider: 'fontello-icons',
      cssClass: 'icon-menu-outline'
    },
    {
      id: 1078,
      provider: 'fontello-icons',
      cssClass: 'icon-menu-2'
    },
    {
      id: 1079,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-outline'
    },
    {
      id: 1080,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-2'
    },
    {
      id: 1081,
      provider: 'fontello-icons',
      cssClass: 'icon-wrench-outline'
    },
    {
      id: 1082,
      provider: 'fontello-icons',
      cssClass: 'icon-wrench-1'
    },
    {
      id: 1083,
      provider: 'fontello-icons',
      cssClass: 'icon-basket-2'
    },
    {
      id: 1084,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-outlilne'
    },
    {
      id: 1085,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-2'
    },
    {
      id: 1086,
      provider: 'fontello-icons',
      cssClass: 'icon-mic-outline'
    },
    {
      id: 1087,
      provider: 'fontello-icons',
      cssClass: 'icon-mic-2'
    },
    {
      id: 1088,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-off-1'
    },
    {
      id: 1089,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-low'
    },
    {
      id: 1090,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-middle'
    },
    {
      id: 1091,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-high'
    },
    {
      id: 1092,
      provider: 'fontello-icons',
      cssClass: 'icon-headphones-1'
    },
    {
      id: 1093,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-2'
    },
    {
      id: 1094,
      provider: 'fontello-icons',
      cssClass: 'icon-wristwatch'
    },
    {
      id: 1095,
      provider: 'fontello-icons',
      cssClass: 'icon-stopwatch'
    },
    {
      id: 1096,
      provider: 'fontello-icons',
      cssClass: 'icon-lightbulb-1'
    },
    {
      id: 1097,
      provider: 'fontello-icons',
      cssClass: 'icon-block-outline'
    },
    {
      id: 1098,
      provider: 'fontello-icons',
      cssClass: 'icon-block-2'
    },
    {
      id: 1099,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-outline'
    },
    {
      id: 1100,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-2'
    },
    {
      id: 1101,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-normal-outline'
    },
    {
      id: 1102,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-normal'
    },
    {
      id: 1103,
      provider: 'fontello-icons',
      cssClass: 'icon-move-outline'
    },
    {
      id: 1104,
      provider: 'fontello-icons',
      cssClass: 'icon-move-1'
    },
    {
      id: 1105,
      provider: 'fontello-icons',
      cssClass: 'icon-popup-1'
    },
    {
      id: 1106,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-in-outline'
    },
    {
      id: 1107,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-in-1'
    },
    {
      id: 1108,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-out-outline'
    },
    {
      id: 1109,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-out-1'
    },
    {
      id: 1110,
      provider: 'fontello-icons',
      cssClass: 'icon-popup-2'
    },
    {
      id: 1111,
      provider: 'fontello-icons',
      cssClass: 'icon-left-open-outline'
    },
    {
      id: 1112,
      provider: 'fontello-icons',
      cssClass: 'icon-left-open-2'
    },
    {
      id: 1113,
      provider: 'fontello-icons',
      cssClass: 'icon-right-open-outline'
    },
    {
      id: 1114,
      provider: 'fontello-icons',
      cssClass: 'icon-right-open-2'
    },
    {
      id: 1115,
      provider: 'fontello-icons',
      cssClass: 'icon-down-2'
    },
    {
      id: 1116,
      provider: 'fontello-icons',
      cssClass: 'icon-left-2'
    },
    {
      id: 1117,
      provider: 'fontello-icons',
      cssClass: 'icon-right-2'
    },
    {
      id: 1118,
      provider: 'fontello-icons',
      cssClass: 'icon-up-2'
    },
    {
      id: 1119,
      provider: 'fontello-icons',
      cssClass: 'icon-down-outline'
    },
    {
      id: 1120,
      provider: 'fontello-icons',
      cssClass: 'icon-left-outline'
    },
    {
      id: 1121,
      provider: 'fontello-icons',
      cssClass: 'icon-right-outline'
    },
    {
      id: 1122,
      provider: 'fontello-icons',
      cssClass: 'icon-up-outline'
    },
    {
      id: 1123,
      provider: 'fontello-icons',
      cssClass: 'icon-down-small'
    },
    {
      id: 1124,
      provider: 'fontello-icons',
      cssClass: 'icon-left-small'
    },
    {
      id: 1125,
      provider: 'fontello-icons',
      cssClass: 'icon-right-small'
    },
    {
      id: 1126,
      provider: 'fontello-icons',
      cssClass: 'icon-up-small'
    },
    {
      id: 1127,
      provider: 'fontello-icons',
      cssClass: 'icon-cw-outline'
    },
    {
      id: 1128,
      provider: 'fontello-icons',
      cssClass: 'icon-cw-2'
    },
    {
      id: 1129,
      provider: 'fontello-icons',
      cssClass: 'icon-arrows-cw-outline'
    },
    {
      id: 1130,
      provider: 'fontello-icons',
      cssClass: 'icon-arrows-cw-1'
    },
    {
      id: 1131,
      provider: 'fontello-icons',
      cssClass: 'icon-loop-outline'
    },
    {
      id: 1132,
      provider: 'fontello-icons',
      cssClass: 'icon-loop-1'
    },
    {
      id: 1133,
      provider: 'fontello-icons',
      cssClass: 'icon-loop-alt-outline'
    },
    {
      id: 1134,
      provider: 'fontello-icons',
      cssClass: 'icon-loop-alt'
    },
    {
      id: 1135,
      provider: 'fontello-icons',
      cssClass: 'icon-shuffle-2'
    },
    {
      id: 1136,
      provider: 'fontello-icons',
      cssClass: 'icon-play-outline'
    },
    {
      id: 1137,
      provider: 'fontello-icons',
      cssClass: 'icon-play-2'
    },
    {
      id: 1138,
      provider: 'fontello-icons',
      cssClass: 'icon-stop-outline'
    },
    {
      id: 1139,
      provider: 'fontello-icons',
      cssClass: 'icon-stop-2'
    },
    {
      id: 1140,
      provider: 'fontello-icons',
      cssClass: 'icon-pause-outline'
    },
    {
      id: 1141,
      provider: 'fontello-icons',
      cssClass: 'icon-pause-2'
    },
    {
      id: 1142,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-fw-outline'
    },
    {
      id: 1143,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-fw-1'
    },
    {
      id: 1144,
      provider: 'fontello-icons',
      cssClass: 'icon-rewind-outline'
    },
    {
      id: 1145,
      provider: 'fontello-icons',
      cssClass: 'icon-rewind'
    },
    {
      id: 1146,
      provider: 'fontello-icons',
      cssClass: 'icon-record-outline'
    },
    {
      id: 1147,
      provider: 'fontello-icons',
      cssClass: 'icon-record-1'
    },
    {
      id: 1148,
      provider: 'fontello-icons',
      cssClass: 'icon-eject-outline'
    },
    {
      id: 1149,
      provider: 'fontello-icons',
      cssClass: 'icon-eject-1'
    },
    {
      id: 1150,
      provider: 'fontello-icons',
      cssClass: 'icon-eject-alt-outline'
    },
    {
      id: 1151,
      provider: 'fontello-icons',
      cssClass: 'icon-eject-alt'
    },
    {
      id: 1152,
      provider: 'fontello-icons',
      cssClass: 'icon-bat1'
    },
    {
      id: 1153,
      provider: 'fontello-icons',
      cssClass: 'icon-bat2'
    },
    {
      id: 1154,
      provider: 'fontello-icons',
      cssClass: 'icon-bat3'
    },
    {
      id: 1155,
      provider: 'fontello-icons',
      cssClass: 'icon-bat4'
    },
    {
      id: 1156,
      provider: 'fontello-icons',
      cssClass: 'icon-bat-charge'
    },
    {
      id: 1157,
      provider: 'fontello-icons',
      cssClass: 'icon-plug'
    },
    {
      id: 1158,
      provider: 'fontello-icons',
      cssClass: 'icon-target-outline'
    },
    {
      id: 1159,
      provider: 'fontello-icons',
      cssClass: 'icon-target-2'
    },
    {
      id: 1160,
      provider: 'fontello-icons',
      cssClass: 'icon-wifi-outline'
    },
    {
      id: 1161,
      provider: 'fontello-icons',
      cssClass: 'icon-wifi'
    },
    {
      id: 1162,
      provider: 'fontello-icons',
      cssClass: 'icon-desktop-1'
    },
    {
      id: 1163,
      provider: 'fontello-icons',
      cssClass: 'icon-laptop-1'
    },
    {
      id: 1164,
      provider: 'fontello-icons',
      cssClass: 'icon-tablet-1'
    },
    {
      id: 1165,
      provider: 'fontello-icons',
      cssClass: 'icon-mobile-2'
    },
    {
      id: 1166,
      provider: 'fontello-icons',
      cssClass: 'icon-contrast'
    },
    {
      id: 1167,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-outline'
    },
    {
      id: 1168,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-2'
    },
    {
      id: 1169,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-alt-outline'
    },
    {
      id: 1170,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-alt'
    },
    {
      id: 1171,
      provider: 'fontello-icons',
      cssClass: 'icon-sun-1'
    },
    {
      id: 1172,
      provider: 'fontello-icons',
      cssClass: 'icon-sun-filled'
    },
    {
      id: 1173,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-2'
    },
    {
      id: 1174,
      provider: 'fontello-icons',
      cssClass: 'icon-flash-outline'
    },
    {
      id: 1175,
      provider: 'fontello-icons',
      cssClass: 'icon-flash-2'
    },
    {
      id: 1176,
      provider: 'fontello-icons',
      cssClass: 'icon-moon-2'
    },
    {
      id: 1177,
      provider: 'fontello-icons',
      cssClass: 'icon-waves-outline'
    },
    {
      id: 1178,
      provider: 'fontello-icons',
      cssClass: 'icon-waves'
    },
    {
      id: 1179,
      provider: 'fontello-icons',
      cssClass: 'icon-rain'
    },
    {
      id: 1180,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-sun'
    },
    {
      id: 1181,
      provider: 'fontello-icons',
      cssClass: 'icon-drizzle'
    },
    {
      id: 1182,
      provider: 'fontello-icons',
      cssClass: 'icon-snow'
    },
    {
      id: 1183,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-flash'
    },
    {
      id: 1184,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-wind'
    },
    {
      id: 1185,
      provider: 'fontello-icons',
      cssClass: 'icon-wind'
    },
    {
      id: 1186,
      provider: 'fontello-icons',
      cssClass: 'icon-plane-outline'
    },
    {
      id: 1187,
      provider: 'fontello-icons',
      cssClass: 'icon-plane'
    },
    {
      id: 1188,
      provider: 'fontello-icons',
      cssClass: 'icon-leaf-2'
    },
    {
      id: 1189,
      provider: 'fontello-icons',
      cssClass: 'icon-lifebuoy-1'
    },
    {
      id: 1190,
      provider: 'fontello-icons',
      cssClass: 'icon-briefcase-2'
    },
    {
      id: 1191,
      provider: 'fontello-icons',
      cssClass: 'icon-brush-1'
    },
    {
      id: 1192,
      provider: 'fontello-icons',
      cssClass: 'icon-pipette'
    },
    {
      id: 1193,
      provider: 'fontello-icons',
      cssClass: 'icon-power-outline'
    },
    {
      id: 1194,
      provider: 'fontello-icons',
      cssClass: 'icon-power'
    },
    {
      id: 1195,
      provider: 'fontello-icons',
      cssClass: 'icon-check-outline'
    },
    {
      id: 1196,
      provider: 'fontello-icons',
      cssClass: 'icon-check-2'
    },
    {
      id: 1197,
      provider: 'fontello-icons',
      cssClass: 'icon-gift-1'
    },
    {
      id: 1198,
      provider: 'fontello-icons',
      cssClass: 'icon-temperatire'
    },
    {
      id: 1199,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-outline'
    },
    {
      id: 1200,
      provider: 'fontello-icons',
      cssClass: 'icon-chart'
    },
    {
      id: 1201,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-alt-outline'
    },
    {
      id: 1202,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-alt'
    },
    {
      id: 1203,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-bar-outline'
    },
    {
      id: 1204,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-bar-2'
    },
    {
      id: 1205,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-pie-outline'
    },
    {
      id: 1206,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-pie-1'
    },
    {
      id: 1207,
      provider: 'fontello-icons',
      cssClass: 'icon-ticket-2'
    },
    {
      id: 1208,
      provider: 'fontello-icons',
      cssClass: 'icon-credit-card-2'
    },
    {
      id: 1209,
      provider: 'fontello-icons',
      cssClass: 'icon-clipboard-1'
    },
    {
      id: 1210,
      provider: 'fontello-icons',
      cssClass: 'icon-database-1'
    },
    {
      id: 1211,
      provider: 'fontello-icons',
      cssClass: 'icon-key-outline'
    },
    {
      id: 1212,
      provider: 'fontello-icons',
      cssClass: 'icon-key-2'
    },
    {
      id: 1213,
      provider: 'fontello-icons',
      cssClass: 'icon-flow-split'
    },
    {
      id: 1214,
      provider: 'fontello-icons',
      cssClass: 'icon-flow-merge'
    },
    {
      id: 1215,
      provider: 'fontello-icons',
      cssClass: 'icon-flow-parallel-1'
    },
    {
      id: 1216,
      provider: 'fontello-icons',
      cssClass: 'icon-flow-cross'
    },
    {
      id: 1217,
      provider: 'fontello-icons',
      cssClass: 'icon-certificate-outline'
    },
    {
      id: 1218,
      provider: 'fontello-icons',
      cssClass: 'icon-certificate-1'
    },
    {
      id: 1219,
      provider: 'fontello-icons',
      cssClass: 'icon-scissors-outline'
    },
    {
      id: 1220,
      provider: 'fontello-icons',
      cssClass: 'icon-scissors-1'
    },
    {
      id: 1221,
      provider: 'fontello-icons',
      cssClass: 'icon-flask'
    },
    {
      id: 1222,
      provider: 'fontello-icons',
      cssClass: 'icon-wine'
    },
    {
      id: 1223,
      provider: 'fontello-icons',
      cssClass: 'icon-coffee-1'
    },
    {
      id: 1224,
      provider: 'fontello-icons',
      cssClass: 'icon-beer-1'
    },
    {
      id: 1225,
      provider: 'fontello-icons',
      cssClass: 'icon-anchor-outline'
    },
    {
      id: 1226,
      provider: 'fontello-icons',
      cssClass: 'icon-anchor-1'
    },
    {
      id: 1227,
      provider: 'fontello-icons',
      cssClass: 'icon-puzzle-outline'
    },
    {
      id: 1228,
      provider: 'fontello-icons',
      cssClass: 'icon-puzzle-1'
    },
    {
      id: 1229,
      provider: 'fontello-icons',
      cssClass: 'icon-tree'
    },
    {
      id: 1230,
      provider: 'fontello-icons',
      cssClass: 'icon-calculator'
    },
    {
      id: 1231,
      provider: 'fontello-icons',
      cssClass: 'icon-infinity-outline'
    },
    {
      id: 1232,
      provider: 'fontello-icons',
      cssClass: 'icon-infinity-1'
    },
    {
      id: 1233,
      provider: 'fontello-icons',
      cssClass: 'icon-pi-outline'
    },
    {
      id: 1234,
      provider: 'fontello-icons',
      cssClass: 'icon-pi'
    },
    {
      id: 1235,
      provider: 'fontello-icons',
      cssClass: 'icon-at'
    },
    {
      id: 1236,
      provider: 'fontello-icons',
      cssClass: 'icon-at-circled'
    },
    {
      id: 1237,
      provider: 'fontello-icons',
      cssClass: 'icon-looped-square-outline'
    },
    {
      id: 1238,
      provider: 'fontello-icons',
      cssClass: 'icon-looped-square-interest'
    },
    {
      id: 1239,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-alphabet-outline'
    },
    {
      id: 1240,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-alphabet'
    },
    {
      id: 1241,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-numeric-outline'
    },
    {
      id: 1242,
      provider: 'fontello-icons',
      cssClass: 'icon-sort-numeric'
    },
    {
      id: 1243,
      provider: 'fontello-icons',
      cssClass: 'icon-dribbble-circled-1'
    },
    {
      id: 1244,
      provider: 'fontello-icons',
      cssClass: 'icon-dribbble-2'
    },
    {
      id: 1245,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-circled-1'
    },
    {
      id: 1246,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-2'
    },
    {
      id: 1247,
      provider: 'fontello-icons',
      cssClass: 'icon-flickr-circled-1'
    },
    {
      id: 1248,
      provider: 'fontello-icons',
      cssClass: 'icon-flickr-2'
    },
    {
      id: 1249,
      provider: 'fontello-icons',
      cssClass: 'icon-github-circled-2'
    },
    {
      id: 1250,
      provider: 'fontello-icons',
      cssClass: 'icon-github-2'
    },
    {
      id: 1251,
      provider: 'fontello-icons',
      cssClass: 'icon-lastfm-circled-1'
    },
    {
      id: 1252,
      provider: 'fontello-icons',
      cssClass: 'icon-lastfm-1'
    },
    {
      id: 1253,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-circled-1'
    },
    {
      id: 1254,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-2'
    },
    {
      id: 1255,
      provider: 'fontello-icons',
      cssClass: 'icon-pinterest-circled-2'
    },
    {
      id: 1256,
      provider: 'fontello-icons',
      cssClass: 'icon-pinterest-1'
    },
    {
      id: 1257,
      provider: 'fontello-icons',
      cssClass: 'icon-skype-outline'
    },
    {
      id: 1258,
      provider: 'fontello-icons',
      cssClass: 'icon-skype-2'
    },
    {
      id: 1259,
      provider: 'fontello-icons',
      cssClass: 'icon-tumbler-circled'
    },
    {
      id: 1260,
      provider: 'fontello-icons',
      cssClass: 'icon-tumbler'
    },
    {
      id: 1261,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-circled-1'
    },
    {
      id: 1262,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-2'
    },
    {
      id: 1263,
      provider: 'fontello-icons',
      cssClass: 'icon-vimeo-circled-1'
    },
    {
      id: 1264,
      provider: 'fontello-icons',
      cssClass: 'icon-vimeo-1'
    },
    {
      id: 1265,
      provider: 'fontello-icons',
      cssClass: 'icon-search-3'
    },
    {
      id: 1266,
      provider: 'fontello-icons',
      cssClass: 'icon-mail-3'
    },
    {
      id: 1267,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-3'
    },
    {
      id: 1268,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-empty-2'
    },
    {
      id: 1269,
      provider: 'fontello-icons',
      cssClass: 'icon-star-3'
    },
    {
      id: 1270,
      provider: 'fontello-icons',
      cssClass: 'icon-user-3'
    },
    {
      id: 1271,
      provider: 'fontello-icons',
      cssClass: 'icon-video-3'
    },
    {
      id: 1272,
      provider: 'fontello-icons',
      cssClass: 'icon-picture-3'
    },
    {
      id: 1273,
      provider: 'fontello-icons',
      cssClass: 'icon-camera-3'
    },
    {
      id: 1274,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-2'
    },
    {
      id: 1275,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-circle'
    },
    {
      id: 1276,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-3'
    },
    {
      id: 1277,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circle'
    },
    {
      id: 1278,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-3'
    },
    {
      id: 1279,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-circle'
    },
    {
      id: 1280,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-3'
    },
    {
      id: 1281,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-circle'
    },
    {
      id: 1282,
      provider: 'fontello-icons',
      cssClass: 'icon-help-2'
    },
    {
      id: 1283,
      provider: 'fontello-icons',
      cssClass: 'icon-info-3'
    },
    {
      id: 1284,
      provider: 'fontello-icons',
      cssClass: 'icon-home-3'
    },
    {
      id: 1285,
      provider: 'fontello-icons',
      cssClass: 'icon-link-3'
    },
    {
      id: 1286,
      provider: 'fontello-icons',
      cssClass: 'icon-attach-3'
    },
    {
      id: 1287,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-3'
    },
    {
      id: 1288,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-empty'
    },
    {
      id: 1289,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-3'
    },
    {
      id: 1290,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-empty'
    },
    {
      id: 1291,
      provider: 'fontello-icons',
      cssClass: 'icon-pin-2'
    },
    {
      id: 1292,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-3'
    },
    {
      id: 1293,
      provider: 'fontello-icons',
      cssClass: 'icon-tag-3'
    },
    {
      id: 1294,
      provider: 'fontello-icons',
      cssClass: 'icon-tag-empty'
    },
    {
      id: 1295,
      provider: 'fontello-icons',
      cssClass: 'icon-download-3'
    },
    {
      id: 1296,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-3'
    },
    {
      id: 1297,
      provider: 'fontello-icons',
      cssClass: 'icon-download-cloud-1'
    },
    {
      id: 1298,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-cloud-3'
    },
    {
      id: 1299,
      provider: 'fontello-icons',
      cssClass: 'icon-quote-left-1'
    },
    {
      id: 1300,
      provider: 'fontello-icons',
      cssClass: 'icon-quote-right-1'
    },
    {
      id: 1301,
      provider: 'fontello-icons',
      cssClass: 'icon-quote-left-alt'
    },
    {
      id: 1302,
      provider: 'fontello-icons',
      cssClass: 'icon-quote-right-alt'
    },
    {
      id: 1303,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-3'
    },
    {
      id: 1304,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-neg'
    },
    {
      id: 1305,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-alt'
    },
    {
      id: 1306,
      provider: 'fontello-icons',
      cssClass: 'icon-undo'
    },
    {
      id: 1307,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-3'
    },
    {
      id: 1308,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-inv'
    },
    {
      id: 1309,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-alt'
    },
    {
      id: 1310,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-inv-alt'
    },
    {
      id: 1311,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-alt2'
    },
    {
      id: 1312,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-inv-alt2'
    },
    {
      id: 1313,
      provider: 'fontello-icons',
      cssClass: 'icon-chat-3'
    },
    {
      id: 1314,
      provider: 'fontello-icons',
      cssClass: 'icon-chat-inv'
    },
    {
      id: 1315,
      provider: 'fontello-icons',
      cssClass: 'icon-location-3'
    },
    {
      id: 1316,
      provider: 'fontello-icons',
      cssClass: 'icon-location-inv'
    },
    {
      id: 1317,
      provider: 'fontello-icons',
      cssClass: 'icon-location-alt'
    },
    {
      id: 1318,
      provider: 'fontello-icons',
      cssClass: 'icon-compass-3'
    },
    {
      id: 1319,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-3'
    },
    {
      id: 1320,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-empty'
    },
    {
      id: 1321,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-3'
    },
    {
      id: 1322,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-inv-1'
    },
    {
      id: 1323,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-alt'
    },
    {
      id: 1324,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-inv-alt'
    },
    {
      id: 1325,
      provider: 'fontello-icons',
      cssClass: 'icon-article'
    },
    {
      id: 1326,
      provider: 'fontello-icons',
      cssClass: 'icon-article-alt'
    },
    {
      id: 1327,
      provider: 'fontello-icons',
      cssClass: 'icon-book-open-1'
    },
    {
      id: 1328,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-3'
    },
    {
      id: 1329,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-empty-1'
    },
    {
      id: 1330,
      provider: 'fontello-icons',
      cssClass: 'icon-box-3'
    },
    {
      id: 1331,
      provider: 'fontello-icons',
      cssClass: 'icon-rss-3'
    },
    {
      id: 1332,
      provider: 'fontello-icons',
      cssClass: 'icon-rss-alt'
    },
    {
      id: 1333,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-3'
    },
    {
      id: 1334,
      provider: 'fontello-icons',
      cssClass: 'icon-wrench-2'
    },
    {
      id: 1335,
      provider: 'fontello-icons',
      cssClass: 'icon-share-1'
    },
    {
      id: 1336,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-3'
    },
    {
      id: 1337,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-inv'
    },
    {
      id: 1338,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-alt'
    },
    {
      id: 1339,
      provider: 'fontello-icons',
      cssClass: 'icon-mic-3'
    },
    {
      id: 1340,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-off-2'
    },
    {
      id: 1341,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-up-1'
    },
    {
      id: 1342,
      provider: 'fontello-icons',
      cssClass: 'icon-headphones-2'
    },
    {
      id: 1343,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-3'
    },
    {
      id: 1344,
      provider: 'fontello-icons',
      cssClass: 'icon-lamp-1'
    },
    {
      id: 1345,
      provider: 'fontello-icons',
      cssClass: 'icon-block-3'
    },
    {
      id: 1346,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-3'
    },
    {
      id: 1347,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-alt-1'
    },
    {
      id: 1348,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-small-2'
    },
    {
      id: 1349,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-small-alt'
    },
    {
      id: 1350,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-vertical-1'
    },
    {
      id: 1351,
      provider: 'fontello-icons',
      cssClass: 'icon-spin4'
    },
    {
      id: 1352,
      provider: 'fontello-icons',
      cssClass: 'icon-move-2'
    },
    {
      id: 1353,
      provider: 'fontello-icons',
      cssClass: 'icon-popup-3'
    },
    {
      id: 1354,
      provider: 'fontello-icons',
      cssClass: 'icon-down-3'
    },
    {
      id: 1355,
      provider: 'fontello-icons',
      cssClass: 'icon-left-3'
    },
    {
      id: 1356,
      provider: 'fontello-icons',
      cssClass: 'icon-right-3'
    },
    {
      id: 1357,
      provider: 'fontello-icons',
      cssClass: 'icon-up-3'
    },
    {
      id: 1358,
      provider: 'fontello-icons',
      cssClass: 'icon-down-circle'
    },
    {
      id: 1359,
      provider: 'fontello-icons',
      cssClass: 'icon-left-circle'
    },
    {
      id: 1360,
      provider: 'fontello-icons',
      cssClass: 'icon-right-circle'
    },
    {
      id: 1361,
      provider: 'fontello-icons',
      cssClass: 'icon-up-circle'
    },
    {
      id: 1362,
      provider: 'fontello-icons',
      cssClass: 'icon-cw-3'
    },
    {
      id: 1363,
      provider: 'fontello-icons',
      cssClass: 'icon-loop-2'
    },
    {
      id: 1364,
      provider: 'fontello-icons',
      cssClass: 'icon-loop-alt-1'
    },
    {
      id: 1365,
      provider: 'fontello-icons',
      cssClass: 'icon-exchange-1'
    },
    {
      id: 1366,
      provider: 'fontello-icons',
      cssClass: 'icon-split'
    },
    {
      id: 1367,
      provider: 'fontello-icons',
      cssClass: 'icon-arrow-curved'
    },
    {
      id: 1368,
      provider: 'fontello-icons',
      cssClass: 'icon-play-3'
    },
    {
      id: 1369,
      provider: 'fontello-icons',
      cssClass: 'icon-play-circle2'
    },
    {
      id: 1370,
      provider: 'fontello-icons',
      cssClass: 'icon-stop-3'
    },
    {
      id: 1371,
      provider: 'fontello-icons',
      cssClass: 'icon-pause-3'
    },
    {
      id: 1372,
      provider: 'fontello-icons',
      cssClass: 'icon-to-start-2'
    },
    {
      id: 1373,
      provider: 'fontello-icons',
      cssClass: 'icon-to-end-2'
    },
    {
      id: 1374,
      provider: 'fontello-icons',
      cssClass: 'icon-eject-2'
    },
    {
      id: 1375,
      provider: 'fontello-icons',
      cssClass: 'icon-target-3'
    },
    {
      id: 1376,
      provider: 'fontello-icons',
      cssClass: 'icon-signal-2'
    },
    {
      id: 1377,
      provider: 'fontello-icons',
      cssClass: 'icon-award-1'
    },
    {
      id: 1378,
      provider: 'fontello-icons',
      cssClass: 'icon-award-empty'
    },
    {
      id: 1379,
      provider: 'fontello-icons',
      cssClass: 'icon-list-2'
    },
    {
      id: 1380,
      provider: 'fontello-icons',
      cssClass: 'icon-list-nested'
    },
    {
      id: 1381,
      provider: 'fontello-icons',
      cssClass: 'icon-bat-empty'
    },
    {
      id: 1382,
      provider: 'fontello-icons',
      cssClass: 'icon-bat-half'
    },
    {
      id: 1383,
      provider: 'fontello-icons',
      cssClass: 'icon-bat-full'
    },
    {
      id: 1384,
      provider: 'fontello-icons',
      cssClass: 'icon-bat-charge-1'
    },
    {
      id: 1385,
      provider: 'fontello-icons',
      cssClass: 'icon-mobile-3'
    },
    {
      id: 1386,
      provider: 'fontello-icons',
      cssClass: 'icon-cd-1'
    },
    {
      id: 1387,
      provider: 'fontello-icons',
      cssClass: 'icon-equalizer'
    },
    {
      id: 1388,
      provider: 'fontello-icons',
      cssClass: 'icon-cursor'
    },
    {
      id: 1389,
      provider: 'fontello-icons',
      cssClass: 'icon-aperture'
    },
    {
      id: 1390,
      provider: 'fontello-icons',
      cssClass: 'icon-aperture-alt'
    },
    {
      id: 1391,
      provider: 'fontello-icons',
      cssClass: 'icon-steering-wheel'
    },
    {
      id: 1392,
      provider: 'fontello-icons',
      cssClass: 'icon-book-2'
    },
    {
      id: 1393,
      provider: 'fontello-icons',
      cssClass: 'icon-book-alt'
    },
    {
      id: 1394,
      provider: 'fontello-icons',
      cssClass: 'icon-brush-2'
    },
    {
      id: 1395,
      provider: 'fontello-icons',
      cssClass: 'icon-brush-alt'
    },
    {
      id: 1396,
      provider: 'fontello-icons',
      cssClass: 'icon-eyedropper'
    },
    {
      id: 1397,
      provider: 'fontello-icons',
      cssClass: 'icon-layers'
    },
    {
      id: 1398,
      provider: 'fontello-icons',
      cssClass: 'icon-layers-alt'
    },
    {
      id: 1399,
      provider: 'fontello-icons',
      cssClass: 'icon-sun-2'
    },
    {
      id: 1400,
      provider: 'fontello-icons',
      cssClass: 'icon-sun-inv'
    },
    {
      id: 1401,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-3'
    },
    {
      id: 1402,
      provider: 'fontello-icons',
      cssClass: 'icon-rain-1'
    },
    {
      id: 1403,
      provider: 'fontello-icons',
      cssClass: 'icon-flash-3'
    },
    {
      id: 1404,
      provider: 'fontello-icons',
      cssClass: 'icon-moon-3'
    },
    {
      id: 1405,
      provider: 'fontello-icons',
      cssClass: 'icon-moon-inv'
    },
    {
      id: 1406,
      provider: 'fontello-icons',
      cssClass: 'icon-umbrella-1'
    },
    {
      id: 1407,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-bar-3'
    },
    {
      id: 1408,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-pie-2'
    },
    {
      id: 1409,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-pie-alt'
    },
    {
      id: 1410,
      provider: 'fontello-icons',
      cssClass: 'icon-key-3'
    },
    {
      id: 1411,
      provider: 'fontello-icons',
      cssClass: 'icon-key-inv'
    },
    {
      id: 1412,
      provider: 'fontello-icons',
      cssClass: 'icon-hash'
    },
    {
      id: 1413,
      provider: 'fontello-icons',
      cssClass: 'icon-at-1'
    },
    {
      id: 1414,
      provider: 'fontello-icons',
      cssClass: 'icon-pilcrow'
    },
    {
      id: 1415,
      provider: 'fontello-icons',
      cssClass: 'icon-dial'
    },
    {
      id: 1416,
      provider: 'fontello-icons',
      cssClass: 'icon-search-4'
    },
    {
      id: 1417,
      provider: 'fontello-icons',
      cssClass: 'icon-mail-4'
    },
    {
      id: 1418,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-4'
    },
    {
      id: 1419,
      provider: 'fontello-icons',
      cssClass: 'icon-star-4'
    },
    {
      id: 1420,
      provider: 'fontello-icons',
      cssClass: 'icon-user-4'
    },
    {
      id: 1421,
      provider: 'fontello-icons',
      cssClass: 'icon-user-woman'
    },
    {
      id: 1422,
      provider: 'fontello-icons',
      cssClass: 'icon-user-pair'
    },
    {
      id: 1423,
      provider: 'fontello-icons',
      cssClass: 'icon-video-alt'
    },
    {
      id: 1424,
      provider: 'fontello-icons',
      cssClass: 'icon-videocam-2'
    },
    {
      id: 1425,
      provider: 'fontello-icons',
      cssClass: 'icon-videocam-alt'
    },
    {
      id: 1426,
      provider: 'fontello-icons',
      cssClass: 'icon-camera-4'
    },
    {
      id: 1427,
      provider: 'fontello-icons',
      cssClass: 'icon-th-2'
    },
    {
      id: 1428,
      provider: 'fontello-icons',
      cssClass: 'icon-th-list-2'
    },
    {
      id: 1429,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-3'
    },
    {
      id: 1430,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-4'
    },
    {
      id: 1431,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circle-1'
    },
    {
      id: 1432,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-4'
    },
    {
      id: 1433,
      provider: 'fontello-icons',
      cssClass: 'icon-home-4'
    },
    {
      id: 1434,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-4'
    },
    {
      id: 1435,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-4'
    },
    {
      id: 1436,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-4'
    },
    {
      id: 1437,
      provider: 'fontello-icons',
      cssClass: 'icon-tag-4'
    },
    {
      id: 1438,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-up-3'
    },
    {
      id: 1439,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-down-3'
    },
    {
      id: 1440,
      provider: 'fontello-icons',
      cssClass: 'icon-download-4'
    },
    {
      id: 1441,
      provider: 'fontello-icons',
      cssClass: 'icon-export-3'
    },
    {
      id: 1442,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-4'
    },
    {
      id: 1443,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-alt-1'
    },
    {
      id: 1444,
      provider: 'fontello-icons',
      cssClass: 'icon-edit-2'
    },
    {
      id: 1445,
      provider: 'fontello-icons',
      cssClass: 'icon-chat-4'
    },
    {
      id: 1446,
      provider: 'fontello-icons',
      cssClass: 'icon-print-3'
    },
    {
      id: 1447,
      provider: 'fontello-icons',
      cssClass: 'icon-bell-3'
    },
    {
      id: 1448,
      provider: 'fontello-icons',
      cssClass: 'icon-attention-3'
    },
    {
      id: 1449,
      provider: 'fontello-icons',
      cssClass: 'icon-info-4'
    },
    {
      id: 1450,
      provider: 'fontello-icons',
      cssClass: 'icon-question'
    },
    {
      id: 1451,
      provider: 'fontello-icons',
      cssClass: 'icon-location-4'
    },
    {
      id: 1452,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-4'
    },
    {
      id: 1453,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-4'
    },
    {
      id: 1454,
      provider: 'fontello-icons',
      cssClass: 'icon-article-1'
    },
    {
      id: 1455,
      provider: 'fontello-icons',
      cssClass: 'icon-article-alt-1'
    },
    {
      id: 1456,
      provider: 'fontello-icons',
      cssClass: 'icon-rss-4'
    },
    {
      id: 1457,
      provider: 'fontello-icons',
      cssClass: 'icon-wrench-3'
    },
    {
      id: 1458,
      provider: 'fontello-icons',
      cssClass: 'icon-basket-3'
    },
    {
      id: 1459,
      provider: 'fontello-icons',
      cssClass: 'icon-basket-alt'
    },
    {
      id: 1460,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-4'
    },
    {
      id: 1461,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-alt-1'
    },
    {
      id: 1462,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-off-3'
    },
    {
      id: 1463,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-down-1'
    },
    {
      id: 1464,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-up-2'
    },
    {
      id: 1465,
      provider: 'fontello-icons',
      cssClass: 'icon-bullhorn'
    },
    {
      id: 1466,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-4'
    },
    {
      id: 1467,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-alt'
    },
    {
      id: 1468,
      provider: 'fontello-icons',
      cssClass: 'icon-stop-4'
    },
    {
      id: 1469,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-4'
    },
    {
      id: 1470,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-small-3'
    },
    {
      id: 1471,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-in-2'
    },
    {
      id: 1472,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-out-2'
    },
    {
      id: 1473,
      provider: 'fontello-icons',
      cssClass: 'icon-popup-4'
    },
    {
      id: 1474,
      provider: 'fontello-icons',
      cssClass: 'icon-down-dir-2'
    },
    {
      id: 1475,
      provider: 'fontello-icons',
      cssClass: 'icon-left-dir-2'
    },
    {
      id: 1476,
      provider: 'fontello-icons',
      cssClass: 'icon-right-dir-2'
    },
    {
      id: 1477,
      provider: 'fontello-icons',
      cssClass: 'icon-up-dir-2'
    },
    {
      id: 1478,
      provider: 'fontello-icons',
      cssClass: 'icon-down-4'
    },
    {
      id: 1479,
      provider: 'fontello-icons',
      cssClass: 'icon-up-4'
    },
    {
      id: 1480,
      provider: 'fontello-icons',
      cssClass: 'icon-cw-4'
    },
    {
      id: 1481,
      provider: 'fontello-icons',
      cssClass: 'icon-signal-3'
    },
    {
      id: 1482,
      provider: 'fontello-icons',
      cssClass: 'icon-award-2'
    },
    {
      id: 1483,
      provider: 'fontello-icons',
      cssClass: 'icon-mobile-4'
    },
    {
      id: 1484,
      provider: 'fontello-icons',
      cssClass: 'icon-mobile-alt'
    },
    {
      id: 1485,
      provider: 'fontello-icons',
      cssClass: 'icon-tablet-2'
    },
    {
      id: 1486,
      provider: 'fontello-icons',
      cssClass: 'icon-ipod'
    },
    {
      id: 1487,
      provider: 'fontello-icons',
      cssClass: 'icon-cd-2'
    },
    {
      id: 1488,
      provider: 'fontello-icons',
      cssClass: 'icon-grid'
    },
    {
      id: 1489,
      provider: 'fontello-icons',
      cssClass: 'icon-book-3'
    },
    {
      id: 1490,
      provider: 'fontello-icons',
      cssClass: 'icon-easel'
    },
    {
      id: 1491,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-3'
    },
    {
      id: 1492,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-1'
    },
    {
      id: 1493,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-bar-4'
    },
    {
      id: 1494,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-pie-3'
    },
    {
      id: 1495,
      provider: 'fontello-icons',
      cssClass: 'icon-dollar-1'
    },
    {
      id: 1496,
      provider: 'fontello-icons',
      cssClass: 'icon-at-2'
    },
    {
      id: 1497,
      provider: 'fontello-icons',
      cssClass: 'icon-colon'
    },
    {
      id: 1498,
      provider: 'fontello-icons',
      cssClass: 'icon-semicolon'
    },
    {
      id: 1499,
      provider: 'fontello-icons',
      cssClass: 'icon-squares'
    },
    {
      id: 1500,
      provider: 'fontello-icons',
      cssClass: 'icon-money-1'
    },
    {
      id: 1501,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-3'
    },
    {
      id: 1502,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-rect'
    },
    {
      id: 1503,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-3'
    },
    {
      id: 1504,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-bird'
    },
    {
      id: 1505,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-rect'
    },
    {
      id: 1506,
      provider: 'fontello-icons',
      cssClass: 'icon-youtube-1'
    },
    {
      id: 1507,
      provider: 'fontello-icons',
      cssClass: 'icon-search-5'
    },
    {
      id: 1508,
      provider: 'fontello-icons',
      cssClass: 'icon-mail-5'
    },
    {
      id: 1509,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-5'
    },
    {
      id: 1510,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-broken'
    },
    {
      id: 1511,
      provider: 'fontello-icons',
      cssClass: 'icon-star-5'
    },
    {
      id: 1512,
      provider: 'fontello-icons',
      cssClass: 'icon-star-empty-2'
    },
    {
      id: 1513,
      provider: 'fontello-icons',
      cssClass: 'icon-star-half-1'
    },
    {
      id: 1514,
      provider: 'fontello-icons',
      cssClass: 'icon-star-half_empty'
    },
    {
      id: 1515,
      provider: 'fontello-icons',
      cssClass: 'icon-user-5'
    },
    {
      id: 1516,
      provider: 'fontello-icons',
      cssClass: 'icon-user-male'
    },
    {
      id: 1517,
      provider: 'fontello-icons',
      cssClass: 'icon-user-female'
    },
    {
      id: 1518,
      provider: 'fontello-icons',
      cssClass: 'icon-users-3'
    },
    {
      id: 1519,
      provider: 'fontello-icons',
      cssClass: 'icon-movie'
    },
    {
      id: 1520,
      provider: 'fontello-icons',
      cssClass: 'icon-videocam-3'
    },
    {
      id: 1521,
      provider: 'fontello-icons',
      cssClass: 'icon-isight'
    },
    {
      id: 1522,
      provider: 'fontello-icons',
      cssClass: 'icon-camera-5'
    },
    {
      id: 1523,
      provider: 'fontello-icons',
      cssClass: 'icon-menu-3'
    },
    {
      id: 1524,
      provider: 'fontello-icons',
      cssClass: 'icon-th-thumb'
    },
    {
      id: 1525,
      provider: 'fontello-icons',
      cssClass: 'icon-th-thumb-empty'
    },
    {
      id: 1526,
      provider: 'fontello-icons',
      cssClass: 'icon-th-list-3'
    },
    {
      id: 1527,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-4'
    },
    {
      id: 1528,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-circled-1'
    },
    {
      id: 1529,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-5'
    },
    {
      id: 1530,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circled-3'
    },
    {
      id: 1531,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-5'
    },
    {
      id: 1532,
      provider: 'fontello-icons',
      cssClass: 'icon-help-circled-2'
    },
    {
      id: 1533,
      provider: 'fontello-icons',
      cssClass: 'icon-help-circled-alt'
    },
    {
      id: 1534,
      provider: 'fontello-icons',
      cssClass: 'icon-info-circled-2'
    },
    {
      id: 1535,
      provider: 'fontello-icons',
      cssClass: 'icon-info-circled-alt'
    },
    {
      id: 1536,
      provider: 'fontello-icons',
      cssClass: 'icon-home-5'
    },
    {
      id: 1537,
      provider: 'fontello-icons',
      cssClass: 'icon-link-4'
    },
    {
      id: 1538,
      provider: 'fontello-icons',
      cssClass: 'icon-attach-4'
    },
    {
      id: 1539,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-5'
    },
    {
      id: 1540,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-alt'
    },
    {
      id: 1541,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-5'
    },
    {
      id: 1542,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-alt-1'
    },
    {
      id: 1543,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-5'
    },
    {
      id: 1544,
      provider: 'fontello-icons',
      cssClass: 'icon-download-5'
    },
    {
      id: 1545,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-4'
    },
    {
      id: 1546,
      provider: 'fontello-icons',
      cssClass: 'icon-download-cloud-2'
    },
    {
      id: 1547,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-cloud-4'
    },
    {
      id: 1548,
      provider: 'fontello-icons',
      cssClass: 'icon-reply-3'
    },
    {
      id: 1549,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-5'
    },
    {
      id: 1550,
      provider: 'fontello-icons',
      cssClass: 'icon-export-4'
    },
    {
      id: 1551,
      provider: 'fontello-icons',
      cssClass: 'icon-print-4'
    },
    {
      id: 1552,
      provider: 'fontello-icons',
      cssClass: 'icon-retweet-2'
    },
    {
      id: 1553,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-4'
    },
    {
      id: 1554,
      provider: 'fontello-icons',
      cssClass: 'icon-chat-5'
    },
    {
      id: 1555,
      provider: 'fontello-icons',
      cssClass: 'icon-bell-4'
    },
    {
      id: 1556,
      provider: 'fontello-icons',
      cssClass: 'icon-attention-4'
    },
    {
      id: 1557,
      provider: 'fontello-icons',
      cssClass: 'icon-attention-alt-1'
    },
    {
      id: 1558,
      provider: 'fontello-icons',
      cssClass: 'icon-location-5'
    },
    {
      id: 1559,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-5'
    },
    {
      id: 1560,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-5'
    },
    {
      id: 1561,
      provider: 'fontello-icons',
      cssClass: 'icon-newspaper-1'
    },
    {
      id: 1562,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-4'
    },
    {
      id: 1563,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-open-1'
    },
    {
      id: 1564,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-empty-2'
    },
    {
      id: 1565,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-open-empty-1'
    },
    {
      id: 1566,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-4'
    },
    {
      id: 1567,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-5'
    },
    {
      id: 1568,
      provider: 'fontello-icons',
      cssClass: 'icon-login-2'
    },
    {
      id: 1569,
      provider: 'fontello-icons',
      cssClass: 'icon-logout-2'
    },
    {
      id: 1570,
      provider: 'fontello-icons',
      cssClass: 'icon-mic-4'
    },
    {
      id: 1571,
      provider: 'fontello-icons',
      cssClass: 'icon-mic-off'
    },
    {
      id: 1572,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-5'
    },
    {
      id: 1573,
      provider: 'fontello-icons',
      cssClass: 'icon-stopwatch-1'
    },
    {
      id: 1574,
      provider: 'fontello-icons',
      cssClass: 'icon-hourglass-1'
    },
    {
      id: 1575,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-in-3'
    },
    {
      id: 1576,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-out-3'
    },
    {
      id: 1577,
      provider: 'fontello-icons',
      cssClass: 'icon-down-open-2'
    },
    {
      id: 1578,
      provider: 'fontello-icons',
      cssClass: 'icon-left-open-3'
    },
    {
      id: 1579,
      provider: 'fontello-icons',
      cssClass: 'icon-right-open-3'
    },
    {
      id: 1580,
      provider: 'fontello-icons',
      cssClass: 'icon-up-open-2'
    },
    {
      id: 1581,
      provider: 'fontello-icons',
      cssClass: 'icon-down-5'
    },
    {
      id: 1582,
      provider: 'fontello-icons',
      cssClass: 'icon-left-4'
    },
    {
      id: 1583,
      provider: 'fontello-icons',
      cssClass: 'icon-right-4'
    },
    {
      id: 1584,
      provider: 'fontello-icons',
      cssClass: 'icon-up-5'
    },
    {
      id: 1585,
      provider: 'fontello-icons',
      cssClass: 'icon-down-bold-1'
    },
    {
      id: 1586,
      provider: 'fontello-icons',
      cssClass: 'icon-left-bold-1'
    },
    {
      id: 1587,
      provider: 'fontello-icons',
      cssClass: 'icon-right-bold-1'
    },
    {
      id: 1588,
      provider: 'fontello-icons',
      cssClass: 'icon-up-bold-1'
    },
    {
      id: 1589,
      provider: 'fontello-icons',
      cssClass: 'icon-down-fat'
    },
    {
      id: 1590,
      provider: 'fontello-icons',
      cssClass: 'icon-left-fat'
    },
    {
      id: 1591,
      provider: 'fontello-icons',
      cssClass: 'icon-right-fat'
    },
    {
      id: 1592,
      provider: 'fontello-icons',
      cssClass: 'icon-up-fat'
    },
    {
      id: 1593,
      provider: 'fontello-icons',
      cssClass: 'icon-ccw-2'
    },
    {
      id: 1594,
      provider: 'fontello-icons',
      cssClass: 'icon-shuffle-3'
    },
    {
      id: 1595,
      provider: 'fontello-icons',
      cssClass: 'icon-play-4'
    },
    {
      id: 1596,
      provider: 'fontello-icons',
      cssClass: 'icon-pause-4'
    },
    {
      id: 1597,
      provider: 'fontello-icons',
      cssClass: 'icon-stop-5'
    },
    {
      id: 1598,
      provider: 'fontello-icons',
      cssClass: 'icon-to-end-3'
    },
    {
      id: 1599,
      provider: 'fontello-icons',
      cssClass: 'icon-to-start-3'
    },
    {
      id: 1600,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-forward-1'
    },
    {
      id: 1601,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-backward-1'
    },
    {
      id: 1602,
      provider: 'fontello-icons',
      cssClass: 'icon-trophy-1'
    },
    {
      id: 1603,
      provider: 'fontello-icons',
      cssClass: 'icon-monitor-1'
    },
    {
      id: 1604,
      provider: 'fontello-icons',
      cssClass: 'icon-tablet-3'
    },
    {
      id: 1605,
      provider: 'fontello-icons',
      cssClass: 'icon-mobile-5'
    },
    {
      id: 1606,
      provider: 'fontello-icons',
      cssClass: 'icon-data-science'
    },
    {
      id: 1607,
      provider: 'fontello-icons',
      cssClass: 'icon-data-science-inv'
    },
    {
      id: 1608,
      provider: 'fontello-icons',
      cssClass: 'icon-inbox-2'
    },
    {
      id: 1609,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-4'
    },
    {
      id: 1610,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-inv'
    },
    {
      id: 1611,
      provider: 'fontello-icons',
      cssClass: 'icon-flash-4'
    },
    {
      id: 1612,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-4'
    },
    {
      id: 1613,
      provider: 'fontello-icons',
      cssClass: 'icon-coverflow'
    },
    {
      id: 1614,
      provider: 'fontello-icons',
      cssClass: 'icon-coverflow-empty'
    },
    {
      id: 1615,
      provider: 'fontello-icons',
      cssClass: 'icon-math'
    },
    {
      id: 1616,
      provider: 'fontello-icons',
      cssClass: 'icon-math-circled'
    },
    {
      id: 1617,
      provider: 'fontello-icons',
      cssClass: 'icon-math-circled-empty'
    },
    {
      id: 1618,
      provider: 'fontello-icons',
      cssClass: 'icon-paper-plane-1'
    },
    {
      id: 1619,
      provider: 'fontello-icons',
      cssClass: 'icon-paper-plane-alt'
    },
    {
      id: 1620,
      provider: 'fontello-icons',
      cssClass: 'icon-paper-plane-alt2'
    },
    {
      id: 1621,
      provider: 'fontello-icons',
      cssClass: 'icon-fontsize'
    },
    {
      id: 1622,
      provider: 'fontello-icons',
      cssClass: 'icon-color-adjust'
    },
    {
      id: 1623,
      provider: 'fontello-icons',
      cssClass: 'icon-fire-1'
    },
    {
      id: 1624,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-bar-5'
    },
    {
      id: 1625,
      provider: 'fontello-icons',
      cssClass: 'icon-hdd-1'
    },
    {
      id: 1626,
      provider: 'fontello-icons',
      cssClass: 'icon-connected-object'
    },
    {
      id: 1627,
      provider: 'fontello-icons',
      cssClass: 'icon-ruler'
    },
    {
      id: 1628,
      provider: 'fontello-icons',
      cssClass: 'icon-vector'
    },
    {
      id: 1629,
      provider: 'fontello-icons',
      cssClass: 'icon-vector-pencil'
    },
    {
      id: 1630,
      provider: 'fontello-icons',
      cssClass: 'icon-at-3'
    },
    {
      id: 1631,
      provider: 'fontello-icons',
      cssClass: 'icon-hash-1'
    },
    {
      id: 1632,
      provider: 'fontello-icons',
      cssClass: 'icon-female-1'
    },
    {
      id: 1633,
      provider: 'fontello-icons',
      cssClass: 'icon-male-1'
    },
    {
      id: 1634,
      provider: 'fontello-icons',
      cssClass: 'icon-spread'
    },
    {
      id: 1635,
      provider: 'fontello-icons',
      cssClass: 'icon-king'
    },
    {
      id: 1636,
      provider: 'fontello-icons',
      cssClass: 'icon-anchor-2'
    },
    {
      id: 1637,
      provider: 'fontello-icons',
      cssClass: 'icon-joystick'
    },
    {
      id: 1638,
      provider: 'fontello-icons',
      cssClass: 'icon-spinner1'
    },
    {
      id: 1639,
      provider: 'fontello-icons',
      cssClass: 'icon-spinner2'
    },
    {
      id: 1640,
      provider: 'fontello-icons',
      cssClass: 'icon-github-3'
    },
    {
      id: 1641,
      provider: 'fontello-icons',
      cssClass: 'icon-github-circled-3'
    },
    {
      id: 1642,
      provider: 'fontello-icons',
      cssClass: 'icon-github-circled-alt'
    },
    {
      id: 1643,
      provider: 'fontello-icons',
      cssClass: 'icon-github-circled-alt2'
    },
    {
      id: 1644,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-4'
    },
    {
      id: 1645,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-circled-2'
    },
    {
      id: 1646,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-4'
    },
    {
      id: 1647,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-circled-2'
    },
    {
      id: 1648,
      provider: 'fontello-icons',
      cssClass: 'icon-gplus-2'
    },
    {
      id: 1649,
      provider: 'fontello-icons',
      cssClass: 'icon-gplus-circled-1'
    },
    {
      id: 1650,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-3'
    },
    {
      id: 1651,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-circled-2'
    },
    {
      id: 1652,
      provider: 'fontello-icons',
      cssClass: 'icon-dribbble-3'
    },
    {
      id: 1653,
      provider: 'fontello-icons',
      cssClass: 'icon-dribbble-circled-2'
    },
    {
      id: 1654,
      provider: 'fontello-icons',
      cssClass: 'icon-instagram-1'
    },
    {
      id: 1655,
      provider: 'fontello-icons',
      cssClass: 'icon-instagram-circled'
    },
    {
      id: 1656,
      provider: 'fontello-icons',
      cssClass: 'icon-soundcloud-1'
    },
    {
      id: 1657,
      provider: 'fontello-icons',
      cssClass: 'icon-soundcloud-circled'
    },
    {
      id: 1658,
      provider: 'fontello-icons',
      cssClass: 'icon-mfg-logo'
    },
    {
      id: 1659,
      provider: 'fontello-icons',
      cssClass: 'icon-mfg-logo-circled'
    },
    {
      id: 1660,
      provider: 'fontello-icons',
      cssClass: 'icon-aboveground-rail'
    },
    {
      id: 1661,
      provider: 'fontello-icons',
      cssClass: 'icon-airfield'
    },
    {
      id: 1662,
      provider: 'fontello-icons',
      cssClass: 'icon-airport'
    },
    {
      id: 1663,
      provider: 'fontello-icons',
      cssClass: 'icon-art-gallery'
    },
    {
      id: 1664,
      provider: 'fontello-icons',
      cssClass: 'icon-bar'
    },
    {
      id: 1665,
      provider: 'fontello-icons',
      cssClass: 'icon-baseball'
    },
    {
      id: 1666,
      provider: 'fontello-icons',
      cssClass: 'icon-basketball'
    },
    {
      id: 1667,
      provider: 'fontello-icons',
      cssClass: 'icon-beer-2'
    },
    {
      id: 1668,
      provider: 'fontello-icons',
      cssClass: 'icon-belowground-rail'
    },
    {
      id: 1669,
      provider: 'fontello-icons',
      cssClass: 'icon-bicycle'
    },
    {
      id: 1670,
      provider: 'fontello-icons',
      cssClass: 'icon-bus'
    },
    {
      id: 1671,
      provider: 'fontello-icons',
      cssClass: 'icon-cafe'
    },
    {
      id: 1672,
      provider: 'fontello-icons',
      cssClass: 'icon-campsite'
    },
    {
      id: 1673,
      provider: 'fontello-icons',
      cssClass: 'icon-cemetery'
    },
    {
      id: 1674,
      provider: 'fontello-icons',
      cssClass: 'icon-cinema'
    },
    {
      id: 1675,
      provider: 'fontello-icons',
      cssClass: 'icon-college'
    },
    {
      id: 1676,
      provider: 'fontello-icons',
      cssClass: 'icon-commerical-building'
    },
    {
      id: 1677,
      provider: 'fontello-icons',
      cssClass: 'icon-credit-card-3'
    },
    {
      id: 1678,
      provider: 'fontello-icons',
      cssClass: 'icon-cricket'
    },
    {
      id: 1679,
      provider: 'fontello-icons',
      cssClass: 'icon-embassy'
    },
    {
      id: 1680,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-food'
    },
    {
      id: 1681,
      provider: 'fontello-icons',
      cssClass: 'icon-ferry'
    },
    {
      id: 1682,
      provider: 'fontello-icons',
      cssClass: 'icon-fire-station'
    },
    {
      id: 1683,
      provider: 'fontello-icons',
      cssClass: 'icon-football'
    },
    {
      id: 1684,
      provider: 'fontello-icons',
      cssClass: 'icon-fuel'
    },
    {
      id: 1685,
      provider: 'fontello-icons',
      cssClass: 'icon-garden'
    },
    {
      id: 1686,
      provider: 'fontello-icons',
      cssClass: 'icon-giraffe'
    },
    {
      id: 1687,
      provider: 'fontello-icons',
      cssClass: 'icon-golf'
    },
    {
      id: 1688,
      provider: 'fontello-icons',
      cssClass: 'icon-grocery-store'
    },
    {
      id: 1689,
      provider: 'fontello-icons',
      cssClass: 'icon-harbor'
    },
    {
      id: 1690,
      provider: 'fontello-icons',
      cssClass: 'icon-heliport'
    },
    {
      id: 1691,
      provider: 'fontello-icons',
      cssClass: 'icon-hospital-1'
    },
    {
      id: 1692,
      provider: 'fontello-icons',
      cssClass: 'icon-industrial-building'
    },
    {
      id: 1693,
      provider: 'fontello-icons',
      cssClass: 'icon-library'
    },
    {
      id: 1694,
      provider: 'fontello-icons',
      cssClass: 'icon-lodging'
    },
    {
      id: 1695,
      provider: 'fontello-icons',
      cssClass: 'icon-london-underground'
    },
    {
      id: 1696,
      provider: 'fontello-icons',
      cssClass: 'icon-minefield'
    },
    {
      id: 1697,
      provider: 'fontello-icons',
      cssClass: 'icon-monument'
    },
    {
      id: 1698,
      provider: 'fontello-icons',
      cssClass: 'icon-museum'
    },
    {
      id: 1699,
      provider: 'fontello-icons',
      cssClass: 'icon-pharmacy'
    },
    {
      id: 1700,
      provider: 'fontello-icons',
      cssClass: 'icon-pitch'
    },
    {
      id: 1701,
      provider: 'fontello-icons',
      cssClass: 'icon-police'
    },
    {
      id: 1702,
      provider: 'fontello-icons',
      cssClass: 'icon-post'
    },
    {
      id: 1703,
      provider: 'fontello-icons',
      cssClass: 'icon-prison'
    },
    {
      id: 1704,
      provider: 'fontello-icons',
      cssClass: 'icon-rail'
    },
    {
      id: 1705,
      provider: 'fontello-icons',
      cssClass: 'icon-religious-christian'
    },
    {
      id: 1706,
      provider: 'fontello-icons',
      cssClass: 'icon-religious-islam'
    },
    {
      id: 1707,
      provider: 'fontello-icons',
      cssClass: 'icon-religious-jewish'
    },
    {
      id: 1708,
      provider: 'fontello-icons',
      cssClass: 'icon-restaurant'
    },
    {
      id: 1709,
      provider: 'fontello-icons',
      cssClass: 'icon-roadblock'
    },
    {
      id: 1710,
      provider: 'fontello-icons',
      cssClass: 'icon-school'
    },
    {
      id: 1711,
      provider: 'fontello-icons',
      cssClass: 'icon-shop'
    },
    {
      id: 1712,
      provider: 'fontello-icons',
      cssClass: 'icon-skiing'
    },
    {
      id: 1713,
      provider: 'fontello-icons',
      cssClass: 'icon-soccer'
    },
    {
      id: 1714,
      provider: 'fontello-icons',
      cssClass: 'icon-swimming'
    },
    {
      id: 1715,
      provider: 'fontello-icons',
      cssClass: 'icon-tennis'
    },
    {
      id: 1716,
      provider: 'fontello-icons',
      cssClass: 'icon-theatre'
    },
    {
      id: 1717,
      provider: 'fontello-icons',
      cssClass: 'icon-toilet'
    },
    {
      id: 1718,
      provider: 'fontello-icons',
      cssClass: 'icon-town-hall'
    },
    {
      id: 1719,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-6'
    },
    {
      id: 1720,
      provider: 'fontello-icons',
      cssClass: 'icon-tree-1'
    },
    {
      id: 1721,
      provider: 'fontello-icons',
      cssClass: 'icon-tree-2'
    },
    {
      id: 1722,
      provider: 'fontello-icons',
      cssClass: 'icon-warehouse'
    },
    {
      id: 1723,
      provider: 'fontello-icons',
      cssClass: 'icon-duckduckgo'
    },
    {
      id: 1724,
      provider: 'fontello-icons',
      cssClass: 'icon-aim'
    },
    {
      id: 1725,
      provider: 'fontello-icons',
      cssClass: 'icon-delicious'
    },
    {
      id: 1726,
      provider: 'fontello-icons',
      cssClass: 'icon-paypal-1'
    },
    {
      id: 1727,
      provider: 'fontello-icons',
      cssClass: 'icon-flattr-1'
    },
    {
      id: 1728,
      provider: 'fontello-icons',
      cssClass: 'icon-android-1'
    },
    {
      id: 1729,
      provider: 'fontello-icons',
      cssClass: 'icon-eventful'
    },
    {
      id: 1730,
      provider: 'fontello-icons',
      cssClass: 'icon-smashmag'
    },
    {
      id: 1731,
      provider: 'fontello-icons',
      cssClass: 'icon-gplus-3'
    },
    {
      id: 1732,
      provider: 'fontello-icons',
      cssClass: 'icon-wikipedia'
    },
    {
      id: 1733,
      provider: 'fontello-icons',
      cssClass: 'icon-lanyrd'
    },
    {
      id: 1734,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-6'
    },
    {
      id: 1735,
      provider: 'fontello-icons',
      cssClass: 'icon-stumbleupon-1'
    },
    {
      id: 1736,
      provider: 'fontello-icons',
      cssClass: 'icon-fivehundredpx'
    },
    {
      id: 1737,
      provider: 'fontello-icons',
      cssClass: 'icon-pinterest-2'
    },
    {
      id: 1738,
      provider: 'fontello-icons',
      cssClass: 'icon-bitcoin-1'
    },
    {
      id: 1739,
      provider: 'fontello-icons',
      cssClass: 'icon-w3c'
    },
    {
      id: 1740,
      provider: 'fontello-icons',
      cssClass: 'icon-foursquare-1'
    },
    {
      id: 1741,
      provider: 'fontello-icons',
      cssClass: 'icon-html5-1'
    },
    {
      id: 1742,
      provider: 'fontello-icons',
      cssClass: 'icon-ie'
    },
    {
      id: 1743,
      provider: 'fontello-icons',
      cssClass: 'icon-call'
    },
    {
      id: 1744,
      provider: 'fontello-icons',
      cssClass: 'icon-grooveshark'
    },
    {
      id: 1745,
      provider: 'fontello-icons',
      cssClass: 'icon-ninetyninedesigns'
    },
    {
      id: 1746,
      provider: 'fontello-icons',
      cssClass: 'icon-forrst'
    },
    {
      id: 1747,
      provider: 'fontello-icons',
      cssClass: 'icon-digg'
    },
    {
      id: 1748,
      provider: 'fontello-icons',
      cssClass: 'icon-spotify-1'
    },
    {
      id: 1749,
      provider: 'fontello-icons',
      cssClass: 'icon-reddit'
    },
    {
      id: 1750,
      provider: 'fontello-icons',
      cssClass: 'icon-guest'
    },
    {
      id: 1751,
      provider: 'fontello-icons',
      cssClass: 'icon-gowalla'
    },
    {
      id: 1752,
      provider: 'fontello-icons',
      cssClass: 'icon-appstore'
    },
    {
      id: 1753,
      provider: 'fontello-icons',
      cssClass: 'icon-blogger'
    },
    {
      id: 1754,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-1'
    },
    {
      id: 1755,
      provider: 'fontello-icons',
      cssClass: 'icon-dribbble-4'
    },
    {
      id: 1756,
      provider: 'fontello-icons',
      cssClass: 'icon-evernote-1'
    },
    {
      id: 1757,
      provider: 'fontello-icons',
      cssClass: 'icon-flickr-3'
    },
    {
      id: 1758,
      provider: 'fontello-icons',
      cssClass: 'icon-google'
    },
    {
      id: 1759,
      provider: 'fontello-icons',
      cssClass: 'icon-viadeo'
    },
    {
      id: 1760,
      provider: 'fontello-icons',
      cssClass: 'icon-instapaper'
    },
    {
      id: 1761,
      provider: 'fontello-icons',
      cssClass: 'icon-weibo-1'
    },
    {
      id: 1762,
      provider: 'fontello-icons',
      cssClass: 'icon-klout'
    },
    {
      id: 1763,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-4'
    },
    {
      id: 1764,
      provider: 'fontello-icons',
      cssClass: 'icon-meetup'
    },
    {
      id: 1765,
      provider: 'fontello-icons',
      cssClass: 'icon-vk'
    },
    {
      id: 1766,
      provider: 'fontello-icons',
      cssClass: 'icon-plancast'
    },
    {
      id: 1767,
      provider: 'fontello-icons',
      cssClass: 'icon-disqus'
    },
    {
      id: 1768,
      provider: 'fontello-icons',
      cssClass: 'icon-rss-5'
    },
    {
      id: 1769,
      provider: 'fontello-icons',
      cssClass: 'icon-skype-3'
    },
    {
      id: 1770,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-5'
    },
    {
      id: 1771,
      provider: 'fontello-icons',
      cssClass: 'icon-youtube-2'
    },
    {
      id: 1772,
      provider: 'fontello-icons',
      cssClass: 'icon-vimeo-2'
    },
    {
      id: 1773,
      provider: 'fontello-icons',
      cssClass: 'icon-windows-1'
    },
    {
      id: 1774,
      provider: 'fontello-icons',
      cssClass: 'icon-xing-1'
    },
    {
      id: 1775,
      provider: 'fontello-icons',
      cssClass: 'icon-yahoo'
    },
    {
      id: 1776,
      provider: 'fontello-icons',
      cssClass: 'icon-chrome'
    },
    {
      id: 1777,
      provider: 'fontello-icons',
      cssClass: 'icon-email'
    },
    {
      id: 1778,
      provider: 'fontello-icons',
      cssClass: 'icon-macstore'
    },
    {
      id: 1779,
      provider: 'fontello-icons',
      cssClass: 'icon-myspace'
    },
    {
      id: 1780,
      provider: 'fontello-icons',
      cssClass: 'icon-podcast'
    },
    {
      id: 1781,
      provider: 'fontello-icons',
      cssClass: 'icon-amazon'
    },
    {
      id: 1782,
      provider: 'fontello-icons',
      cssClass: 'icon-steam'
    },
    {
      id: 1783,
      provider: 'fontello-icons',
      cssClass: 'icon-cloudapp'
    },
    {
      id: 1784,
      provider: 'fontello-icons',
      cssClass: 'icon-dropbox-2'
    },
    {
      id: 1785,
      provider: 'fontello-icons',
      cssClass: 'icon-ebay'
    },
    {
      id: 1786,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-5'
    },
    {
      id: 1787,
      provider: 'fontello-icons',
      cssClass: 'icon-github-4'
    },
    {
      id: 1788,
      provider: 'fontello-icons',
      cssClass: 'icon-github-circled-4'
    },
    {
      id: 1789,
      provider: 'fontello-icons',
      cssClass: 'icon-googleplay'
    },
    {
      id: 1790,
      provider: 'fontello-icons',
      cssClass: 'icon-itunes'
    },
    {
      id: 1791,
      provider: 'fontello-icons',
      cssClass: 'icon-plurk'
    },
    {
      id: 1792,
      provider: 'fontello-icons',
      cssClass: 'icon-songkick'
    },
    {
      id: 1793,
      provider: 'fontello-icons',
      cssClass: 'icon-lastfm-2'
    },
    {
      id: 1794,
      provider: 'fontello-icons',
      cssClass: 'icon-gmail'
    },
    {
      id: 1795,
      provider: 'fontello-icons',
      cssClass: 'icon-pinboard'
    },
    {
      id: 1796,
      provider: 'fontello-icons',
      cssClass: 'icon-openid'
    },
    {
      id: 1797,
      provider: 'fontello-icons',
      cssClass: 'icon-quora'
    },
    {
      id: 1798,
      provider: 'fontello-icons',
      cssClass: 'icon-soundcloud-2'
    },
    {
      id: 1799,
      provider: 'fontello-icons',
      cssClass: 'icon-tumblr-2'
    },
    {
      id: 1800,
      provider: 'fontello-icons',
      cssClass: 'icon-eventasaurus'
    },
    {
      id: 1801,
      provider: 'fontello-icons',
      cssClass: 'icon-wordpress'
    },
    {
      id: 1802,
      provider: 'fontello-icons',
      cssClass: 'icon-yelp'
    },
    {
      id: 1803,
      provider: 'fontello-icons',
      cssClass: 'icon-intensedebate'
    },
    {
      id: 1804,
      provider: 'fontello-icons',
      cssClass: 'icon-eventbrite'
    },
    {
      id: 1805,
      provider: 'fontello-icons',
      cssClass: 'icon-scribd'
    },
    {
      id: 1806,
      provider: 'fontello-icons',
      cssClass: 'icon-posterous'
    },
    {
      id: 1807,
      provider: 'fontello-icons',
      cssClass: 'icon-stripe'
    },
    {
      id: 1808,
      provider: 'fontello-icons',
      cssClass: 'icon-opentable'
    },
    {
      id: 1809,
      provider: 'fontello-icons',
      cssClass: 'icon-cart'
    },
    {
      id: 1810,
      provider: 'fontello-icons',
      cssClass: 'icon-print-5'
    },
    {
      id: 1811,
      provider: 'fontello-icons',
      cssClass: 'icon-angellist'
    },
    {
      id: 1812,
      provider: 'fontello-icons',
      cssClass: 'icon-instagram-2'
    },
    {
      id: 1813,
      provider: 'fontello-icons',
      cssClass: 'icon-dwolla'
    },
    {
      id: 1814,
      provider: 'fontello-icons',
      cssClass: 'icon-appnet'
    },
    {
      id: 1815,
      provider: 'fontello-icons',
      cssClass: 'icon-statusnet'
    },
    {
      id: 1816,
      provider: 'fontello-icons',
      cssClass: 'icon-acrobat'
    },
    {
      id: 1817,
      provider: 'fontello-icons',
      cssClass: 'icon-drupal'
    },
    {
      id: 1818,
      provider: 'fontello-icons',
      cssClass: 'icon-buffer'
    },
    {
      id: 1819,
      provider: 'fontello-icons',
      cssClass: 'icon-pocket'
    },
    {
      id: 1820,
      provider: 'fontello-icons',
      cssClass: 'icon-bitbucket-1'
    },
    {
      id: 1821,
      provider: 'fontello-icons',
      cssClass: 'icon-lego'
    },
    {
      id: 1822,
      provider: 'fontello-icons',
      cssClass: 'icon-login-3'
    },
    {
      id: 1823,
      provider: 'fontello-icons',
      cssClass: 'icon-stackoverflow-1'
    },
    {
      id: 1824,
      provider: 'fontello-icons',
      cssClass: 'icon-hackernews'
    },
    {
      id: 1825,
      provider: 'fontello-icons',
      cssClass: 'icon-lkdto'
    },
    {
      id: 1826,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-6'
    },
    {
      id: 1827,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-rect-1'
    },
    {
      id: 1828,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-6'
    },
    {
      id: 1829,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-bird-1'
    },
    {
      id: 1830,
      provider: 'fontello-icons',
      cssClass: 'icon-vimeo-3'
    },
    {
      id: 1831,
      provider: 'fontello-icons',
      cssClass: 'icon-vimeo-rect'
    },
    {
      id: 1832,
      provider: 'fontello-icons',
      cssClass: 'icon-tumblr-3'
    },
    {
      id: 1833,
      provider: 'fontello-icons',
      cssClass: 'icon-tumblr-rect'
    },
    {
      id: 1834,
      provider: 'fontello-icons',
      cssClass: 'icon-googleplus-rect'
    },
    {
      id: 1835,
      provider: 'fontello-icons',
      cssClass: 'icon-github-text'
    },
    {
      id: 1836,
      provider: 'fontello-icons',
      cssClass: 'icon-github-5'
    },
    {
      id: 1837,
      provider: 'fontello-icons',
      cssClass: 'icon-skype-4'
    },
    {
      id: 1838,
      provider: 'fontello-icons',
      cssClass: 'icon-icq'
    },
    {
      id: 1839,
      provider: 'fontello-icons',
      cssClass: 'icon-yandex'
    },
    {
      id: 1840,
      provider: 'fontello-icons',
      cssClass: 'icon-yandex-rect'
    },
    {
      id: 1841,
      provider: 'fontello-icons',
      cssClass: 'icon-vkontakte-rect'
    },
    {
      id: 1842,
      provider: 'fontello-icons',
      cssClass: 'icon-odnoklassniki'
    },
    {
      id: 1843,
      provider: 'fontello-icons',
      cssClass: 'icon-odnoklassniki-rect'
    },
    {
      id: 1844,
      provider: 'fontello-icons',
      cssClass: 'icon-friendfeed'
    },
    {
      id: 1845,
      provider: 'fontello-icons',
      cssClass: 'icon-friendfeed-rect'
    },
    {
      id: 1846,
      provider: 'fontello-icons',
      cssClass: 'icon-blogger-1'
    },
    {
      id: 1847,
      provider: 'fontello-icons',
      cssClass: 'icon-blogger-rect'
    },
    {
      id: 1848,
      provider: 'fontello-icons',
      cssClass: 'icon-deviantart'
    },
    {
      id: 1849,
      provider: 'fontello-icons',
      cssClass: 'icon-jabber'
    },
    {
      id: 1850,
      provider: 'fontello-icons',
      cssClass: 'icon-lastfm-3'
    },
    {
      id: 1851,
      provider: 'fontello-icons',
      cssClass: 'icon-lastfm-rect'
    },
    {
      id: 1852,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-5'
    },
    {
      id: 1853,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-rect'
    },
    {
      id: 1854,
      provider: 'fontello-icons',
      cssClass: 'icon-picasa-1'
    },
    {
      id: 1855,
      provider: 'fontello-icons',
      cssClass: 'icon-wordpress-1'
    },
    {
      id: 1856,
      provider: 'fontello-icons',
      cssClass: 'icon-instagram-3'
    },
    {
      id: 1857,
      provider: 'fontello-icons',
      cssClass: 'icon-instagram-filled'
    },
    {
      id: 1858,
      provider: 'fontello-icons',
      cssClass: 'icon-diigo'
    },
    {
      id: 1859,
      provider: 'fontello-icons',
      cssClass: 'icon-box-4'
    },
    {
      id: 1860,
      provider: 'fontello-icons',
      cssClass: 'icon-box-rect'
    },
    {
      id: 1861,
      provider: 'fontello-icons',
      cssClass: 'icon-tudou'
    },
    {
      id: 1862,
      provider: 'fontello-icons',
      cssClass: 'icon-youku'
    },
    {
      id: 1863,
      provider: 'fontello-icons',
      cssClass: 'icon-win8'
    },
    {
      id: 1864,
      provider: 'fontello-icons',
      cssClass: 'icon-glass-1'
    },
    {
      id: 1865,
      provider: 'fontello-icons',
      cssClass: 'icon-music-4'
    },
    {
      id: 1866,
      provider: 'fontello-icons',
      cssClass: 'icon-search-8'
    },
    {
      id: 1867,
      provider: 'fontello-icons',
      cssClass: 'icon-search-circled'
    },
    {
      id: 1868,
      provider: 'fontello-icons',
      cssClass: 'icon-mail-7'
    },
    {
      id: 1869,
      provider: 'fontello-icons',
      cssClass: 'icon-mail-circled'
    },
    {
      id: 1870,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-8'
    },
    {
      id: 1871,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-circled'
    },
    {
      id: 1872,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-empty-4'
    },
    {
      id: 1873,
      provider: 'fontello-icons',
      cssClass: 'icon-star-8'
    },
    {
      id: 1874,
      provider: 'fontello-icons',
      cssClass: 'icon-star-circled'
    },
    {
      id: 1875,
      provider: 'fontello-icons',
      cssClass: 'icon-star-empty-3'
    },
    {
      id: 1876,
      provider: 'fontello-icons',
      cssClass: 'icon-user-8'
    },
    {
      id: 1877,
      provider: 'fontello-icons',
      cssClass: 'icon-group'
    },
    {
      id: 1878,
      provider: 'fontello-icons',
      cssClass: 'icon-group-circled'
    },
    {
      id: 1879,
      provider: 'fontello-icons',
      cssClass: 'icon-torso'
    },
    {
      id: 1880,
      provider: 'fontello-icons',
      cssClass: 'icon-video-5'
    },
    {
      id: 1881,
      provider: 'fontello-icons',
      cssClass: 'icon-video-circled'
    },
    {
      id: 1882,
      provider: 'fontello-icons',
      cssClass: 'icon-video-alt-1'
    },
    {
      id: 1883,
      provider: 'fontello-icons',
      cssClass: 'icon-videocam-5'
    },
    {
      id: 1884,
      provider: 'fontello-icons',
      cssClass: 'icon-video-chat'
    },
    {
      id: 1885,
      provider: 'fontello-icons',
      cssClass: 'icon-picture-5'
    },
    {
      id: 1886,
      provider: 'fontello-icons',
      cssClass: 'icon-camera-7'
    },
    {
      id: 1887,
      provider: 'fontello-icons',
      cssClass: 'icon-photo-1'
    },
    {
      id: 1888,
      provider: 'fontello-icons',
      cssClass: 'icon-photo-circled'
    },
    {
      id: 1889,
      provider: 'fontello-icons',
      cssClass: 'icon-th-large-2'
    },
    {
      id: 1890,
      provider: 'fontello-icons',
      cssClass: 'icon-th-3'
    },
    {
      id: 1891,
      provider: 'fontello-icons',
      cssClass: 'icon-th-list-5'
    },
    {
      id: 1892,
      provider: 'fontello-icons',
      cssClass: 'icon-view-mode'
    },
    {
      id: 1893,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-6'
    },
    {
      id: 1894,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-circled-2'
    },
    {
      id: 1895,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-circled2-1'
    },
    {
      id: 1896,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-7'
    },
    {
      id: 1897,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circled-4'
    },
    {
      id: 1898,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circled2-1'
    },
    {
      id: 1899,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-6'
    },
    {
      id: 1900,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-circled-2'
    },
    {
      id: 1901,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-4'
    },
    {
      id: 1902,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-circled-2'
    },
    {
      id: 1903,
      provider: 'fontello-icons',
      cssClass: 'icon-help-3'
    },
    {
      id: 1904,
      provider: 'fontello-icons',
      cssClass: 'icon-help-circled-3'
    },
    {
      id: 1905,
      provider: 'fontello-icons',
      cssClass: 'icon-info-circled-3'
    },
    {
      id: 1906,
      provider: 'fontello-icons',
      cssClass: 'icon-home-6'
    },
    {
      id: 1907,
      provider: 'fontello-icons',
      cssClass: 'icon-home-circled'
    },
    {
      id: 1908,
      provider: 'fontello-icons',
      cssClass: 'icon-website'
    },
    {
      id: 1909,
      provider: 'fontello-icons',
      cssClass: 'icon-website-circled'
    },
    {
      id: 1910,
      provider: 'fontello-icons',
      cssClass: 'icon-attach-7'
    },
    {
      id: 1911,
      provider: 'fontello-icons',
      cssClass: 'icon-attach-circled'
    },
    {
      id: 1912,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-8'
    },
    {
      id: 1913,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-circled'
    },
    {
      id: 1914,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-7'
    },
    {
      id: 1915,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-alt-2'
    },
    {
      id: 1916,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-7'
    },
    {
      id: 1917,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-off-1'
    },
    {
      id: 1918,
      provider: 'fontello-icons',
      cssClass: 'icon-tag-7'
    },
    {
      id: 1919,
      provider: 'fontello-icons',
      cssClass: 'icon-tags-2'
    },
    {
      id: 1920,
      provider: 'fontello-icons',
      cssClass: 'icon-bookmark-3'
    },
    {
      id: 1921,
      provider: 'fontello-icons',
      cssClass: 'icon-bookmark-empty-1'
    },
    {
      id: 1922,
      provider: 'fontello-icons',
      cssClass: 'icon-flag-3'
    },
    {
      id: 1923,
      provider: 'fontello-icons',
      cssClass: 'icon-flag-circled'
    },
    {
      id: 1924,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-up-5'
    },
    {
      id: 1925,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-down-4'
    },
    {
      id: 1926,
      provider: 'fontello-icons',
      cssClass: 'icon-download-6'
    },
    {
      id: 1927,
      provider: 'fontello-icons',
      cssClass: 'icon-download-alt'
    },
    {
      id: 1928,
      provider: 'fontello-icons',
      cssClass: 'icon-upload-5'
    },
    {
      id: 1929,
      provider: 'fontello-icons',
      cssClass: 'icon-share-2'
    },
    {
      id: 1930,
      provider: 'fontello-icons',
      cssClass: 'icon-quote-1'
    },
    {
      id: 1931,
      provider: 'fontello-icons',
      cssClass: 'icon-quote-circled'
    },
    {
      id: 1932,
      provider: 'fontello-icons',
      cssClass: 'icon-export-5'
    },
    {
      id: 1933,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-7'
    },
    {
      id: 1934,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-circled'
    },
    {
      id: 1935,
      provider: 'fontello-icons',
      cssClass: 'icon-edit-3'
    },
    {
      id: 1936,
      provider: 'fontello-icons',
      cssClass: 'icon-edit-circled'
    },
    {
      id: 1937,
      provider: 'fontello-icons',
      cssClass: 'icon-edit-alt'
    },
    {
      id: 1938,
      provider: 'fontello-icons',
      cssClass: 'icon-print-6'
    },
    {
      id: 1939,
      provider: 'fontello-icons',
      cssClass: 'icon-retweet-4'
    },
    {
      id: 1940,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-7'
    },
    {
      id: 1941,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-alt-1'
    },
    {
      id: 1942,
      provider: 'fontello-icons',
      cssClass: 'icon-bell-5'
    },
    {
      id: 1943,
      provider: 'fontello-icons',
      cssClass: 'icon-warning-1'
    },
    {
      id: 1944,
      provider: 'fontello-icons',
      cssClass: 'icon-exclamation'
    },
    {
      id: 1945,
      provider: 'fontello-icons',
      cssClass: 'icon-error'
    },
    {
      id: 1946,
      provider: 'fontello-icons',
      cssClass: 'icon-error-alt'
    },
    {
      id: 1947,
      provider: 'fontello-icons',
      cssClass: 'icon-location-7'
    },
    {
      id: 1948,
      provider: 'fontello-icons',
      cssClass: 'icon-location-circled'
    },
    {
      id: 1949,
      provider: 'fontello-icons',
      cssClass: 'icon-compass-4'
    },
    {
      id: 1950,
      provider: 'fontello-icons',
      cssClass: 'icon-compass-circled'
    },
    {
      id: 1951,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-8'
    },
    {
      id: 1952,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-circled'
    },
    {
      id: 1953,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-7'
    },
    {
      id: 1954,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-circled'
    },
    {
      id: 1955,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-new'
    },
    {
      id: 1956,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-new-circled'
    },
    {
      id: 1957,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-5'
    },
    {
      id: 1958,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-circled'
    },
    {
      id: 1959,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-close'
    },
    {
      id: 1960,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-open-2'
    },
    {
      id: 1961,
      provider: 'fontello-icons',
      cssClass: 'icon-rss-6'
    },
    {
      id: 1962,
      provider: 'fontello-icons',
      cssClass: 'icon-phone-3'
    },
    {
      id: 1963,
      provider: 'fontello-icons',
      cssClass: 'icon-phone-circled'
    },
    {
      id: 1964,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-6'
    },
    {
      id: 1965,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-circled'
    },
    {
      id: 1966,
      provider: 'fontello-icons',
      cssClass: 'icon-cogs'
    },
    {
      id: 1967,
      provider: 'fontello-icons',
      cssClass: 'icon-wrench-4'
    },
    {
      id: 1968,
      provider: 'fontello-icons',
      cssClass: 'icon-wrench-circled'
    },
    {
      id: 1969,
      provider: 'fontello-icons',
      cssClass: 'icon-basket-4'
    },
    {
      id: 1970,
      provider: 'fontello-icons',
      cssClass: 'icon-basket-circled'
    },
    {
      id: 1971,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-8'
    },
    {
      id: 1972,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-circled'
    },
    {
      id: 1973,
      provider: 'fontello-icons',
      cssClass: 'icon-mic-5'
    },
    {
      id: 1974,
      provider: 'fontello-icons',
      cssClass: 'icon-mic-circled'
    },
    {
      id: 1975,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-off-4'
    },
    {
      id: 1976,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-down-2'
    },
    {
      id: 1977,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-1'
    },
    {
      id: 1978,
      provider: 'fontello-icons',
      cssClass: 'icon-volume-up-3'
    },
    {
      id: 1979,
      provider: 'fontello-icons',
      cssClass: 'icon-headphones-3'
    },
    {
      id: 1980,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-8'
    },
    {
      id: 1981,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-circled'
    },
    {
      id: 1982,
      provider: 'fontello-icons',
      cssClass: 'icon-lightbulb-3'
    },
    {
      id: 1983,
      provider: 'fontello-icons',
      cssClass: 'icon-lightbulb-alt'
    },
    {
      id: 1984,
      provider: 'fontello-icons',
      cssClass: 'icon-block-4'
    },
    {
      id: 1985,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-6'
    },
    {
      id: 1986,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-alt-2'
    },
    {
      id: 1987,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-small-4'
    },
    {
      id: 1988,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-vertical-2'
    },
    {
      id: 1989,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-horizontal-2'
    },
    {
      id: 1990,
      provider: 'fontello-icons',
      cssClass: 'icon-move-3'
    },
    {
      id: 1991,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-in-4'
    },
    {
      id: 1992,
      provider: 'fontello-icons',
      cssClass: 'icon-zoom-out-4'
    },
    {
      id: 1993,
      provider: 'fontello-icons',
      cssClass: 'icon-down-open-3'
    },
    {
      id: 1994,
      provider: 'fontello-icons',
      cssClass: 'icon-left-open-5'
    },
    {
      id: 1995,
      provider: 'fontello-icons',
      cssClass: 'icon-right-open-5'
    },
    {
      id: 1996,
      provider: 'fontello-icons',
      cssClass: 'icon-up-open-3'
    },
    {
      id: 1997,
      provider: 'fontello-icons',
      cssClass: 'icon-down-6'
    },
    {
      id: 1998,
      provider: 'fontello-icons',
      cssClass: 'icon-left-5'
    },
    {
      id: 1999,
      provider: 'fontello-icons',
      cssClass: 'icon-right-5'
    },
    {
      id: 2000,
      provider: 'fontello-icons',
      cssClass: 'icon-up-6'
    },
    {
      id: 2001,
      provider: 'fontello-icons',
      cssClass: 'icon-down-circled-2'
    },
    {
      id: 2002,
      provider: 'fontello-icons',
      cssClass: 'icon-left-circled-2'
    },
    {
      id: 2003,
      provider: 'fontello-icons',
      cssClass: 'icon-right-circled-2'
    },
    {
      id: 2004,
      provider: 'fontello-icons',
      cssClass: 'icon-up-circled-2'
    },
    {
      id: 2005,
      provider: 'fontello-icons',
      cssClass: 'icon-down-hand-1'
    },
    {
      id: 2006,
      provider: 'fontello-icons',
      cssClass: 'icon-left-hand-1'
    },
    {
      id: 2007,
      provider: 'fontello-icons',
      cssClass: 'icon-right-hand-1'
    },
    {
      id: 2008,
      provider: 'fontello-icons',
      cssClass: 'icon-up-hand-1'
    },
    {
      id: 2009,
      provider: 'fontello-icons',
      cssClass: 'icon-cw-5'
    },
    {
      id: 2010,
      provider: 'fontello-icons',
      cssClass: 'icon-cw-circled'
    },
    {
      id: 2011,
      provider: 'fontello-icons',
      cssClass: 'icon-arrows-cw-3'
    },
    {
      id: 2012,
      provider: 'fontello-icons',
      cssClass: 'icon-shuffle-4'
    },
    {
      id: 2013,
      provider: 'fontello-icons',
      cssClass: 'icon-play-5'
    },
    {
      id: 2014,
      provider: 'fontello-icons',
      cssClass: 'icon-play-circled-1'
    },
    {
      id: 2015,
      provider: 'fontello-icons',
      cssClass: 'icon-play-circled2-1'
    },
    {
      id: 2016,
      provider: 'fontello-icons',
      cssClass: 'icon-stop-6'
    },
    {
      id: 2017,
      provider: 'fontello-icons',
      cssClass: 'icon-stop-circled'
    },
    {
      id: 2018,
      provider: 'fontello-icons',
      cssClass: 'icon-pause-5'
    },
    {
      id: 2019,
      provider: 'fontello-icons',
      cssClass: 'icon-pause-circled'
    },
    {
      id: 2020,
      provider: 'fontello-icons',
      cssClass: 'icon-record-2'
    },
    {
      id: 2021,
      provider: 'fontello-icons',
      cssClass: 'icon-eject-3'
    },
    {
      id: 2022,
      provider: 'fontello-icons',
      cssClass: 'icon-backward'
    },
    {
      id: 2023,
      provider: 'fontello-icons',
      cssClass: 'icon-backward-circled'
    },
    {
      id: 2024,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-backward-2'
    },
    {
      id: 2025,
      provider: 'fontello-icons',
      cssClass: 'icon-fast-forward-2'
    },
    {
      id: 2026,
      provider: 'fontello-icons',
      cssClass: 'icon-forward-3'
    },
    {
      id: 2027,
      provider: 'fontello-icons',
      cssClass: 'icon-forward-circled'
    },
    {
      id: 2028,
      provider: 'fontello-icons',
      cssClass: 'icon-step-backward'
    },
    {
      id: 2029,
      provider: 'fontello-icons',
      cssClass: 'icon-step-forward'
    },
    {
      id: 2030,
      provider: 'fontello-icons',
      cssClass: 'icon-target-5'
    },
    {
      id: 2031,
      provider: 'fontello-icons',
      cssClass: 'icon-signal-5'
    },
    {
      id: 2032,
      provider: 'fontello-icons',
      cssClass: 'icon-desktop-3'
    },
    {
      id: 2033,
      provider: 'fontello-icons',
      cssClass: 'icon-desktop-circled'
    },
    {
      id: 2034,
      provider: 'fontello-icons',
      cssClass: 'icon-laptop-2'
    },
    {
      id: 2035,
      provider: 'fontello-icons',
      cssClass: 'icon-laptop-circled'
    },
    {
      id: 2036,
      provider: 'fontello-icons',
      cssClass: 'icon-network-1'
    },
    {
      id: 2037,
      provider: 'fontello-icons',
      cssClass: 'icon-inbox-4'
    },
    {
      id: 2038,
      provider: 'fontello-icons',
      cssClass: 'icon-inbox-circled'
    },
    {
      id: 2039,
      provider: 'fontello-icons',
      cssClass: 'icon-inbox-alt'
    },
    {
      id: 2040,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-6'
    },
    {
      id: 2041,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-alt-1'
    },
    {
      id: 2042,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-6'
    },
    {
      id: 2043,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-circled'
    },
    {
      id: 2044,
      provider: 'fontello-icons',
      cssClass: 'icon-flight-2'
    },
    {
      id: 2045,
      provider: 'fontello-icons',
      cssClass: 'icon-leaf-3'
    },
    {
      id: 2046,
      provider: 'fontello-icons',
      cssClass: 'icon-font-1'
    },
    {
      id: 2047,
      provider: 'fontello-icons',
      cssClass: 'icon-fontsize-1'
    },
    {
      id: 2048,
      provider: 'fontello-icons',
      cssClass: 'icon-bold-1'
    },
    {
      id: 2049,
      provider: 'fontello-icons',
      cssClass: 'icon-italic-1'
    },
    {
      id: 2050,
      provider: 'fontello-icons',
      cssClass: 'icon-text-height-1'
    },
    {
      id: 2051,
      provider: 'fontello-icons',
      cssClass: 'icon-text-width-1'
    },
    {
      id: 2052,
      provider: 'fontello-icons',
      cssClass: 'icon-align-left-1'
    },
    {
      id: 2053,
      provider: 'fontello-icons',
      cssClass: 'icon-align-center-1'
    },
    {
      id: 2054,
      provider: 'fontello-icons',
      cssClass: 'icon-align-right-1'
    },
    {
      id: 2055,
      provider: 'fontello-icons',
      cssClass: 'icon-align-justify-1'
    },
    {
      id: 2056,
      provider: 'fontello-icons',
      cssClass: 'icon-list-3'
    },
    {
      id: 2057,
      provider: 'fontello-icons',
      cssClass: 'icon-indent-left-1'
    },
    {
      id: 2058,
      provider: 'fontello-icons',
      cssClass: 'icon-indent-right-1'
    },
    {
      id: 2059,
      provider: 'fontello-icons',
      cssClass: 'icon-briefcase-3'
    },
    {
      id: 2060,
      provider: 'fontello-icons',
      cssClass: 'icon-off-1'
    },
    {
      id: 2061,
      provider: 'fontello-icons',
      cssClass: 'icon-road-1'
    },
    {
      id: 2062,
      provider: 'fontello-icons',
      cssClass: 'icon-qrcode-1'
    },
    {
      id: 2063,
      provider: 'fontello-icons',
      cssClass: 'icon-barcode-1'
    },
    {
      id: 2064,
      provider: 'fontello-icons',
      cssClass: 'icon-braille'
    },
    {
      id: 2065,
      provider: 'fontello-icons',
      cssClass: 'icon-book-4'
    },
    {
      id: 2066,
      provider: 'fontello-icons',
      cssClass: 'icon-adjust-1'
    },
    {
      id: 2067,
      provider: 'fontello-icons',
      cssClass: 'icon-tint-1'
    },
    {
      id: 2068,
      provider: 'fontello-icons',
      cssClass: 'icon-check-3'
    },
    {
      id: 2069,
      provider: 'fontello-icons',
      cssClass: 'icon-check-empty-1'
    },
    {
      id: 2070,
      provider: 'fontello-icons',
      cssClass: 'icon-asterisk-1'
    },
    {
      id: 2071,
      provider: 'fontello-icons',
      cssClass: 'icon-gift-2'
    },
    {
      id: 2072,
      provider: 'fontello-icons',
      cssClass: 'icon-fire-3'
    },
    {
      id: 2073,
      provider: 'fontello-icons',
      cssClass: 'icon-magnet-2'
    },
    {
      id: 2074,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-2'
    },
    {
      id: 2075,
      provider: 'fontello-icons',
      cssClass: 'icon-chart-circled'
    },
    {
      id: 2076,
      provider: 'fontello-icons',
      cssClass: 'icon-credit-card-4'
    },
    {
      id: 2077,
      provider: 'fontello-icons',
      cssClass: 'icon-megaphone-3'
    },
    {
      id: 2078,
      provider: 'fontello-icons',
      cssClass: 'icon-clipboard-2'
    },
    {
      id: 2079,
      provider: 'fontello-icons',
      cssClass: 'icon-hdd-2'
    },
    {
      id: 2080,
      provider: 'fontello-icons',
      cssClass: 'icon-key-5'
    },
    {
      id: 2081,
      provider: 'fontello-icons',
      cssClass: 'icon-certificate-2'
    },
    {
      id: 2082,
      provider: 'fontello-icons',
      cssClass: 'icon-tasks-1'
    },
    {
      id: 2083,
      provider: 'fontello-icons',
      cssClass: 'icon-filter-1'
    },
    {
      id: 2084,
      provider: 'fontello-icons',
      cssClass: 'icon-gauge-2'
    },
    {
      id: 2085,
      provider: 'fontello-icons',
      cssClass: 'icon-smiley'
    },
    {
      id: 2086,
      provider: 'fontello-icons',
      cssClass: 'icon-smiley-circled'
    },
    {
      id: 2087,
      provider: 'fontello-icons',
      cssClass: 'icon-address-book'
    },
    {
      id: 2088,
      provider: 'fontello-icons',
      cssClass: 'icon-address-book-alt'
    },
    {
      id: 2089,
      provider: 'fontello-icons',
      cssClass: 'icon-asl'
    },
    {
      id: 2090,
      provider: 'fontello-icons',
      cssClass: 'icon-glasses'
    },
    {
      id: 2091,
      provider: 'fontello-icons',
      cssClass: 'icon-hearing-impaired'
    },
    {
      id: 2092,
      provider: 'fontello-icons',
      cssClass: 'icon-iphone-home'
    },
    {
      id: 2093,
      provider: 'fontello-icons',
      cssClass: 'icon-person'
    },
    {
      id: 2094,
      provider: 'fontello-icons',
      cssClass: 'icon-adult'
    },
    {
      id: 2095,
      provider: 'fontello-icons',
      cssClass: 'icon-child'
    },
    {
      id: 2096,
      provider: 'fontello-icons',
      cssClass: 'icon-blind'
    },
    {
      id: 2097,
      provider: 'fontello-icons',
      cssClass: 'icon-guidedog'
    },
    {
      id: 2098,
      provider: 'fontello-icons',
      cssClass: 'icon-accessibility'
    },
    {
      id: 2099,
      provider: 'fontello-icons',
      cssClass: 'icon-universal-access'
    },
    {
      id: 2100,
      provider: 'fontello-icons',
      cssClass: 'icon-male-2'
    },
    {
      id: 2101,
      provider: 'fontello-icons',
      cssClass: 'icon-female-2'
    },
    {
      id: 2102,
      provider: 'fontello-icons',
      cssClass: 'icon-behance-1'
    },
    {
      id: 2103,
      provider: 'fontello-icons',
      cssClass: 'icon-blogger-2'
    },
    {
      id: 2104,
      provider: 'fontello-icons',
      cssClass: 'icon-cc-2'
    },
    {
      id: 2105,
      provider: 'fontello-icons',
      cssClass: 'icon-css'
    },
    {
      id: 2106,
      provider: 'fontello-icons',
      cssClass: 'icon-delicious-1'
    },
    {
      id: 2107,
      provider: 'fontello-icons',
      cssClass: 'icon-deviantart-1'
    },
    {
      id: 2108,
      provider: 'fontello-icons',
      cssClass: 'icon-digg-1'
    },
    {
      id: 2109,
      provider: 'fontello-icons',
      cssClass: 'icon-dribbble-5'
    },
    {
      id: 2110,
      provider: 'fontello-icons',
      cssClass: 'icon-facebook-7'
    },
    {
      id: 2111,
      provider: 'fontello-icons',
      cssClass: 'icon-flickr-4'
    },
    {
      id: 2112,
      provider: 'fontello-icons',
      cssClass: 'icon-foursquare-2'
    },
    {
      id: 2113,
      provider: 'fontello-icons',
      cssClass: 'icon-friendfeed-1'
    },
    {
      id: 2114,
      provider: 'fontello-icons',
      cssClass: 'icon-friendfeed-rect-1'
    },
    {
      id: 2115,
      provider: 'fontello-icons',
      cssClass: 'icon-github-6'
    },
    {
      id: 2116,
      provider: 'fontello-icons',
      cssClass: 'icon-github-text-1'
    },
    {
      id: 2117,
      provider: 'fontello-icons',
      cssClass: 'icon-googleplus'
    },
    {
      id: 2118,
      provider: 'fontello-icons',
      cssClass: 'icon-instagram-4'
    },
    {
      id: 2119,
      provider: 'fontello-icons',
      cssClass: 'icon-linkedin-6'
    },
    {
      id: 2120,
      provider: 'fontello-icons',
      cssClass: 'icon-path'
    },
    {
      id: 2121,
      provider: 'fontello-icons',
      cssClass: 'icon-picasa-2'
    },
    {
      id: 2122,
      provider: 'fontello-icons',
      cssClass: 'icon-pinterest-3'
    },
    {
      id: 2123,
      provider: 'fontello-icons',
      cssClass: 'icon-reddit-1'
    },
    {
      id: 2124,
      provider: 'fontello-icons',
      cssClass: 'icon-skype-5'
    },
    {
      id: 2125,
      provider: 'fontello-icons',
      cssClass: 'icon-slideshare'
    },
    {
      id: 2126,
      provider: 'fontello-icons',
      cssClass: 'icon-stackoverflow-2'
    },
    {
      id: 2127,
      provider: 'fontello-icons',
      cssClass: 'icon-stumbleupon-2'
    },
    {
      id: 2128,
      provider: 'fontello-icons',
      cssClass: 'icon-twitter-7'
    },
    {
      id: 2129,
      provider: 'fontello-icons',
      cssClass: 'icon-tumblr-4'
    },
    {
      id: 2130,
      provider: 'fontello-icons',
      cssClass: 'icon-vimeo-4'
    },
    {
      id: 2131,
      provider: 'fontello-icons',
      cssClass: 'icon-vkontakte-2'
    },
    {
      id: 2132,
      provider: 'fontello-icons',
      cssClass: 'icon-w3c-1'
    },
    {
      id: 2133,
      provider: 'fontello-icons',
      cssClass: 'icon-wordpress-2'
    },
    {
      id: 2134,
      provider: 'fontello-icons',
      cssClass: 'icon-youtube-3'
    },
    {
      id: 2135,
      provider: 'fontello-icons',
      cssClass: 'icon-music-3'
    },
    {
      id: 2136,
      provider: 'fontello-icons',
      cssClass: 'icon-search-7'
    },
    {
      id: 2137,
      provider: 'fontello-icons',
      cssClass: 'icon-mail-6'
    },
    {
      id: 2138,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-7'
    },
    {
      id: 2139,
      provider: 'fontello-icons',
      cssClass: 'icon-star-7'
    },
    {
      id: 2140,
      provider: 'fontello-icons',
      cssClass: 'icon-user-7'
    },
    {
      id: 2141,
      provider: 'fontello-icons',
      cssClass: 'icon-videocam-4'
    },
    {
      id: 2142,
      provider: 'fontello-icons',
      cssClass: 'icon-camera-6'
    },
    {
      id: 2143,
      provider: 'fontello-icons',
      cssClass: 'icon-photo'
    },
    {
      id: 2144,
      provider: 'fontello-icons',
      cssClass: 'icon-attach-6'
    },
    {
      id: 2145,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-7'
    },
    {
      id: 2146,
      provider: 'fontello-icons',
      cssClass: 'icon-eye-6'
    },
    {
      id: 2147,
      provider: 'fontello-icons',
      cssClass: 'icon-tag-6'
    },
    {
      id: 2148,
      provider: 'fontello-icons',
      cssClass: 'icon-thumbs-up-4'
    },
    {
      id: 2149,
      provider: 'fontello-icons',
      cssClass: 'icon-pencil-6'
    },
    {
      id: 2150,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-6'
    },
    {
      id: 2151,
      provider: 'fontello-icons',
      cssClass: 'icon-location-6'
    },
    {
      id: 2152,
      provider: 'fontello-icons',
      cssClass: 'icon-cup-1'
    },
    {
      id: 2153,
      provider: 'fontello-icons',
      cssClass: 'icon-trash-7'
    },
    {
      id: 2154,
      provider: 'fontello-icons',
      cssClass: 'icon-doc-6'
    },
    {
      id: 2155,
      provider: 'fontello-icons',
      cssClass: 'icon-note-1'
    },
    {
      id: 2156,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-5'
    },
    {
      id: 2157,
      provider: 'fontello-icons',
      cssClass: 'icon-params'
    },
    {
      id: 2158,
      provider: 'fontello-icons',
      cssClass: 'icon-calendar-7'
    },
    {
      id: 2159,
      provider: 'fontello-icons',
      cssClass: 'icon-sound-1'
    },
    {
      id: 2160,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-7'
    },
    {
      id: 2161,
      provider: 'fontello-icons',
      cssClass: 'icon-lightbulb-2'
    },
    {
      id: 2162,
      provider: 'fontello-icons',
      cssClass: 'icon-tv'
    },
    {
      id: 2163,
      provider: 'fontello-icons',
      cssClass: 'icon-desktop-2'
    },
    {
      id: 2164,
      provider: 'fontello-icons',
      cssClass: 'icon-mobile-6'
    },
    {
      id: 2165,
      provider: 'fontello-icons',
      cssClass: 'icon-cd-3'
    },
    {
      id: 2166,
      provider: 'fontello-icons',
      cssClass: 'icon-inbox-3'
    },
    {
      id: 2167,
      provider: 'fontello-icons',
      cssClass: 'icon-globe-5'
    },
    {
      id: 2168,
      provider: 'fontello-icons',
      cssClass: 'icon-cloud-5'
    },
    {
      id: 2169,
      provider: 'fontello-icons',
      cssClass: 'icon-paper-plane-2'
    },
    {
      id: 2170,
      provider: 'fontello-icons',
      cssClass: 'icon-fire-2'
    },
    {
      id: 2171,
      provider: 'fontello-icons',
      cssClass: 'icon-graduation-cap-1'
    },
    {
      id: 2172,
      provider: 'fontello-icons',
      cssClass: 'icon-megaphone-2'
    },
    {
      id: 2173,
      provider: 'fontello-icons',
      cssClass: 'icon-database-2'
    },
    {
      id: 2174,
      provider: 'fontello-icons',
      cssClass: 'icon-key-4'
    },
    {
      id: 2175,
      provider: 'fontello-icons',
      cssClass: 'icon-beaker-1'
    },
    {
      id: 2176,
      provider: 'fontello-icons',
      cssClass: 'icon-truck-1'
    },
    {
      id: 2177,
      provider: 'fontello-icons',
      cssClass: 'icon-money-2'
    },
    {
      id: 2178,
      provider: 'fontello-icons',
      cssClass: 'icon-food-1'
    },
    {
      id: 2179,
      provider: 'fontello-icons',
      cssClass: 'icon-shop-1'
    },
    {
      id: 2180,
      provider: 'fontello-icons',
      cssClass: 'icon-diamond'
    },
    {
      id: 2181,
      provider: 'fontello-icons',
      cssClass: 'icon-t-shirt'
    },
    {
      id: 2182,
      provider: 'fontello-icons',
      cssClass: 'icon-wallet'
    },
    {
      id: 2183,
      provider: 'fontello-icons',
      cssClass: 'icon-search-6'
    },
    {
      id: 2184,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-6'
    },
    {
      id: 2185,
      provider: 'fontello-icons',
      cssClass: 'icon-heart-empty-3'
    },
    {
      id: 2186,
      provider: 'fontello-icons',
      cssClass: 'icon-star-6'
    },
    {
      id: 2187,
      provider: 'fontello-icons',
      cssClass: 'icon-user-6'
    },
    {
      id: 2188,
      provider: 'fontello-icons',
      cssClass: 'icon-video-4'
    },
    {
      id: 2189,
      provider: 'fontello-icons',
      cssClass: 'icon-picture-4'
    },
    {
      id: 2190,
      provider: 'fontello-icons',
      cssClass: 'icon-th-large-3'
    },
    {
      id: 2191,
      provider: 'fontello-icons',
      cssClass: 'icon-th-4'
    },
    {
      id: 2192,
      provider: 'fontello-icons',
      cssClass: 'icon-th-list-4'
    },
    {
      id: 2193,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-5'
    },
    {
      id: 2194,
      provider: 'fontello-icons',
      cssClass: 'icon-ok-circle-1'
    },
    {
      id: 2195,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-6'
    },
    {
      id: 2196,
      provider: 'fontello-icons',
      cssClass: 'icon-cancel-circle-2'
    },
    {
      id: 2197,
      provider: 'fontello-icons',
      cssClass: 'icon-plus-circle-1'
    },
    {
      id: 2198,
      provider: 'fontello-icons',
      cssClass: 'icon-minus-circle-1'
    },
    {
      id: 2199,
      provider: 'fontello-icons',
      cssClass: 'icon-link-5'
    },
    {
      id: 2200,
      provider: 'fontello-icons',
      cssClass: 'icon-attach-5'
    },
    {
      id: 2201,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-6'
    },
    {
      id: 2202,
      provider: 'fontello-icons',
      cssClass: 'icon-lock-open-6'
    },
    {
      id: 2203,
      provider: 'fontello-icons',
      cssClass: 'icon-tag-5'
    },
    {
      id: 2204,
      provider: 'fontello-icons',
      cssClass: 'icon-reply-4'
    },
    {
      id: 2205,
      provider: 'fontello-icons',
      cssClass: 'icon-reply-all-2'
    },
    {
      id: 2206,
      provider: 'fontello-icons',
      cssClass: 'icon-code-3'
    },
    {
      id: 2207,
      provider: 'fontello-icons',
      cssClass: 'icon-retweet-3'
    },
    {
      id: 2208,
      provider: 'fontello-icons',
      cssClass: 'icon-comment-5'
    },
    {
      id: 2209,
      provider: 'fontello-icons',
      cssClass: 'icon-location-8'
    },
    {
      id: 2210,
      provider: 'fontello-icons',
      cssClass: 'icon-folder-6'
    },
    {
      id: 2211,
      provider: 'fontello-icons',
      cssClass: 'icon-archive-2'
    },
    {
      id: 2212,
      provider: 'fontello-icons',
      cssClass: 'icon-cog-7'
    },
    {
      id: 2213,
      provider: 'fontello-icons',
      cssClass: 'icon-logout-3'
    },
    {
      id: 2214,
      provider: 'fontello-icons',
      cssClass: 'icon-clock-6'
    },
    {
      id: 2215,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-5'
    },
    {
      id: 2216,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-full-circle'
    },
    {
      id: 2217,
      provider: 'fontello-icons',
      cssClass: 'icon-popup-5'
    },
    {
      id: 2218,
      provider: 'fontello-icons',
      cssClass: 'icon-left-open-4'
    },
    {
      id: 2219,
      provider: 'fontello-icons',
      cssClass: 'icon-right-open-4'
    },
    {
      id: 2220,
      provider: 'fontello-icons',
      cssClass: 'icon-down-circle-1'
    },
    {
      id: 2221,
      provider: 'fontello-icons',
      cssClass: 'icon-left-circle-1'
    },
    {
      id: 2222,
      provider: 'fontello-icons',
      cssClass: 'icon-right-circle-1'
    },
    {
      id: 2223,
      provider: 'fontello-icons',
      cssClass: 'icon-up-circle-1'
    },
    {
      id: 2224,
      provider: 'fontello-icons',
      cssClass: 'icon-cw-circle'
    },
    {
      id: 2225,
      provider: 'fontello-icons',
      cssClass: 'icon-arrows-cw-2'
    },
    {
      id: 2226,
      provider: 'fontello-icons',
      cssClass: 'icon-updown-circle'
    },
    {
      id: 2227,
      provider: 'fontello-icons',
      cssClass: 'icon-target-4'
    },
    {
      id: 2228,
      provider: 'fontello-icons',
      cssClass: 'icon-signal-4'
    },
    {
      id: 2229,
      provider: 'fontello-icons',
      cssClass: 'icon-resize-horizontal-1'
    }];
}


'use strict';

/* exported
getApprovalMethod
 */

function getApprovalMethod() {
  return {
    'auto-approve-allocation': 'Prepaid',
    'auto-approve-flag': 'Auto-Approve',
    'manual-approve': 'Regular',
    'pending-approval': 'Pending Approval',
    'approved-by-supplier-api': 'API'
  };
}

/**
 * Created by john on 1/10/16.
 */
'use strict';

/* exported
 getAuthEvents
 */

function getAuthEvents() {
  return {
    loginSuccess: 'auth-login-success',
    loginFailed: 'auth-login-failed',
    logoutSuccess: 'auth-logout-success',
    sessionTimeout: 'auth-session-timeout',
    notAuthenticated: 'auth-not-authenticated',
    notAuthorized: 'auth-not-authorized'
  };
}

/**
 * Created by john on 1/10/16.
 */
'use strict';

/* exported
 getUserRoles
 */

function getUserRoles() {
  return {
    all: '*',
    support: 'support',
    admin: 'admin',
    agent: 'agent',
    'partner-admin': 'partner-admin',
    'read-admin': 'read-admin',
    traveler: 'traveler',
    'content-editor': 'content-editor',
  };
}

'use strict';

/* exported
MAX_MULTI_STATION_OFFSET
 */

const MAX_MULTI_STATION_OFFSET = 24 * 60 - 1; // 23:59 hours

'use strict';

/* exported
PRODUCT_TYPES
 */

const PRODUCT_TYPES = {
    ONLINE: 'online',
    LINE: 'line',
}

'use strict';

/* exported
RIDE_RULE_LEVELS,
RIDE_RULE_ATTRIBUTE_TYPES,
 */

const RIDE_RULE_LEVELS = {
    SUPPLIER: 'Supplier',
    OPERATOR: 'Operator',
    TRANSPORT: 'Transport',
    API: 'API',
}

const RIDE_RULE_ATTRIBUTE_TYPES = {
    APPEND: 'appendAttribute',
    OVERRIDE: 'overrideAttribute',
}

"use strict";

/* exported
CHANGE_PRICE_REASONS,
*/

const CHANGE_PRICE_REASONS = {
  CANCEL_BOOKING: {
    supplier_cancellation: {
      label: "Supplier cancellation",
      value: "supplier_cancellation",
      reasons: [
        {
          label: "Departure canceled",
          value: "departure_canceled",
        },
        {
          label: "Double booking",
          value: "double_booking",
        },
        {
          label: "Weather cancellation",
          value: "weather_cancellation",
        },
        {
          label: "Missing information from customer",
          value: "missing_information_from_customer",
        },
        {
          label: "Pickup location is not supported",
          value: "pickup_not_supported",
        },
        {
          label: "Tech issue",
          value: "tech_issue",
        },
        {
          label: 'Fully booked',
          value: 'fully_booked'
        }
      ],
    },
    customer_cancellation: {
      label: "Customer cancellation",
      value: "customer_cancellation",
      reasons: [
        {
          value: 'found_cheaper_trip',
          label: 'I found a cheaper trip elsewhere',
        },
        {
          value: 'looking_for_different_transportation',
          label: 'I’m looking for a different type of transportation',
        },
        {
          value: 'plans_changed',
          label: 'Plans changed',
        },
        {
          value: 'booking_error',
          label: 'Booking error (wrong date, departure time, etc.)',
        },
        {
          value: 'reason_not_provided',
          label: 'Reason was not provided',
        },
      ],
    },
    tech_baw_issue: {
      label: "Tech issue - Bookaway",
      value: "tech_baw_issue",
    },
  },
  CHANGE_PRICE: {
    complaint: {
      label: "Complaint",
      value: "complaint",
      reasons: [
        {
          label: "Operator performance",
          value: "operator_performance",
        },
        {
          label: "Pickup issue",
          value: "pickup_issue",
        },
        {
          label: "Wrong information",
          value: "wrong_information",
        },
        {
          label: "Voucher was not received",
          value: "voucher_not_recieved",
        },
        {
          label: "Not approved on time",
          value: "not_approved_on_time",
        },
        {
          label: "Weather cancellation",
          value: "weather_cancellation",
        },
        {
          label: "Customer experience",
          value: "customer_experience",
        },
        {
          label: "Pricing",
          value: "pricing",
        },
        {
          label: "Customer fault",
          value: "customer_fault",
        },
      ],
    },
    supplier_cancellation: {
      label: "Supplier cancellation",
      value: "supplier_cancellation",
      reasons: [
        {
          label: "Departure canceled",
          value: "departure_canceled",
        },
        {
          label: "Double booking",
          value: "double_booking",
        },
        {
          label: "Weather cancellation",
          value: "weather_cancellation",
        },
        {
          label: "Missing information from customer",
          value: "missing_information_from_customer",
        },
        {
          label: "Pickup location is not supported",
          value: "pickup_not_supported",
        },
        {
          label: "Tech issue",
          value: "tech_issue",
        },
        {
          label: 'Fully booked',
          value: 'fully_booked'
        }
      ],
    },
    customer_cancellation: {
      label: "Customer cancellation",
      value: "customer_cancellation",
      reasons: [
        {
          value: 'found_cheaper_trip',
          label: 'I found a cheaper trip elsewhere',
        },
        {
          value: 'looking_for_different_transportation',
          label: 'I’m looking for a different type of transportation',
        },
        {
          value: 'plans_changed',
          label: 'Plans changed',
        },
        {
          value: 'booking_error',
          label: 'Booking error (wrong date, departure time, etc.)',
        },
        {
          value: 'reason_not_provided',
          label: 'Reason was not provided',
        },
      ],
    },
    tech_baw_issue: {
      label: "Tech issue - Bookaway",
      value: "tech_baw_issue",
    },
    extra_charge: {
      label: "Extra charge",
      value: "extra_charge",
      reasons: [
        {
          label: "Change fee",
          value: "change_fee",
        },
        {
          label: "Price increase",
          value: "price_increase",
        },
      ],
    },
    other: {
      label: "Other",
      value: "other",
    },
  },
  BOOKING_AMENDMENT: {
    booking_amendment: {
      label: "Booking amendment",
      value: "booking_amendment",
      reasons: [
        {
          label: "Remove / add passenger",
          value: "remove_add_passenger",
        },
        {
          label: "Remove / add return",
          value: "remove_add_return",
        },
        {
          label: "Change trip",
          value: "change_trip",
        },
        {
          label: "Remove / add extra",
          value: "remove_add_extra",
        },
        {
          label: "Booking exchange",
          value: "booking_exchange",
        },
      ],
    },
  },
  DECLINED_BOOKING: {
    decline: {
      label: "Decline",
      value: "decline",
      reasons: [
        {
          value: 'fully_booked',
          label: 'Fully booked (sold out)',
        },
        {
          value: 'departure_not_exist',
          label: 'Departure does not exists',
        },
        {
          value: 'departure_cancelled',
          label: 'Departure cancelled',
        },
        {
          value: 'double_booking',
          label: 'Double booking',
        },
        {
          value: 'out_of_credit',
          label: 'Out of credit',
        },
        {
          value: 'missing_info_from_client',
          label: 'Missing information from client',
        },
        {
          value: 'pickup_not_supported',
          label: 'Pickup location is not supported',
        },
        {
          value: 'not_enough_passengers',
          label: 'Not enough passengers',
        },
        {
          value: 'supplier_unresponsive',
          label: 'Supplier unresponsive',
        },
        {
          value: 'rate_difference',
          label: 'Rate difference',
        },
        {
          value: 'not_approved_on_time',
          label: 'Not approved on time',
        },
        {
          value: 'schedule_is_not_open',
          label: 'Schedule is not open yet',
        },
        {
          value: 'other',
          label: 'Other',
        },
      ],
    },
  }
};

'use strict';

/* exported
GOOGLE_GL_COUNTRIES,
GOOGLE_HL_LANGUAGES,
 */

const GOOGLE_GL_COUNTRIES = [
    {
        "country_code": "af",
        "country_name": "Afghanistan"
    },
    {
        "country_code": "al",
        "country_name": "Albania"
    },
    {
        "country_code": "dz",
        "country_name": "Algeria"
    },
    {
        "country_code": "as",
        "country_name": "American Samoa"
    },
    {
        "country_code": "ad",
        "country_name": "Andorra"
    },
    {
        "country_code": "ao",
        "country_name": "Angola"
    },
    {
        "country_code": "ai",
        "country_name": "Anguilla"
    },
    {
        "country_code": "aq",
        "country_name": "Antarctica"
    },
    {
        "country_code": "ag",
        "country_name": "Antigua and Barbuda"
    },
    {
        "country_code": "ar",
        "country_name": "Argentina"
    },
    {
        "country_code": "am",
        "country_name": "Armenia"
    },
    {
        "country_code": "aw",
        "country_name": "Aruba"
    },
    {
        "country_code": "au",
        "country_name": "Australia"
    },
    {
        "country_code": "at",
        "country_name": "Austria"
    },
    {
        "country_code": "az",
        "country_name": "Azerbaijan"
    },
    {
        "country_code": "bs",
        "country_name": "Bahamas"
    },
    {
        "country_code": "bh",
        "country_name": "Bahrain"
    },
    {
        "country_code": "bd",
        "country_name": "Bangladesh"
    },
    {
        "country_code": "bb",
        "country_name": "Barbados"
    },
    {
        "country_code": "by",
        "country_name": "Belarus"
    },
    {
        "country_code": "be",
        "country_name": "Belgium"
    },
    {
        "country_code": "bz",
        "country_name": "Belize"
    },
    {
        "country_code": "bj",
        "country_name": "Benin"
    },
    {
        "country_code": "bm",
        "country_name": "Bermuda"
    },
    {
        "country_code": "bt",
        "country_name": "Bhutan"
    },
    {
        "country_code": "bo",
        "country_name": "Bolivia"
    },
    {
        "country_code": "ba",
        "country_name": "Bosnia and Herzegovina"
    },
    {
        "country_code": "bw",
        "country_name": "Botswana"
    },
    {
        "country_code": "bv",
        "country_name": "Bouvet Island"
    },
    {
        "country_code": "br",
        "country_name": "Brazil"
    },
    {
        "country_code": "io",
        "country_name": "British Indian Ocean Territory"
    },
    {
        "country_code": "bn",
        "country_name": "Brunei Darussalam"
    },
    {
        "country_code": "bg",
        "country_name": "Bulgaria"
    },
    {
        "country_code": "bf",
        "country_name": "Burkina Faso"
    },
    {
        "country_code": "bi",
        "country_name": "Burundi"
    },
    {
        "country_code": "kh",
        "country_name": "Cambodia"
    },
    {
        "country_code": "cm",
        "country_name": "Cameroon"
    },
    {
        "country_code": "ca",
        "country_name": "Canada"
    },
    {
        "country_code": "cv",
        "country_name": "Cape Verde"
    },
    {
        "country_code": "ky",
        "country_name": "Cayman Islands"
    },
    {
        "country_code": "cf",
        "country_name": "Central African Republic"
    },
    {
        "country_code": "td",
        "country_name": "Chad"
    },
    {
        "country_code": "cl",
        "country_name": "Chile"
    },
    {
        "country_code": "cn",
        "country_name": "China"
    },
    {
        "country_code": "cx",
        "country_name": "Christmas Island"
    },
    {
        "country_code": "cc",
        "country_name": "Cocos (Keeling) Islands"
    },
    {
        "country_code": "co",
        "country_name": "Colombia"
    },
    {
        "country_code": "km",
        "country_name": "Comoros"
    },
    {
        "country_code": "cg",
        "country_name": "Congo"
    },
    {
        "country_code": "cd",
        "country_name": "Congo, the Democratic Republic of the"
    },
    {
        "country_code": "ck",
        "country_name": "Cook Islands"
    },
    {
        "country_code": "cr",
        "country_name": "Costa Rica"
    },
    {
        "country_code": "ci",
        "country_name": "Cote D'ivoire"
    },
    {
        "country_code": "hr",
        "country_name": "Croatia"
    },
    {
        "country_code": "cu",
        "country_name": "Cuba"
    },
    {
        "country_code": "cy",
        "country_name": "Cyprus"
    },
    {
        "country_code": "cz",
        "country_name": "Czech Republic"
    },
    {
        "country_code": "dk",
        "country_name": "Denmark"
    },
    {
        "country_code": "dj",
        "country_name": "Djibouti"
    },
    {
        "country_code": "dm",
        "country_name": "Dominica"
    },
    {
        "country_code": "do",
        "country_name": "Dominican Republic"
    },
    {
        "country_code": "ec",
        "country_name": "Ecuador"
    },
    {
        "country_code": "eg",
        "country_name": "Egypt"
    },
    {
        "country_code": "sv",
        "country_name": "El Salvador"
    },
    {
        "country_code": "gq",
        "country_name": "Equatorial Guinea"
    },
    {
        "country_code": "er",
        "country_name": "Eritrea"
    },
    {
        "country_code": "ee",
        "country_name": "Estonia"
    },
    {
        "country_code": "et",
        "country_name": "Ethiopia"
    },
    {
        "country_code": "fk",
        "country_name": "Falkland Islands (Malvinas)"
    },
    {
        "country_code": "fo",
        "country_name": "Faroe Islands"
    },
    {
        "country_code": "fj",
        "country_name": "Fiji"
    },
    {
        "country_code": "fi",
        "country_name": "Finland"
    },
    {
        "country_code": "fr",
        "country_name": "France"
    },
    {
        "country_code": "gf",
        "country_name": "French Guiana"
    },
    {
        "country_code": "pf",
        "country_name": "French Polynesia"
    },
    {
        "country_code": "tf",
        "country_name": "French Southern Territories"
    },
    {
        "country_code": "ga",
        "country_name": "Gabon"
    },
    {
        "country_code": "gm",
        "country_name": "Gambia"
    },
    {
        "country_code": "ge",
        "country_name": "Georgia"
    },
    {
        "country_code": "de",
        "country_name": "Germany"
    },
    {
        "country_code": "gh",
        "country_name": "Ghana"
    },
    {
        "country_code": "gi",
        "country_name": "Gibraltar"
    },
    {
        "country_code": "gr",
        "country_name": "Greece"
    },
    {
        "country_code": "gl",
        "country_name": "Greenland"
    },
    {
        "country_code": "gd",
        "country_name": "Grenada"
    },
    {
        "country_code": "gp",
        "country_name": "Guadeloupe"
    },
    {
        "country_code": "gu",
        "country_name": "Guam"
    },
    {
        "country_code": "gt",
        "country_name": "Guatemala"
    },
    {
        "country_code": "gn",
        "country_name": "Guinea"
    },
    {
        "country_code": "gw",
        "country_name": "Guinea-Bissau"
    },
    {
        "country_code": "gy",
        "country_name": "Guyana"
    },
    {
        "country_code": "ht",
        "country_name": "Haiti"
    },
    {
        "country_code": "hm",
        "country_name": "Heard Island and Mcdonald Islands"
    },
    {
        "country_code": "va",
        "country_name": "Holy See (Vatican City State)"
    },
    {
        "country_code": "hn",
        "country_name": "Honduras"
    },
    {
        "country_code": "hk",
        "country_name": "Hong Kong"
    },
    {
        "country_code": "hu",
        "country_name": "Hungary"
    },
    {
        "country_code": "is",
        "country_name": "Iceland"
    },
    {
        "country_code": "in",
        "country_name": "India"
    },
    {
        "country_code": "id",
        "country_name": "Indonesia"
    },
    {
        "country_code": "ir",
        "country_name": "Iran, Islamic Republic of"
    },
    {
        "country_code": "iq",
        "country_name": "Iraq"
    },
    {
        "country_code": "ie",
        "country_name": "Ireland"
    },
    {
        "country_code": "il",
        "country_name": "Israel"
    },
    {
        "country_code": "it",
        "country_name": "Italy"
    },
    {
        "country_code": "jm",
        "country_name": "Jamaica"
    },
    {
        "country_code": "jp",
        "country_name": "Japan"
    },
    {
        "country_code": "jo",
        "country_name": "Jordan"
    },
    {
        "country_code": "kz",
        "country_name": "Kazakhstan"
    },
    {
        "country_code": "ke",
        "country_name": "Kenya"
    },
    {
        "country_code": "ki",
        "country_name": "Kiribati"
    },
    {
        "country_code": "kp",
        "country_name": "Korea, Democratic People's Republic of"
    },
    {
        "country_code": "kr",
        "country_name": "Korea, Republic of"
    },
    {
        "country_code": "kw",
        "country_name": "Kuwait"
    },
    {
        "country_code": "kg",
        "country_name": "Kyrgyzstan"
    },
    {
        "country_code": "la",
        "country_name": "Lao People's Democratic Republic"
    },
    {
        "country_code": "lv",
        "country_name": "Latvia"
    },
    {
        "country_code": "lb",
        "country_name": "Lebanon"
    },
    {
        "country_code": "ls",
        "country_name": "Lesotho"
    },
    {
        "country_code": "lr",
        "country_name": "Liberia"
    },
    {
        "country_code": "ly",
        "country_name": "Libyan Arab Jamahiriya"
    },
    {
        "country_code": "li",
        "country_name": "Liechtenstein"
    },
    {
        "country_code": "lt",
        "country_name": "Lithuania"
    },
    {
        "country_code": "lu",
        "country_name": "Luxembourg"
    },
    {
        "country_code": "mo",
        "country_name": "Macao"
    },
    {
        "country_code": "mk",
        "country_name": "Macedonia, the Former Yugosalv Republic of"
    },
    {
        "country_code": "mg",
        "country_name": "Madagascar"
    },
    {
        "country_code": "mw",
        "country_name": "Malawi"
    },
    {
        "country_code": "my",
        "country_name": "Malaysia"
    },
    {
        "country_code": "mv",
        "country_name": "Maldives"
    },
    {
        "country_code": "ml",
        "country_name": "Mali"
    },
    {
        "country_code": "mt",
        "country_name": "Malta"
    },
    {
        "country_code": "mh",
        "country_name": "Marshall Islands"
    },
    {
        "country_code": "mq",
        "country_name": "Martinique"
    },
    {
        "country_code": "mr",
        "country_name": "Mauritania"
    },
    {
        "country_code": "mu",
        "country_name": "Mauritius"
    },
    {
        "country_code": "yt",
        "country_name": "Mayotte"
    },
    {
        "country_code": "mx",
        "country_name": "Mexico"
    },
    {
        "country_code": "fm",
        "country_name": "Micronesia, Federated States of"
    },
    {
        "country_code": "md",
        "country_name": "Moldova, Republic of"
    },
    {
        "country_code": "mc",
        "country_name": "Monaco"
    },
    {
        "country_code": "mn",
        "country_name": "Mongolia"
    },
    {
        "country_code": "ms",
        "country_name": "Montserrat"
    },
    {
        "country_code": "ma",
        "country_name": "Morocco"
    },
    {
        "country_code": "mz",
        "country_name": "Mozambique"
    },
    {
        "country_code": "mm",
        "country_name": "Myanmar"
    },
    {
        "country_code": "na",
        "country_name": "Namibia"
    },
    {
        "country_code": "nr",
        "country_name": "Nauru"
    },
    {
        "country_code": "np",
        "country_name": "Nepal"
    },
    {
        "country_code": "nl",
        "country_name": "Netherlands"
    },
    {
        "country_code": "an",
        "country_name": "Netherlands Antilles"
    },
    {
        "country_code": "nc",
        "country_name": "New Caledonia"
    },
    {
        "country_code": "nz",
        "country_name": "New Zealand"
    },
    {
        "country_code": "ni",
        "country_name": "Nicaragua"
    },
    {
        "country_code": "ne",
        "country_name": "Niger"
    },
    {
        "country_code": "ng",
        "country_name": "Nigeria"
    },
    {
        "country_code": "nu",
        "country_name": "Niue"
    },
    {
        "country_code": "nf",
        "country_name": "Norfolk Island"
    },
    {
        "country_code": "mp",
        "country_name": "Northern Mariana Islands"
    },
    {
        "country_code": "no",
        "country_name": "Norway"
    },
    {
        "country_code": "om",
        "country_name": "Oman"
    },
    {
        "country_code": "pk",
        "country_name": "Pakistan"
    },
    {
        "country_code": "pw",
        "country_name": "Palau"
    },
    {
        "country_code": "ps",
        "country_name": "Palestinian Territory, Occupied"
    },
    {
        "country_code": "pa",
        "country_name": "Panama"
    },
    {
        "country_code": "pg",
        "country_name": "Papua New Guinea"
    },
    {
        "country_code": "py",
        "country_name": "Paraguay"
    },
    {
        "country_code": "pe",
        "country_name": "Peru"
    },
    {
        "country_code": "ph",
        "country_name": "Philippines"
    },
    {
        "country_code": "pn",
        "country_name": "Pitcairn"
    },
    {
        "country_code": "pl",
        "country_name": "Poland"
    },
    {
        "country_code": "pt",
        "country_name": "Portugal"
    },
    {
        "country_code": "pr",
        "country_name": "Puerto Rico"
    },
    {
        "country_code": "qa",
        "country_name": "Qatar"
    },
    {
        "country_code": "re",
        "country_name": "Reunion"
    },
    {
        "country_code": "ro",
        "country_name": "Romania"
    },
    {
        "country_code": "ru",
        "country_name": "Russian Federation"
    },
    {
        "country_code": "rw",
        "country_name": "Rwanda"
    },
    {
        "country_code": "sh",
        "country_name": "Saint Helena"
    },
    {
        "country_code": "kn",
        "country_name": "Saint Kitts and Nevis"
    },
    {
        "country_code": "lc",
        "country_name": "Saint Lucia"
    },
    {
        "country_code": "pm",
        "country_name": "Saint Pierre and Miquelon"
    },
    {
        "country_code": "vc",
        "country_name": "Saint Vincent and the Grenadines"
    },
    {
        "country_code": "ws",
        "country_name": "Samoa"
    },
    {
        "country_code": "sm",
        "country_name": "San Marino"
    },
    {
        "country_code": "st",
        "country_name": "Sao Tome and Principe"
    },
    {
        "country_code": "sa",
        "country_name": "Saudi Arabia"
    },
    {
        "country_code": "sn",
        "country_name": "Senegal"
    },
    {
        "country_code": "rs",
        "country_name": "Serbia and Montenegro"
    },
    {
        "country_code": "sc",
        "country_name": "Seychelles"
    },
    {
        "country_code": "sl",
        "country_name": "Sierra Leone"
    },
    {
        "country_code": "sg",
        "country_name": "Singapore"
    },
    {
        "country_code": "sk",
        "country_name": "Slovakia"
    },
    {
        "country_code": "si",
        "country_name": "Slovenia"
    },
    {
        "country_code": "sb",
        "country_name": "Solomon Islands"
    },
    {
        "country_code": "so",
        "country_name": "Somalia"
    },
    {
        "country_code": "za",
        "country_name": "South Africa"
    },
    {
        "country_code": "gs",
        "country_name": "South Georgia and the South Sandwich Islands"
    },
    {
        "country_code": "es",
        "country_name": "Spain"
    },
    {
        "country_code": "lk",
        "country_name": "Sri Lanka"
    },
    {
        "country_code": "sd",
        "country_name": "Sudan"
    },
    {
        "country_code": "sr",
        "country_name": "Suriname"
    },
    {
        "country_code": "sj",
        "country_name": "Svalbard and Jan Mayen"
    },
    {
        "country_code": "sz",
        "country_name": "Swaziland"
    },
    {
        "country_code": "se",
        "country_name": "Sweden"
    },
    {
        "country_code": "ch",
        "country_name": "Switzerland"
    },
    {
        "country_code": "sy",
        "country_name": "Syrian Arab Republic"
    },
    {
        "country_code": "tw",
        "country_name": "Taiwan, Province of China"
    },
    {
        "country_code": "tj",
        "country_name": "Tajikistan"
    },
    {
        "country_code": "tz",
        "country_name": "Tanzania, United Republic of"
    },
    {
        "country_code": "th",
        "country_name": "Thailand"
    },
    {
        "country_code": "tl",
        "country_name": "Timor-Leste"
    },
    {
        "country_code": "tg",
        "country_name": "Togo"
    },
    {
        "country_code": "tk",
        "country_name": "Tokelau"
    },
    {
        "country_code": "to",
        "country_name": "Tonga"
    },
    {
        "country_code": "tt",
        "country_name": "Trinidad and Tobago"
    },
    {
        "country_code": "tn",
        "country_name": "Tunisia"
    },
    {
        "country_code": "tr",
        "country_name": "Turkey"
    },
    {
        "country_code": "tm",
        "country_name": "Turkmenistan"
    },
    {
        "country_code": "tc",
        "country_name": "Turks and Caicos Islands"
    },
    {
        "country_code": "tv",
        "country_name": "Tuvalu"
    },
    {
        "country_code": "ug",
        "country_name": "Uganda"
    },
    {
        "country_code": "ua",
        "country_name": "Ukraine"
    },
    {
        "country_code": "ae",
        "country_name": "United Arab Emirates"
    },
    {
        "country_code": "uk",
        "country_name": "United Kingdom"
    },
    {
        "country_code": "gb",
        "country_name": "United Kingdom"
    },
    {
        "country_code": "us",
        "country_name": "United States"
    },
    {
        "country_code": "um",
        "country_name": "United States Minor Outlying Islands"
    },
    {
        "country_code": "uy",
        "country_name": "Uruguay"
    },
    {
        "country_code": "uz",
        "country_name": "Uzbekistan"
    },
    {
        "country_code": "vu",
        "country_name": "Vanuatu"
    },
    {
        "country_code": "ve",
        "country_name": "Venezuela"
    },
    {
        "country_code": "vn",
        "country_name": "Viet Nam"
    },
    {
        "country_code": "vg",
        "country_name": "Virgin Islands, British"
    },
    {
        "country_code": "vi",
        "country_name": "Virgin Islands, U.S."
    },
    {
        "country_code": "wf",
        "country_name": "Wallis and Futuna"
    },
    {
        "country_code": "eh",
        "country_name": "Western Sahara"
    },
    {
        "country_code": "ye",
        "country_name": "Yemen"
    },
    {
        "country_code": "zm",
        "country_name": "Zambia"
    },
    {
        "country_code": "zw",
        "country_name": "Zimbabwe"
    }
];

const GOOGLE_HL_LANGUAGES = [
    {
        "language_code": "af",
        "language_name": "Afrikaans"
    },
    {
        "language_code": "ak",
        "language_name": "Akan"
    },
    {
        "language_code": "sq",
        "language_name": "Albanian"
    },
    {
        "language_code": "ws",
        "language_name": "Samoa"
    },
    {
        "language_code": "am",
        "language_name": "Amharic"
    },
    {
        "language_code": "ar",
        "language_name": "Arabic"
    },
    {
        "language_code": "hy",
        "language_name": "Armenian"
    },
    {
        "language_code": "az",
        "language_name": "Azerbaijani"
    },
    {
        "language_code": "eu",
        "language_name": "Basque"
    },
    {
        "language_code": "be",
        "language_name": "Belarusian"
    },
    {
        "language_code": "bem",
        "language_name": "Bemba"
    },
    {
        "language_code": "bn",
        "language_name": "Bengali"
    },
    {
        "language_code": "bh",
        "language_name": "Bihari"
    },
    {
        "language_code": "xx-bork",
        "language_name": "Bork, bork, bork!"
    },
    {
        "language_code": "bs",
        "language_name": "Bosnian"
    },
    {
        "language_code": "br",
        "language_name": "Breton"
    },
    {
        "language_code": "bg",
        "language_name": "Bulgarian"
    },
    {
        "language_code": "bt",
        "language_name": "Bhutanese"
    },
    {
        "language_code": "km",
        "language_name": "Cambodian"
    },
    {
        "language_code": "ca",
        "language_name": "Catalan"
    },
    {
        "language_code": "chr",
        "language_name": "Cherokee"
    },
    {
        "language_code": "ny",
        "language_name": "Chichewa"
    },
    {
        "language_code": "zh-cn",
        "language_name": "Chinese (Simplified)"
    },
    {
        "language_code": "zh-tw",
        "language_name": "Chinese (Traditional)"
    },
    {
        "language_code": "co",
        "language_name": "Corsican"
    },
    {
        "language_code": "hr",
        "language_name": "Croatian"
    },
    {
        "language_code": "cs",
        "language_name": "Czech"
    },
    {
        "language_code": "da",
        "language_name": "Danish"
    },
    {
        "language_code": "nl",
        "language_name": "Dutch"
    },
    {
        "language_code": "xx-elmer",
        "language_name": "Elmer Fudd"
    },
    {
        "language_code": "en",
        "language_name": "English"
    },
    {
        "language_code": "eo",
        "language_name": "Esperanto"
    },
    {
        "language_code": "et",
        "language_name": "Estonian"
    },
    {
        "language_code": "ee",
        "language_name": "Ewe"
    },
    {
        "language_code": "fo",
        "language_name": "Faroese"
    },
    {
        "language_code": "tl",
        "language_name": "Filipino"
    },
    {
        "language_code": "fi",
        "language_name": "Finnish"
    },
    {
        "language_code": "fr",
        "language_name": "French"
    },
    {
        "language_code": "fy",
        "language_name": "Frisian"
    },
    {
        "language_code": "gaa",
        "language_name": "Ga"
    },
    {
        "language_code": "gl",
        "language_name": "Galician"
    },
    {
        "language_code": "ka",
        "language_name": "Georgian"
    },
    {
        "language_code": "de",
        "language_name": "German"
    },
    {
        "language_code": "el",
        "language_name": "Greek"
    },
    {
        "language_code": "kl",
        "language_name": "Greenlandic"
    },
    {
        "language_code": "gn",
        "language_name": "Guarani"
    },
    {
        "language_code": "gu",
        "language_name": "Gujarati"
    },
    {
        "language_code": "xx-hacker",
        "language_name": "Hacker"
    },
    {
        "language_code": "ht",
        "language_name": "Haitian Creole"
    },
    {
        "language_code": "ha",
        "language_name": "Hausa"
    },
    {
        "language_code": "haw",
        "language_name": "Hawaiian"
    },
    {
        "language_code": "iw",
        "language_name": "Hebrew"
    },
    {
        "language_code": "hi",
        "language_name": "Hindi"
    },
    {
        "language_code": "hu",
        "language_name": "Hungarian"
    },
    {
        "language_code": "is",
        "language_name": "Icelandic"
    },
    {
        "language_code": "ig",
        "language_name": "Igbo"
    },
    {
        "language_code": "id",
        "language_name": "Indonesian"
    },
    {
        "language_code": "ia",
        "language_name": "Interlingua"
    },
    {
        "language_code": "ga",
        "language_name": "Irish"
    },
    {
        "language_code": "it",
        "language_name": "Italian"
    },
    {
        "language_code": "ja",
        "language_name": "Japanese"
    },
    {
        "language_code": "jw",
        "language_name": "Javanese"
    },
    {
        "language_code": "kn",
        "language_name": "Kannada"
    },
    {
        "language_code": "kk",
        "language_name": "Kazakh"
    },
    {
        "language_code": "rw",
        "language_name": "Kinyarwanda"
    },
    {
        "language_code": "rn",
        "language_name": "Kirundi"
    },
    {
        "language_code": "xx-klingon",
        "language_name": "Klingon"
    },
    {
        "language_code": "kg",
        "language_name": "Kongo"
    },
    {
        "language_code": "ko",
        "language_name": "Korean"
    },
    {
        "language_code": "kri",
        "language_name": "Krio (Sierra Leone)"
    },
    {
        "language_code": "ku",
        "language_name": "Kurdish"
    },
    {
        "language_code": "ckb",
        "language_name": "Kurdish (Soranî)"
    },
    {
        "language_code": "ky",
        "language_name": "Kyrgyz"
    },
    {
        "language_code": "lo",
        "language_name": "Laothian"
    },
    {
        "language_code": "la",
        "language_name": "Latin"
    },
    {
        "language_code": "lv",
        "language_name": "Latvian"
    },
    {
        "language_code": "ln",
        "language_name": "Lingala"
    },
    {
        "language_code": "lt",
        "language_name": "Lithuanian"
    },
    {
        "language_code": "loz",
        "language_name": "Lozi"
    },
    {
        "language_code": "lg",
        "language_name": "Luganda"
    },
    {
        "language_code": "ach",
        "language_name": "Luo"
    },
    {
        "language_code": "mk",
        "language_name": "Macedonian"
    },
    {
        "language_code": "mg",
        "language_name": "Malagasy"
    },
    {
        "language_code": "my",
        "language_name": "Malay"
    },
    {
        "language_code": "ml",
        "language_name": "Malayalam"
    },
    {
        "language_code": "mt",
        "language_name": "Maltese"
    },
    {
        "language_code": "mv",
        "language_name": "Maldives"
    },
    {
        "language_code": "mi",
        "language_name": "Maori"
    },
    {
        "language_code": "mr",
        "language_name": "Marathi"
    },
    {
        "language_code": "mfe",
        "language_name": "Mauritian Creole"
    },
    {
        "language_code": "mo",
        "language_name": "Moldavian"
    },
    {
        "language_code": "mn",
        "language_name": "Mongolian"
    },
    {
        "language_code": "sr-me",
        "language_name": "Montenegrin"
    },
    {
        "language_code": "ne",
        "language_name": "Nepali"
    },
    {
        "language_code": "pcm",
        "language_name": "Nigerian Pidgin"
    },
    {
        "language_code": "nso",
        "language_name": "Northern Sotho"
    },
    {
        "language_code": "no",
        "language_name": "Norwegian"
    },
    {
        "language_code": "nn",
        "language_name": "Norwegian (Nynorsk)"
    },
    {
        "language_code": "oc",
        "language_name": "Occitan"
    },
    {
        "language_code": "or",
        "language_name": "Oriya"
    },
    {
        "language_code": "om",
        "language_name": "Oromo"
    },
    {
        "language_code": "ps",
        "language_name": "Pashto"
    },
    {
        "language_code": "fa",
        "language_name": "Persian"
    },
    {
        "language_code": "xx-pirate",
        "language_name": "Pirate"
    },
    {
        "language_code": "pl",
        "language_name": "Polish"
    },
    {
        "language_code": "pt",
        "language_name": "Portuguese"
    },
    {
        "language_code": "pt-br",
        "language_name": "Portuguese (Brazil)"
    },
    {
        "language_code": "pt-pt",
        "language_name": "Portuguese (Portugal)"
    },
    {
        "language_code": "pa",
        "language_name": "Punjabi"
    },
    {
        "language_code": "qu",
        "language_name": "Quechua"
    },
    {
        "language_code": "ro",
        "language_name": "Romanian"
    },
    {
        "language_code": "rm",
        "language_name": "Romansh"
    },
    {
        "language_code": "nyn",
        "language_name": "Runyakitara"
    },
    {
        "language_code": "ru",
        "language_name": "Russian"
    },
    {
        "language_code": "gd",
        "language_name": "Scots Gaelic"
    },
    {
        "language_code": "sr",
        "language_name": "Serbian"
    },
    {
        "language_code": "sh",
        "language_name": "Serbo-Croatian"
    },
    {
        "language_code": "st",
        "language_name": "Sesotho"
    },
    {
        "language_code": "tn",
        "language_name": "Setswana"
    },
    {
        "language_code": "crs",
        "language_name": "Seychellois Creole"
    },
    {
        "language_code": "sn",
        "language_name": "Shona"
    },
    {
        "language_code": "sd",
        "language_name": "Sindhi"
    },
    {
        "language_code": "si",
        "language_name": "Sinhalese"
    },
    {
        "language_code": "sk",
        "language_name": "Slovak"
    },
    {
        "language_code": "sl",
        "language_name": "Slovenian"
    },
    {
        "language_code": "so",
        "language_name": "Somali"
    },
    {
        "language_code": "es",
        "language_name": "Spanish"
    },
    {
        "language_code": "es-419",
        "language_name": "Spanish (Latin American)"
    },
    {
        "language_code": "su",
        "language_name": "Sundanese"
    },
    {
        "language_code": "sw",
        "language_name": "Swahili"
    },
    {
        "language_code": "sv",
        "language_name": "Swedish"
    },
    {
        "language_code": "tg",
        "language_name": "Tajik"
    },
    {
        "language_code": "ta",
        "language_name": "Tamil"
    },
    {
        "language_code": "tt",
        "language_name": "Tatar"
    },
    {
        "language_code": "te",
        "language_name": "Telugu"
    },
    {
        "language_code": "th",
        "language_name": "Thai"
    },
    {
        "language_code": "ti",
        "language_name": "Tigrinya"
    },
    {
        "language_code": "to",
        "language_name": "Tonga"
    },
    {
        "language_code": "lua",
        "language_name": "Tshiluba"
    },
    {
        "language_code": "tum",
        "language_name": "Tumbuka"
    },
    {
        "language_code": "tr",
        "language_name": "Turkish"
    },
    {
        "language_code": "tk",
        "language_name": "Turkmen"
    },
    {
        "language_code": "tw",
        "language_name": "Twi"
    },
    {
        "language_code": "ug",
        "language_name": "Uighur"
    },
    {
        "language_code": "uk",
        "language_name": "Ukrainian"
    },
    {
        "language_code": "ur",
        "language_name": "Urdu"
    },
    {
        "language_code": "uz",
        "language_name": "Uzbek"
    },
    {
        "language_code": "vu",
        "language_name": "Vanuatu"
    },
    {
        "language_code": "vi",
        "language_name": "Vietnamese"
    },
    {
        "language_code": "cy",
        "language_name": "Welsh"
    },
    {
        "language_code": "wo",
        "language_name": "Wolof"
    },
    {
        "language_code": "xh",
        "language_name": "Xhosa"
    },
    {
        "language_code": "yi",
        "language_name": "Yiddish"
    },
    {
        "language_code": "yo",
        "language_name": "Yoruba"
    },
    {
        "language_code": "zu",
        "language_name": "Zulu"
    }
];

/**
 * Created by john on 3/10/16.
 */
 'use strict';

 /* exported
 getAppConfiguration
  */

 function getAppConfiguration() {

   function last(arr, fallback) {
     return (
       Array.isArray(arr) &&
       arr.length > 0 &&
       arr[arr.length - 1]
     ) || fallback;
   }
   const googleRecaptchaKeysMap = {
     prod:'6LeF97okAAAAAPCzu3uFWG3LsdSdi9p9jcfAMLIX',
     test:'6LeF97okAAAAAPCzu3uFWG3LsdSdi9p9jcfAMLIX'
   };

   const mapBoxTokenMap = {
     prod: 'pk.eyJ1Ijoib21lcmNoZWhtZXIiLCJhIjoiY20yd3hpaG5wMGNkbzJsc2JudnV4M3YzZyJ9.gOa92Ncc-4ZVzVnLwZyMcQ',
     test: 'pk.eyJ1Ijoib21lcmNoZWhtZXIiLCJhIjoiY20yd3hqcTFtMDJpZjJsc2Jscms3Mjh6OSJ9.963ku0yOfAja2_hcUAf69g'
   };

   let googleRecaptchaSiteKey = googleRecaptchaKeysMap.prod;
   let mapBoxToken = mapBoxTokenMap.prod;

   let env;
   let dashEnv;
   let localEnv;

   let url = location.hostname;
   let protocol = location.protocol;
   let environment = location.hostname.split('.')[0];
   let suffix = last(location.hostname.split('.'), "com");

   if (environment.includes('-')) {
     environment = environment.split('-')[1];
   }

   if (url.includes('local')) {
     env = '';
     dashEnv = '.';
     localEnv = 'local.';
     googleRecaptchaSiteKey = googleRecaptchaKeysMap.test;
     mapBoxToken = mapBoxTokenMap.test;

   } else if (environment === 'admin') {
     env = 'www.';
    dashEnv = '.';
     localEnv = '';
   } else {
     env = `${environment}.`;
     dashEnv = `-${environment}.`;
     localEnv = '';
     googleRecaptchaSiteKey = googleRecaptchaKeysMap.test;
     mapBoxToken = mapBoxTokenMap.test;
   }

     return {
     version: '1.0.0',
     displayVersion: true,
     googleRecaptchaSiteKey,
     mapBoxToken,
     bookingsServiceBaseUrl: `${protocol}//${env}bookaway.${localEnv}${suffix}/_api/bookings`,
     inventoryServiceBaseUrl: `${protocol}//${env}bookaway.${localEnv}${suffix}/_api/inventory`,
     searchServiceBaseUrl: `${protocol}//${env}bookaway.${localEnv}${suffix}/_api/search`,
     usersServiceBaseUrl: `${protocol}//${env}bookaway.${localEnv}${suffix}/_api/users`,
     authServiceBaseUrl: `${protocol}//${env}bookaway.${localEnv}${suffix}/api/auth`,
     imagesServiceBaseUrl: `${protocol}//${env}bookaway.${localEnv}${suffix}/_api/images`,
     dataCollectorUrl: `${protocol}//${env}bookaway.${localEnv}${suffix}/_api/data-collector`,
     routesServiceUrl: `${protocol}//${env}bookaway.${localEnv}${suffix}/_api/routes`,
     frontendBrowserUrl: `${protocol}//${env}bookaway.${localEnv}${suffix}`,
     cdnImageEndpoint: `${protocol}//${environment === 'admin' ? 'cdn.' : `cdn-${env}`}bookaway.${localEnv}${suffix}/media/files`,
     transports: true,
     editAddDeleteRideRules: true,
     newPickupDropOff: true,
   };
 }
